import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import PhoneInput from '../../../../components/PhoneInput';
import TextInput from '../../../../components/TextInput';
import { useNotifications } from '../../../../context/NotificationsContext';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import {
  selectLaundry,
  selectName,
  selectPhone,
  selectSurname,
  setName,
  setPhone,
  setSurname,
} from '../../../../store/slices/laundrySlice';

import { CommonWrapper, MainDataWrapper, Title } from '../../styled';
import { Acceptation, BottomWrapper, Form } from './styled';

export default function PersonalData(): JSX.Element {
  const dispatch = useTypedDispatch();

  const name = useSelector(selectName);
  const surname = useSelector(selectSurname);
  const phone = useSelector(selectPhone);

  const { setNotification } = useNotifications();
  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();

  const laundry = useSelector(selectLaundry);

  useEffect(() => {
    if (!laundry) {
      navigateWithUid('/', { replace: true });
    } else {
      setNotification({
        status: 'neutral',
        value: staticTranslate('operatorCallInfo'),
      });
    }
    // check once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setNameHandler(value: string) {
    dispatch(setName(value));
  }
  function setSurnameHandler(value: string) {
    dispatch(setSurname(value));
  }
  function setPhoneHandler(value: string) {
    dispatch(setPhone(value));
  }

  const isButtonActive = !!name && !!surname && !!phone;

  return (
    <>
      <CommonWrapper>
        <Title>{staticTranslate('enterYourDetails')}</Title>
        <MainDataWrapper>
          <Form>
            <TextInput
              value={name}
              onChange={setNameHandler}
              placeholder={staticTranslate('name')}
            />
            <TextInput
              value={surname}
              onChange={setSurnameHandler}
              placeholder={staticTranslate('surname')}
            />
            <PhoneInput value={phone} onChange={setPhoneHandler} />
          </Form>
        </MainDataWrapper>
      </CommonWrapper>
      <BottomWrapper>
        <Acceptation>{staticTranslate('acceptances')}</Acceptation>
        <Button
          isActive={isButtonActive}
          variant="contained"
          to="/laundry/clothes"
        >
          {staticTranslate('Next')}
        </Button>
      </BottomWrapper>
    </>
  );
}
