import { usePhoneInputSettings } from './hooks/usePhoneInputSettings';
import { PhoneContainerProps } from './types';

import { StyledPhoneInput } from './styled';

export default function PhoneContainer({
  value,
  onChange,
}: PhoneContainerProps): JSX.Element {
  const country = usePhoneInputSettings(value);

  return (
    <StyledPhoneInput
      value={value}
      onChange={(number) => onChange(number)}
      country={country}
      placeholder="XXX XXX XXX"
    />
  );
}
