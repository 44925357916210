import { RadioButtonProps } from './types';

import { Label, LabelText, RadioInput, RadioWrapper } from './styled';

export default function RadioButton({
  id,
  checked,
  label,
  value,
}: RadioButtonProps) {
  return (
    <RadioWrapper>
      <RadioInput
        aria-label="Choice extension"
        id={id}
        type="radio"
        checked={checked}
        readOnly
        value={value}
      />
      <Label htmlFor={id}>
        <LabelText>{label}</LabelText>
      </Label>
    </RadioWrapper>
  );
}
