import Box from '../../../../assets/images/extendBox.png';
import Button from '../../../../components/Button';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useHeadTitle } from '../../../../hooks/useHeadTitle';
import { useTranslate } from '../../../../hooks/useTranslate';

import { BoxImg, ImgWrapper, Main } from '../../styled';
import { ErrorHeading, ErrorIcon, ErrorMessage, TextWrapper } from './styled';

export default function ExtendError(): JSX.Element {
  const { staticTranslate } = useTranslate();
  const { navigateWithUid } = useCustomNavigate();

  const toMainPage = () => {
    navigateWithUid('/', { replace: true });
  };

  useHeadTitle(staticTranslate('ExtendErrorTitle'));

  return (
    <>
      <Main>
        <ImgWrapper>
          <BoxImg src={Box} alt="box" />
          <ErrorIcon role="img" />
        </ImgWrapper>
        <TextWrapper>
          <ErrorHeading>{staticTranslate('ExtendErrorTitle')}</ErrorHeading>
          <ErrorMessage>
            {staticTranslate('ExtendErrorDescription')}
          </ErrorMessage>
        </TextWrapper>
      </Main>
      <Button variant="outlined" onClick={toMainPage}>
        {staticTranslate('ToMain')}
      </Button>
    </>
  );
}
