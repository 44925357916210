import styled from 'styled-components';

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  color: ${({ theme }): string => theme.palette.grey[100]};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  padding: ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(5)};

  background-color: ${({ theme }): string =>
    theme.palette.background.specialHeader};
`;

export const CommonWrapper = styled.div`
  width: 100%;
`;

export const MainDataWrapper = styled.main`
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const TextInfoBig = styled.p`
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  padding: ${({ theme }): string => theme.spacing(4)};
`;
