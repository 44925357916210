import styled, { keyframes } from 'styled-components';

const load = keyframes`
  0% {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

export const Loading = styled.div`
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;

  border-top: 4px solid
    ${({ theme }): string => theme.palette.border.hightLight};
  border-right: 4px solid
    ${({ theme }): string => theme.palette.border.hightLight};
  border-bottom: 4px solid
    ${({ theme }): string => theme.palette.border.hightLight};
  border-left: 4px solid ${({ theme }): string => theme.palette.border.primary};

  &,
  &::after {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
`;
