import { useState } from 'react';
import { useSelector } from 'react-redux';

import { request } from '../../../../../api';
import { CreateOrderResponse } from '../../../../../api/types';
import { useCheckFlow } from '../../../../../hooks/useCheckFlow';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { useTypedDispatch } from '../../../../../store';
import { openDeviceCell } from '../../../../../store/slices/configSlice';
import { selectUid } from '../../../../../store/slices/initialSlice';
import {
  selectClothes,
  selectLaundry,
  selectName,
  selectPhone,
  selectSurname,
} from '../../../../../store/slices/laundrySlice';
import { Clothe } from '../../../../../store/types/laundryTypes';

export function useOpenCell() {
  const [isProcessing, setIsProcessing] = useState(false);

  const deviceUid = useSelector(selectUid);
  const laundry = useSelector(selectLaundry);
  const phone = useSelector(selectPhone);
  const senderName = useSelector(selectName);
  const senderSurname = useSelector(selectSurname);
  const clothes = useSelector(selectClothes);

  const { flow } = useCheckFlow();
  const { navigateToErrorPage, navigateWithUid } = useCustomNavigate();
  const dispatch = useTypedDispatch();

  function clothesToString(items: Clothe[]): string {
    return items
      .map((clothe) => {
        return `${clothe.clothe}: ${clothe.quantity}`;
      })
      .join(', ');
  }

  function openCell() {
    setIsProcessing(true);

    request<CreateOrderResponse>({
      method: 'POST',
      path: '/public/order/',
      data: {
        device_uid: deviceUid,
        order_type: 10,
        status: 0,
        client_system_id: laundry,
        barcode: '',
        order_number: '',
        request_number: '',
        sender_phone: phone,
        sender_name: `${senderName} ${senderSurname}`,
        client_order: true,
        is_return: false,
        comments: clothesToString(clothes),
      },
    })
      .then((data) => {
        return dispatch(
          openDeviceCell({
            flow,
            deviceUid,
            orderUid: data.data.uid,
          }),
        ).unwrap();
      })
      .then(() => {
        sessionStorage.removeItem('persist:laundry');
        navigateWithUid('/laundry/open-cell-viewer');
      })
      .catch((error) => navigateToErrorPage(error.message))
      .finally(() => setIsProcessing(false));
  }

  return {
    isProcessing,
    openCell,
  };
}
