import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useCheckFlow } from '../../../hooks/useCheckFlow';
import { useTypedDispatch } from '../../../store';
import {
  getDeviceConfig,
  selectConfigStatus,
} from '../../../store/slices/configSlice';
import { selectUid } from '../../../store/slices/initialSlice';
import { selectScannedData } from '../../../store/slices/qrSlice';
import { Flow } from '../../../types/appLogic';

export function useGetDeviceConfig(): string {
  const scannedData = useSelector(selectScannedData);
  const configStatus = useSelector(selectConfigStatus);
  const savedDeviceUid = useSelector(selectUid);
  const { flow } = useCheckFlow();
  const dispatch = useTypedDispatch();

  const deviceUid = flow === Flow.common ? scannedData : savedDeviceUid;

  useEffect(() => {
    if (configStatus !== 'idle' || !deviceUid) return;
    dispatch(getDeviceConfig(deviceUid));
  }, [dispatch, deviceUid, configStatus]);

  return configStatus;
}
