import { PhotoItemProps } from './types';

import { Cell, PhotoPaper, PostamatPhoto } from './styled';

export default function PhotoItem({
  photo,
  onClick: selectPhoto,
  closeButton,
}: PhotoItemProps): JSX.Element {
  const ariaLabel = !selectPhoto ? 'Photo' : 'Open fullscreen photo';
  const role = !selectPhoto ? 'img' : 'button';
  return (
    <Cell
      role={role}
      aria-label={ariaLabel}
      key={String(photo)}
      onClick={() => selectPhoto?.(photo)}
    >
      <PhotoPaper>
        {closeButton}
        <PostamatPhoto src={photo} alt="Postamat" />
      </PhotoPaper>
    </Cell>
  );
}
