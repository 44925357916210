import Button from '../../components/Button';
import Paper from '../../components/Paper';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { ThemeMode } from '../../types/theme';
import ExampleArea from './components/ExampleArea';
import { BUTTONS } from './constants';
import { useA11ySettings } from './hooks/useA11ySettings';
import { CheckboxName } from './types';

import { ButtonsWrapper } from '../../components/ButtonsPaper/styled';
import {
  A11yButtonsForm,
  A11yContainer,
  A11yDocLink,
  BoxGroup,
  CheckBox,
  ExampleAreaWrapper,
  Fieldset,
  FontSizeButton,
  FontSizes,
  Label,
} from './styled';

export default function A11yPage(): JSX.Element {
  const {
    fontSize,
    themeMode,
    isArialFont,
    isHighlightLinks,
    selectMode,
    selectSettings,
    selectFontSize,
    resetThemSettings,
    acceptThemeSettings,
  } = useA11ySettings();

  const { staticTranslate } = useTranslate();

  useHeadTitle(staticTranslate('Accessibility'));
  usePageHeaderContext(staticTranslate('Accessibility'));

  return (
    <>
      <A11yContainer>
        <Paper>
          <ExampleAreaWrapper>
            <ExampleArea
              themeSettings={{
                fontSize,
                themeMode,
                isArialFont,
                isHighlightLinks,
              }}
            />
          </ExampleAreaWrapper>
          <FontSizes aria-label="Font size selectors" onClick={selectFontSize}>
            {BUTTONS.map(({ size, ariaLabel }) => {
              const isActive = size === fontSize;
              return (
                <FontSizeButton
                  key={size}
                  name={size}
                  fontSizeCrop={size}
                  aria-label={ariaLabel}
                  isActive={isActive}
                >
                  A
                </FontSizeButton>
              );
            })}
          </FontSizes>
          <A11yButtonsForm>
            <Fieldset onChange={selectMode} aria-label="Select theme mode">
              <BoxGroup>
                <CheckBox
                  type="checkbox"
                  name={ThemeMode.dark}
                  id="dark"
                  checked={themeMode === 'dark'}
                  readOnly
                />
                <Label htmlFor="dark">{staticTranslate('BlackWhite')}</Label>
              </BoxGroup>
              <BoxGroup>
                <CheckBox
                  type="checkbox"
                  name={ThemeMode.contrast}
                  id="contrast"
                  checked={themeMode === 'contrast'}
                  readOnly
                />
                <Label htmlFor="contrast">
                  {staticTranslate('HighContrast')}
                </Label>
              </BoxGroup>
            </Fieldset>
            <Fieldset
              aria-label="Set highlight links, and readable font"
              onChange={selectSettings}
            >
              <BoxGroup>
                <CheckBox
                  type="checkbox"
                  name={CheckboxName.highlightLinks}
                  id="highlight-links"
                  checked={isHighlightLinks}
                  readOnly
                />
                <Label htmlFor="highlight-links">
                  {staticTranslate('HighlightLinks')}
                </Label>
              </BoxGroup>
              <BoxGroup>
                <CheckBox
                  type="checkbox"
                  name={CheckboxName.arialFont}
                  id="arial-Font"
                  checked={isArialFont}
                  readOnly
                />
                <Label htmlFor="arial-Font">
                  {staticTranslate('ReadableFont')} (Arial)
                </Label>
              </BoxGroup>
            </Fieldset>
          </A11yButtonsForm>
          <A11yDocLink to="/a11y-statement">
            {staticTranslate('AccessibilityStatement')}
          </A11yDocLink>
        </Paper>
      </A11yContainer>
      <ButtonsWrapper>
        <Button variant="outlined" size="xxl" onClick={resetThemSettings}>
          {staticTranslate('Reset')}
        </Button>
        <Button variant="contained" size="xxl" onClick={acceptThemeSettings}>
          {staticTranslate('Accept')}
        </Button>
      </ButtonsWrapper>
    </>
  );
}
