import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useOutsideClick } from '../../../../../../hooks/useOutsideClick';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../../../store';
import {
  removeClothe,
  selectClothesVault,
} from '../../../../../../store/slices/laundrySlice';
import AssortmentList from './components/AssortmentList';
import Quantity from './components/Quantity';
import { ASSORTMENT_LIST_HEIGHT } from './constants';
import { useAssortmentFilter } from './hooks/useAssortmentFilter';
import { ClotheItemProps } from './types';

import { PlusIcon } from './components/Quantity/styled';
import {
  Clothe,
  InnerElements,
  InputWrapper,
  ItemActionButton,
  Search,
} from './styled';

export default function ClotheItem({
  clothe: { listItemId, clothe, quantity, clotheId },
}: ClotheItemProps): JSX.Element {
  const [isOpenListUp, setIsOpenListUp] = useState(false);
  const [isShowAssortmentList, setIsShowAssortmentList] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const clothesVault = useSelector(selectClothesVault);
  const isSingleLi = clothesVault.length === 1;
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useTypedDispatch();
  const { staticTranslate } = useTranslate();

  const shownAssortment = useAssortmentFilter(searchValue, listItemId);

  function closeAssortmentList(): void {
    setIsShowAssortmentList(false);
  }

  useOutsideClick({
    ref,
    isOpen: isShowAssortmentList,
    cb: closeAssortmentList,
  });

  useEffect(() => {
    setSearchValue(clothe);
  }, [clothe]);

  function onChangeHandler({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    setSearchValue(value);
  }

  function checkDistanceToBottom() {
    if (ref.current) {
      const bottomElement = ref.current?.getBoundingClientRect().bottom;
      const clientWindowHeight = document.documentElement.clientHeight;
      const distanceToWindowBottom = clientWindowHeight - bottomElement;
      const isEnoughSpace = distanceToWindowBottom > ASSORTMENT_LIST_HEIGHT;
      setIsOpenListUp(!isEnoughSpace);
    }
  }

  function onFocusHandler() {
    setSearchValue('');
    setIsShowAssortmentList(true);
    checkDistanceToBottom();
  }

  function onBlurHandler() {
    setSearchValue(clothe);
  }

  function removeLI() {
    if (!isSingleLi) {
      dispatch(removeClothe(listItemId));
    }
  }

  return (
    <Clothe>
      <InnerElements isActive={isShowAssortmentList}>
        <InputWrapper ref={ref} isOpenListUp={isOpenListUp}>
          <Search
            aria-label="Search input for clothes"
            placeholder={staticTranslate('search')}
            type="text"
            value={searchValue}
            onChange={onChangeHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
          {isShowAssortmentList && (
            <AssortmentList
              shownAssortment={shownAssortment}
              listItemId={listItemId}
              closeAssortmentList={closeAssortmentList}
            />
          )}
        </InputWrapper>
        <Quantity
          quantity={quantity}
          clotheId={clotheId}
          listItemId={listItemId}
        />
      </InnerElements>
      <ItemActionButton isActive={!isSingleLi} onClick={removeLI}>
        <PlusIcon />
      </ItemActionButton>
    </Clothe>
  );
}
