import terminalX from '../../../../assets/images/return/terminalX.png';
import { useTypedDispatch } from '../../../../store';
import { setClient } from '../../../../store/slices/returnSlice';
import { ShopsProps } from './types';

import { Shop, ShopImage, ShopName, ShopsContainer } from './styled';

export default function Shops({ changeStep }: ShopsProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const selectShop = (shop: string) => () => {
    dispatch(setClient(shop));
    changeStep('next');
  };

  return (
    <ShopsContainer aria-label="Shops-partners">
      <Shop onClick={selectShop('Bar Group LTD')}>
        <ShopImage src={terminalX} />
        <ShopName />
      </Shop>
    </ShopsContainer>
  );
}
