import styled from 'styled-components';

import { StyledIndicator } from './types';

function applyIndicatorColor({ isAvailable, theme }: StyledIndicator): string {
  return isAvailable ? theme.palette.primary[30] : theme.palette.error;
}

export const AvailabilityIndicator = styled.div`
  background-color: ${applyIndicatorColor};
  border-radius: 50%;
  min-width: 10px;
  min-height: 10px;
`;
