import { STARS_PERCENTS } from '../constants';
import { StarsPercents } from '../types';

export function getFeedbacksPercent(feedbacksRatings: number[]): StarsPercents {
  const allRatings = feedbacksRatings.length;
  const stars = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  feedbacksRatings.forEach((rating) => {
    if (!rating) return;
    const type = rating as keyof StarsPercents;
    stars[type] = ++stars[type];
  });

  return Object.entries(stars).reduce<StarsPercents>((acc, [key, quantity]) => {
    const star = +key as keyof StarsPercents;
    acc[star] = `${(+quantity / allRatings) * 100}%`;
    return acc;
  }, STARS_PERCENTS);
}
