import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { MainContainerProps } from './types';

import { Cell } from './components/PhotoItem/styled';

export const Popup = styled.div`
  top: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }): string =>
    theme.palette.background.transparently};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};

  ${Cell} {
    display: none;
  }
`;

function applyMainContainer({
  isPopup,
}: MainContainerProps): FlattenInterpolation<ThemeProps<DefaultTheme>> | false {
  return (
    isPopup &&
    css`
      min-height: 320px;
      ${Popup} {
        opacity: 1;
        pointer-events: all;

        ${Cell} {
          display: block;
        }
      }
    `
  );
}

export const MainContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: fit-content;
  min-height: 138px;
  ${applyMainContainer};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  margin: 0 ${({ theme }): string => theme.spacing(3)};
`;

export const PhotoGrid = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  justify-content: center;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
`;
