import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LANG_LIST, projectIs } from '../../constants';
import { LangDescriptorList } from '../../dictionary';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { usePageCheck } from '../../hooks/usePageCheck';
import { useTypedDispatch } from '../../store';
import { selectIsOrderReceived } from '../../store/slices/initialSlice';
import { selectLang, setLang } from '../../store/slices/langSlice';
import {
  A11yButton,
  A11yIcon,
  HeaderContainer,
  HeaderHolder,
  Language,
  Languages,
  Logo,
  LogoButton,
  SettingsWrapper,
} from './styles';

export default function Header(): JSX.Element {
  const currentLang = useSelector(selectLang);
  const isOrderReceived = useSelector(selectIsOrderReceived);

  const isShowA11yBtn = !projectIs.omar && !projectIs.omkaz && !projectIs.mncdk;
  const isShowLangs = LANG_LIST.length > 1;

  const navigate = useNavigate();

  const dispatch = useTypedDispatch();
  const { navigateWithUid } = useCustomNavigate();

  const { isA11yPage, isErrorPage } = usePageCheck();

  function onClickHandler() {
    if (isA11yPage) {
      navigate(-1);
    } else {
      navigateWithUid('/a11y-features');
    }
  }

  return (
    <>
      <HeaderHolder />
      <HeaderContainer>
        <LogoButton
          onClick={() =>
            !isOrderReceived && !isErrorPage && navigateWithUid('/')
          }
          type="button"
          aria-label="Go to main page"
        >
          <Logo role="img" aria-label="Logo" />
        </LogoButton>
        <SettingsWrapper>
          {isShowA11yBtn && (
            <A11yButton
              aria-label="Open/close accessability settings"
              onClick={onClickHandler}
              isActive={isA11yPage}
            >
              <A11yIcon />
            </A11yButton>
          )}
          {isShowLangs && (
            <Languages>
              {LANG_LIST.map((lang) => {
                const isActive = currentLang === lang;
                return (
                  <Language
                    type="button"
                    aria-label="Select language"
                    key={lang}
                    onClick={() => dispatch(setLang(lang))}
                    isActive={isActive}
                  >
                    {LangDescriptorList[lang].short3}
                  </Language>
                );
              })}
            </Languages>
          )}
        </SettingsWrapper>
      </HeaderContainer>
    </>
  );
}
