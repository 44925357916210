import MapManager from '../../../../../../components/MapManager';

import { MapContainer, MapManagerWrapper } from './styled';

export default function Map(): JSX.Element {
  return (
    <MapContainer>
      <MapManagerWrapper>
        <MapManager width="100%" height="100%" />
      </MapManagerWrapper>
    </MapContainer>
  );
}
