import styled from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';
import { WSItemProps } from './types';

export const WSContainer = styled.ul`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: fit-content;
  gap: ${({ theme }): string => theme.spacing()};
`;

export const WSItem = styled.li<WSItemProps>`
  max-width: fit-content;
  display: grid;
  grid-gap: ${({ theme }): string => theme.spacing()};
  grid-template-columns: auto 1px auto;
  list-style-type: none;
  justify-items: end;
  order: ${({ isFirstWeeklyDay }): string =>
    isFirstWeeklyDay ? '-1' : 'unset'};
`;

export const DayName = styled.p`
  min-width: fit-content;
`;

export const PipeLine = styled.div`
  background-color: ${({ theme }): string => theme.palette.border.secondary};
  width: 100%;
  height: 100%;
`;

export const WorkingTime = styled.p`
  min-width: fit-content;
`;
