import { ErrorIds, ErrorType } from '../types/errors';

export function identifyError(res: ErrorIds | null) {
  const orderError = [
    ErrorIds.orderNotDeliveredYet,
    ErrorIds.orderHasAlreadyDelivered,
    ErrorIds.notFound,
  ];
  const openCellError = [
    ErrorIds.cellDidNotOpen,
    ErrorIds.cellBlocked,
    ErrorIds.thereAreOpenCells,
    ErrorIds.deviceOffline,
  ];

  if (!res) {
    throw new Error('Something went wrong');
  }

  if (res === ErrorIds.orderHasAlreadyPaid) return {};

  if (res === ErrorIds.orderDoesNotExist) {
    throw new Error(ErrorType.ORDER_IN_POSTAMAT_ERROR);
  }
  if (openCellError.includes(res)) {
    throw new Error(ErrorType.OPEN_CELL_ERROR);
  }
  if (res === ErrorIds.paymentRequired) {
    throw new Error(ErrorType.PAYMENT_ERROR);
  }
  if (orderError.includes(res)) {
    throw new Error(ErrorType.NO_ORDER_ERROR);
  }
}
