import styled from 'styled-components';

import { infoTextTemplate } from '../../../../../../../../theme/pagesElements';

export const UserName = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  opacity: 0.66;
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const StarsButton = styled.div`
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }): string => theme.spacing(1.5)};
`;
