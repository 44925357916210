import styled from 'styled-components';

export const ClothesList = styled.ul`
  list-style-type: none;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(3)};

  margin-bottom: ${({ theme }): string => theme.spacing(3)};

  & + button {
    margin: 0;
    margin-bottom: ${({ theme }): string => theme.spacing(2)};
  }
`;
