import { ReactComponent as L } from '../../../../assets/images/return/cells/l.svg';
import { ReactComponent as M } from '../../../../assets/images/return/cells/m.svg';
import { ReactComponent as S } from '../../../../assets/images/return/cells/s.svg';
import { ReactComponent as XL } from '../../../../assets/images/return/cells/xl.svg';
import { ReactComponent as XS } from '../../../../assets/images/return/cells/xs.svg';
import { ReactComponent as XXL } from '../../../../assets/images/return/cells/xxl.svg';
import { ReactComponent as XXS } from '../../../../assets/images/return/cells/xxs.svg';
import { ReactComponent as XXXL } from '../../../../assets/images/return/cells/xxxl.svg';
import { Expands } from './types';

// Sorted small < big
export const expandsForCells: Expands[] = [
  {
    image: XXS,
    size: 'XXS',
  },
  {
    image: XS,
    size: 'XS',
  },
  {
    image: S,
    size: 'S',
  },
  {
    image: M,
    size: 'M',
  },
  {
    image: L,
    size: 'L',
  },
  {
    image: XL,
    size: 'XL',
  },

  {
    image: XXL,
    size: 'XXL',
  },
  {
    image: XXXL,
    size: 'XXXL',
  },
];
