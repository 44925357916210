import Button from '../../../../components/Button';
import { projectIs } from '../../../../constants';
import { useTranslate } from '../../../../hooks/useTranslate';

import { ButtonLaundry, ButtonsWrapper } from './styled';

export default function MultiTask(): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <ButtonsWrapper>
      {projectIs.omua || projectIs.omae ? (
        <ButtonLaundry variant="contained" to="/laundry">
          {staticTranslate('Laundry')}
        </ButtonLaundry>
      ) : (
        <>
          <Button variant="contained" to="/get-order">
            {staticTranslate('Receive')}
          </Button>
          <Button variant="contained" to="/return">
            {staticTranslate('Return')}
          </Button>
          <Button isActive={false} variant="contained" to="/">
            {staticTranslate('Send')}
          </Button>
        </>
      )}
    </ButtonsWrapper>
  );
}
