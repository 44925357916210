import styled from 'styled-components';

import { ASSORTMENT_LIST_HEIGHT } from '../../constants';

export const AssortmentUL = styled.ul`
  z-index: 2;
  background-color: ${({ theme }): string =>
    theme.palette.background.secondary};
  position: absolute;
  list-style-type: none;
  margin: 4px 0px;
  left: 0;

  width: 100%;
  max-height: ${ASSORTMENT_LIST_HEIGHT}px;
  overflow-y: auto;
  border: 1px solid ${({ theme }): string => theme.palette.border.shadow};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};
  box-shadow: 0px 0px 6px 0px
    ${({ theme }): string => theme.palette.border.shadow};
`;

export const AssortmentLI = styled.li`
  width: 100%;
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(1.75)} ${spacing(1.5)}`};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  border-bottom: 1px solid ${({ theme }): string => theme.palette.border.shadow};

  &:last-child {
    border-bottom: none;
  }
`;
