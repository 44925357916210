import styled from 'styled-components';

export const MainPaper = styled.main`
  width: 100%;
`;

export const TopDataWrapper = styled.div`
  height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const MapWrapper = styled.div`
  width: 50%;
  height: 100%;
`;

export const PostamatPhoto = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
`;
