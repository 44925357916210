import { useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import { useTranslate } from '../../../../hooks/useTranslate';
import { selectIsOrderReceived } from '../../../../store/slices/initialSlice';
import Thanks from '../Thanks';

import { LikeFaceIcon, TextIconWrapper } from './styled';

export default function LikeRate(): JSX.Element {
  const isOrderReceived = useSelector(selectIsOrderReceived);
  const { staticTranslate } = useTranslate();

  return (
    <>
      <TextIconWrapper>
        <Thanks textVariant="secondary" />
        <LikeFaceIcon role="img" />
      </TextIconWrapper>
      {!isOrderReceived && (
        <Button variant="clear" to="/">
          {staticTranslate('ToMain')}
        </Button>
      )}
    </>
  );
}
