import { ReactNode } from 'react';

import { FeatureId } from '../../types';
import Feedbacks from './components/Feedbacks/Feedbacks';
import Map from './components/Map';
import Photo from './components/Photo';
import { FeaturesProps } from './types';

export default function Features({
  selectedFeature,
}: FeaturesProps): JSX.Element {
  function getFeature(): ReactNode {
    if (selectedFeature === FeatureId.feedbacks) return <Feedbacks />;
    if (selectedFeature === FeatureId.map) return <Map />;
    if (selectedFeature === FeatureId.photo) return <Photo />;
  }

  return <>{getFeature()}</>;
}
