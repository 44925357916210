import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { projectIs } from '../../constants';
import { ASSORTMENT, ASSORTMENT_EN } from '../mocks/laundryClothes';
import { Clothe, InitialState } from '../types/laundryTypes';
import { RootReducer } from '../types/store';

const initialState: InitialState = {
  laundry: '',
  name: '',
  surname: '',
  phone: '',
  clothes: [
    {
      listItemId: uuidv4(),
      clothe: '',
      clotheId: '',
      quantity: 0,
    },
  ],
  status: 'idle',
  cellInfo: undefined,
  assortment: projectIs.omae ? ASSORTMENT_EN : ASSORTMENT,
};

const laundrySlice = createSlice({
  name: '@@laundry',
  initialState,
  reducers: {
    setName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setSurname: (state, { payload }: PayloadAction<string>) => {
      state.surname = payload;
    },
    setPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    setLaundry: (state, { payload }: PayloadAction<string>) => {
      state.laundry = payload;
    },
    setClothe: (
      state,
      { payload }: PayloadAction<Omit<Clothe, 'quantity'>>,
    ) => {
      const { listItemId: incomingId, ...clotheData } = payload;

      const clotheToChangeIdx = state.clothes.findIndex(
        ({ listItemId }) => listItemId === incomingId,
      );

      state.clothes[clotheToChangeIdx].clothe = clotheData.clothe;
      state.clothes[clotheToChangeIdx].clotheId = clotheData.clotheId;
    },
    setQuantity: (
      state,
      { payload }: PayloadAction<{ quantity: number; listItemId: string }>,
    ) => {
      const { clothes } = state;
      const { listItemId: incomingId, quantity } = payload;

      const clotheToChangeIdx = clothes.findIndex(
        ({ listItemId }) => listItemId === incomingId,
      );

      state.clothes[clotheToChangeIdx].quantity = quantity;
    },
    addClothe: (state) => {
      state.clothes.push({
        listItemId: uuidv4(),
        clothe: '',
        clotheId: '',
        quantity: 0,
      });
    },
    removeClothe: (
      state,
      { payload: idOfRemovedClothe }: PayloadAction<string>,
    ) => {
      const idx = state.clothes.findIndex(
        ({ listItemId }) => listItemId === idOfRemovedClothe,
      );
      state.clothes.splice(idx, 1);
    },
    clearData: () => initialState,
  },
});

export const laundryReducer = laundrySlice.reducer;

export const {
  setName,
  setPhone,
  addClothe,
  setLaundry,
  setSurname,
  removeClothe,
  setClothe,
  setQuantity,
  clearData,
} = laundrySlice.actions;

export const selectLaundry = ({ laundryReducer: { laundry } }: RootReducer) =>
  laundry;
export const selectName = ({ laundryReducer: { name } }: RootReducer) => name;
export const selectSurname = ({ laundryReducer: { surname } }: RootReducer) =>
  surname;
export const selectPhone = ({ laundryReducer: { phone } }: RootReducer) =>
  phone;
export const selectClothesVault = ({
  laundryReducer: { clothes },
}: RootReducer) => clothes;
export const selectLaundryStatus = ({
  laundryReducer: { status },
}: RootReducer) => status;
export const selectLaundryCellInfo = ({
  laundryReducer: { cellInfo },
}: RootReducer) => cellInfo;
export const selectAssortment = ({
  laundryReducer: { assortment },
}: RootReducer) => assortment;
export const selectClothes = ({ laundryReducer: { clothes } }: RootReducer) => {
  return clothes.filter((clothe) => clothe.clothe);
};
