import styled from 'styled-components';

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
  gap: ${({ theme }): string => theme.spacing(1.5)};
`;

export const RatingData = styled.span`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  color: ${({ theme }): string => theme.palette.text.primary};
  opacity: 0.6;
  font-size: 0.6em;
`;

export const NoData = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
