import { NavigateOptions, To, useNavigate, useParams } from 'react-router-dom';

import { useCheckFlow } from './useCheckFlow';

interface CustomNavigate {
  navigateWithUid: (to: To, options?: NavigateOptions) => void;
  navigateToErrorPage: (errorType?: string) => void;
}

export function useCustomNavigate(): CustomNavigate {
  const navigate = useNavigate();
  const { uid } = useParams();

  const { basePath, query } = useCheckFlow();

  function navigateWithUid(to: To, options?: NavigateOptions): void {
    navigate(`${basePath}/${uid}${to}${query}`, options);
  }

  function navigateToErrorPage(errorType?: string): void {
    navigate(`${basePath}/${uid}/error${query}`, {
      state: errorType,
      replace: true,
    });
  }

  return {
    navigateWithUid,
    navigateToErrorPage,
  };
}
