import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

import { ReactComponent as Chevron } from '../../../../assets/images/chevron.svg';
import { ReactComponent as MiniI } from '../../../../assets/images/miniInfo.svg';
import { ReactComponent as MiniL } from '../../../../assets/images/miniLocation.svg';
import { ReactComponent as MiniT } from '../../../../assets/images/miniTime.svg';
import Link from '../../../../components/Link';
import { infoTextTemplate } from '../../../../theme/pagesElements';
import { DirInfo, IndicatorProps } from './types';

function applyChevronDir({ dir }: DirInfo): FlattenSimpleInterpolation | false {
  return (
    dir === 'rtl' &&
    css`
      transform: rotateY(180deg);
    `
  );
}

function applyMoreInfoContainerDir({
  dir,
}: DirInfo): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return dir === 'ltr'
    ? css`
        padding-right: ${({ theme }): string => theme.spacing(3)};
        padding-left: calc(${({ theme }): string => theme.spacing(6)} + 1px);
      `
    : css`
        padding-left: ${({ theme }): string => theme.spacing(3)};
        padding-right: calc(${({ theme }): string => theme.spacing(6)} + 1px);
      `;
}

const border = css`
  border-bottom: 1px solid
    ${({ theme }): string => theme.palette.border.secondary};
`;

const commonStyles = css`
  min-width: 10px;
  path {
    fill: ${({ theme }): string => theme.palette.text.primary};
  }
`;

function applyColor({ theme, isOnline }: IndicatorProps): string {
  return isOnline ? theme.palette.success : theme.palette.error;
}

export const LocationIcon = styled(MiniL)`
  ${commonStyles};
`;

export const InfoIcon = styled(MiniI)`
  ${commonStyles};
`;

export const TimeIcon = styled(MiniT)`
  ${commonStyles};
`;

export const ChevronIcon = styled(Chevron)`
  height: 11px;
  ${commonStyles};
  ${applyChevronDir};
`;

export const PostamatInfoContainer = styled.div`
  ${border};
  width: 100%;
  padding-top: ${({ theme: { spacing } }): string => spacing(4)};
`;

export const TopWrapper = styled.div`
  ${border};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme: { spacing } }): string => spacing(3)};
  padding-bottom: ${({ theme: { spacing } }): string => spacing(1.5)};
  gap: ${({ theme }): string => theme.spacing(3)};
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};
`;

export const StatusText = styled.span`
  ${infoTextTemplate};
  color: ${applyColor};
  text-transform: capitalize;
`;

export const IndicatorDot = styled.div`
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  background: ${applyColor};
`;

export const PostamatNum = styled.p`
  ${infoTextTemplate};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const MoreInfoContainer = styled(Link)`
  ${border};
  text-decoration-color: ${({ theme }): string => theme.palette.text.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }): string => theme.spacing(1.5)} 0px;
  ${applyMoreInfoContainerDir};
`;

export const MoreInfoMessage = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const MainInfoContainer = styled.div`
  width: 100%;
  padding: ${({ theme }): string => theme.spacing(3)};
  padding: ${({ theme }): string => theme.spacing(3)};
`;

export const MainInfoGroup = styled.div`
  display: flex;
  gap: ${({ theme }): string => theme.spacing(2)};
  align-items: center;
  margin-bottom: ${({ theme }): string => theme.spacing(2)};

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const MainInfoText = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
