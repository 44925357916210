import styled from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const DetailTitle = styled.h1`
  text-align: center;
  ${infoTextTemplate};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  margin-top: ${({ theme }): string => theme.spacing(6)};
  margin-bottom: ${({ theme }): string => theme.spacing(6)};
`;
