import { Dispatch, SetStateAction } from 'react';

import { FontSize } from '../../types/theme';

export interface FontSizeButtonProps {
  fontSizeCrop: FontSize;
  isActive: boolean;
}

export enum CheckboxName {
  highlightLinks = 'highlightLinks',
  arialFont = 'arialFont',
}

export interface Buttons {
  size: FontSize;
  ariaLabel: string;
}

export type CheckboxAction = {
  [K in keyof typeof CheckboxName]: Dispatch<SetStateAction<boolean>>;
};
