import styled from 'styled-components';

import { ReactComponent as SSVG } from '../../../../assets/images/success.svg';
import { infoTextTemplate } from '../../../../theme/pagesElements';

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }): string => theme.spacing(10)};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
  padding: 0px ${({ theme }): string => theme.spacing(2)};
  text-align: center;
`;

export const Success = styled(SSVG)`
  margin-bottom: ${({ theme }): string => theme.spacing(5)};
  width: 80px;
  height: 80px;
  path {
    stroke: ${({ theme }): string => theme.palette.text.secondary};
  }
`;

export const GreatHead = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  font-size: 1.45em;
  margin-bottom: ${({ theme }): string => theme.spacing(6)};
  color: ${({ theme }): string => theme.palette.text.secondary};
`;

export const Message = styled.p`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  color: ${({ theme }): string => theme.palette.text.secondary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const FullDate = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  font-size: 1.1em;
  color: ${({ theme }): string => theme.palette.text.secondary};
`;
