import { useSelector } from 'react-redux';

import { localize } from '../dictionary';
import { selectLang } from '../store/slices/langSlice';
import { useDynamicTranslate } from './useDynamicTranslate';

interface Translate {
  staticTranslate: (value: string) => string;
  dynamicTranslate: (value: string) => string;
}

export function useTranslate(): Translate {
  const currentLang = useSelector(selectLang);

  return {
    staticTranslate: (value: string) => localize(value, currentLang),
    dynamicTranslate: useDynamicTranslate,
  };
}
