import { useSelector } from 'react-redux';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { ReactComponent as AttentionSVG } from '../../../../assets/images/return/attention.svg';
import { selectThemeSettings } from '../../../../store/slices/themeSlice';
import { ThemeMode } from '../../../../types/theme';

function useIconColorSetting():
  | FlattenInterpolation<ThemeProps<DefaultTheme>>
  | undefined {
  const { themeMode } = useSelector(selectThemeSettings);
  if (themeMode === ThemeMode.contrast) {
    return css`
      path {
        fill: ${({ theme }): string => theme.palette.grey[100]};
      }
    `;
  }
}

export const AttentionIcon = styled(AttentionSVG)`
  ${useIconColorSetting}
  min-width: 32px;
`;

export const AttentionBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${({ theme: { spacing } }): string => `${spacing(3)} ${spacing(2)}`};
  gap: ${({ theme }): string => theme.spacing(2)};

  background-color: ${({ theme }): string =>
    theme.palette.background.attention};
`;

export const AttentionText = styled.p`
  color: ${({ theme }): string => theme.palette.grey[100]};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
`;
