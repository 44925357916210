import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTypedDispatch } from '../../../../../../../store';
import {
  getFeedback,
  selectFeedbacks,
  selectFeedbacksStatus,
  selectLoadingAvailability,
  setIsFeedbacksRequestAvailable,
} from '../../../../../../../store/slices/feedbackSlice';
import {
  selectDeviceInfo,
  selectIsOrderReceived,
} from '../../../../../../../store/slices/initialSlice';
import { Feedback } from '../../../../../../../store/types/feedbackResponse';
import { STARS_PERCENTS } from '../constants';
import { StarsPercents } from '../types';
import { getFeedbacksPercent } from '../utils/getFeedbacksPercent';

export function useFeedbacks() {
  const [shownFeedback, setShownFeedback] = useState<Feedback>();
  const [percents, setPercents] = useState<StarsPercents>(STARS_PERCENTS);
  const [averageRate, setAverageRate] = useState(0);
  const dispatch = useTypedDispatch();

  const { device_id: deviceId } = useSelector(selectDeviceInfo);
  const feedbacks = useSelector(selectFeedbacks);
  const isFeedbacksRequestAvailable = useSelector(selectLoadingAvailability);
  const isOrderReceived = useSelector(selectIsOrderReceived);
  const status = useSelector(selectFeedbacksStatus);

  useEffect(() => {
    if (deviceId && isFeedbacksRequestAvailable && !isOrderReceived) {
      dispatch(getFeedback(deviceId))
        .unwrap()
        .finally(() => dispatch(setIsFeedbacksRequestAvailable(false)));
    }
  }, [deviceId, isOrderReceived, isFeedbacksRequestAvailable, dispatch]);

  useEffect(() => {
    if (feedbacks.length) {
      const lastFeedbackWithComment = feedbacks.find(
        (el) => el.comment && !el.type,
      );
      const feedBack = lastFeedbackWithComment || feedbacks[0];
      const feedbacksRatings = feedbacks.map(({ rating }) => rating);

      const average = (
        feedbacksRatings.reduce((a, b) => a + b) / feedbacksRatings.length
      ).toFixed(1);

      setPercents(getFeedbacksPercent(feedbacksRatings));
      setAverageRate(Number(average));
      setShownFeedback(feedBack);
    }
  }, [feedbacks]);

  return {
    shownFeedback,
    percents,
    averageRate,
    feedbacks,
    status,
  };
}
