import styled from 'styled-components';

export const Cell = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 76%; // Get 1.3 proportion of image
`;

export const PhotoPaper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
`;

export const PostamatPhoto = styled.img`
  width: 100%;
`;
