import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTypedDispatch } from '../../../store';
import {
  selectCountdownTimer,
  selectCountdownValue,
  selectDateTo,
  setCountdown,
  setCountdownTimer,
  stopCountdown,
} from '../../../store/slices/receiveSlice';

export function useCountdown(): string {
  const countdownValue = useSelector(selectCountdownValue);
  const countdownTime = useSelector(selectCountdownTimer);
  const dateTo = useSelector(selectDateTo);

  const dispatch = useTypedDispatch();

  const startCountdown = useCallback(
    (countdownTo: number) => {
      function getTimer(): number {
        const dateNow = Date.now();
        return Math.floor((countdownTo - dateNow) / 1000);
      }

      dispatch(setCountdownTimer(getTimer()));

      const interval = setInterval(() => {
        const timer = getTimer();
        if (timer < 0) {
          clearInterval(interval);
          dispatch(stopCountdown());
          return;
        }
        dispatch(setCountdownTimer(timer));
      }, 1000);

      return interval;
    },
    [dispatch],
  );

  useEffect(() => {
    if (!dateTo) return;
    const interval = startCountdown(dateTo);
    return () => clearInterval(interval);
  }, [dateTo, startCountdown]);

  useEffect(() => {
    if (countdownTime <= 0) {
      dispatch(setCountdown(''));
      return;
    }
    const minutes = parseInt(String(countdownTime / 60), 10);
    const seconds = parseInt(String(countdownTime % 60), 10);

    const displayedMinutes = minutes >= 10 ? `${minutes}` : `0${minutes}`;
    const displayedSeconds = seconds >= 10 ? `${seconds}` : `0${seconds}`;

    dispatch(setCountdown(`${displayedMinutes}:${displayedSeconds}`));
  }, [countdownTime, dispatch]);

  return countdownValue;
}
