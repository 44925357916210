import styled, { css } from 'styled-components';

import { ReactComponent as Minus } from '../../../../../../../../assets/images/laundry/minus.svg';
import { ReactComponent as Plus } from '../../../../../../../../assets/images/laundry/plus.svg';
import { QuantityComponentsProps } from './types';

const iconStyle = css`
  min-width: 24px;
  min-height: 24px;
  path {
    stroke: ${({ theme }): string => theme.palette.text.primary};
  }
`;

function applyInactiveStyle({ isActive }: QuantityComponentsProps) {
  return isActive
    ? css`
        opacity: 1;
      `
    : css`
        pointer-events: none;
        opacity: 0.5;
      `;
}

export const PlusIcon = styled(Plus)`
  ${iconStyle};
`;
export const MinusIcon = styled(Minus)`
  ${iconStyle};
`;

export const QuantityButton = styled.button`
  border: none;
  outline: none;
  background-color: ${({ theme }): string => theme.palette.background.rateLine};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }): string => theme.spacing()};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  ${applyInactiveStyle};
`;

export const QuantityValue = styled.p`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 ${({ theme }): string => theme.spacing(1.75)};

  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};

  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${applyInactiveStyle};
`;
