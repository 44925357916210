import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { projectIs } from '../../../constants';
import { useTranslate } from '../../../hooks/useTranslate';
import { selectAppFlow } from '../../../store/slices/flowSlice';
import {
  selectDifference,
  selectSupportPhone,
} from '../../../store/slices/initialSlice';
import { selectLang } from '../../../store/slices/langSlice';
import { ErrorType } from '../../../store/types/errors';
import { Flow } from '../../../types/appLogic';
import { ErrorPagePreset, ErrorPageSettings } from '../types';

import {
  InPostamatErrIcon,
  NoOrderErrIcon,
  OpenCellErrIcon,
  PaymentErrIcon,
} from '../styled';

export function useErrorPageSettings(): ErrorPageSettings {
  const [errorPageData, setErrorPageData] = useState<ErrorPagePreset>({
    image: '',
    title: '',
    description: '',
  });

  const currentLang = useSelector(selectLang);
  const supportPhone = useSelector(selectSupportPhone);
  const difference = useSelector(selectDifference);
  const appFlow = useSelector(selectAppFlow);

  const { staticTranslate } = useTranslate();

  const location = useLocation();
  const errorType =
    (location.state as ErrorType) || ErrorType.ORDER_IN_POSTAMAT_ERROR;

  const getErrorPageData = useCallback((): ErrorPagePreset => {
    if (errorType === ErrorType.NO_ORDER_ERROR) {
      return {
        title: `${staticTranslate('NoOrderError')}`,
        description: `${staticTranslate('NoOrderError2')}`,
        image: <NoOrderErrIcon />,
      };
    }

    if (errorType === ErrorType.OPEN_CELL_ERROR) {
      return {
        title: `${staticTranslate('OpenCellError')}`,
        description: `${staticTranslate('OpenCellError2')}`,
        image: <OpenCellErrIcon />,
      };
    }

    if (errorType === ErrorType.PAYMENT_ERROR) {
      return {
        title: `${staticTranslate('PaymentError')}`,
        description: `${staticTranslate('PaymentError2')}`,
        image: <PaymentErrIcon />,
      };
    }

    return {
      title: `${staticTranslate('OrderIsNotInPostamatError')}`,
      description: `${staticTranslate('OrderIsNotInPostamatError2')}`,
      image: <InPostamatErrIcon />,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType, currentLang]);

  useEffect(() => {
    setErrorPageData(getErrorPageData());
  }, [getErrorPageData]);

  return {
    difference,
    errorPageData,
    supportPhone,
    isShowDifference: errorType === ErrorType.PAYMENT_ERROR && !!difference,
    isShowSupportPhone:
      errorType === ErrorType.OPEN_CELL_ERROR &&
      !!supportPhone &&
      !projectIs.omis,
    isShowButton:
      appFlow === Flow.device ||
      (errorType !== ErrorType.NO_ORDER_ERROR &&
        errorType !== ErrorType.ORDER_IN_POSTAMAT_ERROR),
    isShowLegalInfo:
      errorType === ErrorType.ORDER_IN_POSTAMAT_ERROR && projectIs.omkaz,
  };
}
