import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MAP_TYPE } from '../../constants';
import { selectLang } from '../../store/slices/langSlice';
import MapGoogle from './components/MapGoogle';
import MapView from './components/MapView';
import { MapManagerProps } from './types';

import { MapWrapper } from './styled';

export default function MapManager({
  width,
  height,
}: MapManagerProps): JSX.Element {
  const [isVisible, setVisible] = useState(true);
  const currentLang = useSelector(selectLang);

  // This needed for reload map, when lang was changed
  useEffect(() => {
    setVisible(false);
  }, [currentLang]);

  useEffect(() => {
    if (!isVisible) setVisible(true);
  }, [isVisible]);

  const shownMap =
    MAP_TYPE === 'MAP_TYPE_GOOGLE' ? (
      <MapGoogle width={width} height={height} />
    ) : (
      <MapView width={width} height={height} />
    );

  return <MapWrapper>{isVisible && shownMap}</MapWrapper>;
}
