import { ReactNode, useEffect, useState } from 'react';

import { DeviceRatingStarsProps } from './types';

import { Star, StarsWrapper, StarWrapper } from './styled';

const STARS_NUMBER = 5;

export default function DeviceRatingStars({
  rating,
}: DeviceRatingStarsProps): JSX.Element {
  const [stars, setStars] = useState<ReactNode[]>([]);

  useEffect(() => {
    const starsArr = [];
    for (let i = 1; i <= STARS_NUMBER; i++) {
      if (i <= rating) {
        starsArr.push(
          <StarWrapper id={String(i)} isFilled key={i}>
            <Star />
          </StarWrapper>,
        );
      } else {
        starsArr.push(
          <StarWrapper id={String(i)} isFilled={false} key={i}>
            <Star />
          </StarWrapper>,
        );
      }
    }

    setStars(starsArr);
  }, [rating]);
  return <StarsWrapper>{stars.map((star) => star)}</StarsWrapper>;
}
