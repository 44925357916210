import { useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import { useTranslate } from '../../../../hooks/useTranslate';
import {
  selectClients,
  selectDifference,
  selectUid,
} from '../../../../store/slices/initialSlice';
import { getDir } from '../../../../utils/getDir';
import { useExtendSettings } from './hooks/useExtendSettings';
import { usePayments } from './hooks/usePayments';

import {
  AttentionText,
  Clients,
  ClientsDataContainer,
  InfoText,
  InfoWrapper,
  OrderManagementContainer,
  OrderPayment,
  OrderPersonsWrapper,
  OrderPlace,
  OrderStorage,
  Phone,
  Price,
  Receiver,
  ReceiverName,
  Sender,
  SenderName,
} from './styled';

export default function OrderInfo(): JSX.Element {
  const difference = useSelector(selectDifference);
  const orderUid = useSelector(selectUid);

  const { staticTranslate } = useTranslate();

  const { expDate, isShowExtendButton } = useExtendSettings();
  const { PayButton, isShowPayButton } = usePayments();

  const { recipientName, recipientPhone, senderName, senderPhone } =
    useSelector(selectClients);

  const isShowConnectionLine =
    !!(recipientName || recipientPhone) && !!(senderName || senderPhone);

  const dir = getDir();

  return (
    <>
      <ClientsDataContainer>
        <OrderPlace role="contentinfo" aria-label="Order place">
          {staticTranslate('InPostomat')}
        </OrderPlace>
        <OrderPersonsWrapper>
          <Clients direction={dir} isShowConnectionLine={isShowConnectionLine}>
            {(senderName || senderPhone) && (
              <Sender>
                {senderName && (
                  <SenderName aria-label="Sender name">
                    <strong>{staticTranslate('From')}:</strong> {senderName}
                  </SenderName>
                )}
                {senderPhone && (
                  <Phone aria-label="Sender phone">{senderPhone}</Phone>
                )}
              </Sender>
            )}

            {(recipientName || recipientPhone) && (
              <Receiver>
                {recipientName && (
                  <ReceiverName aria-label="Receiver name">
                    <strong>{staticTranslate('To')}:</strong> {recipientName}
                  </ReceiverName>
                )}
                {recipientPhone && (
                  <Phone aria-label="Receiver phone">{recipientPhone}</Phone>
                )}
              </Receiver>
            )}
          </Clients>
        </OrderPersonsWrapper>
      </ClientsDataContainer>

      <OrderManagementContainer>
        {!!difference && (
          <OrderPayment>
            <InfoWrapper>
              <InfoText>
                {staticTranslate('ToPay')}:{' '}
                <Price aria-label="Price to pay">{difference}</Price>
              </InfoText>

              <AttentionText>
                {staticTranslate('OrderOnlyOnline')}
              </AttentionText>
            </InfoWrapper>
            {isShowPayButton && PayButton}
          </OrderPayment>
        )}

        <OrderStorage>
          <InfoWrapper>
            <InfoText>{staticTranslate('StorageUntil')}</InfoText>
            <InfoText>{expDate}</InfoText>
          </InfoWrapper>
          {isShowExtendButton && (
            <Button
              isActive={!!orderUid}
              variant="contained"
              size="m"
              to="/order-extension"
            >
              {staticTranslate('Extend')}
            </Button>
          )}
        </OrderStorage>
      </OrderManagementContainer>
    </>
  );
}
