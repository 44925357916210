import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { selectThemeSettings } from '../../../../store/slices/themeSlice';
import { infoTextTemplate } from '../../../../theme/pagesElements';
import { FontSize } from '../../../../types/theme';
import { getDir } from '../../../../utils/getDir';

export const LabelText = styled.span`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: ${({ theme }): string => theme.spacing(2.5)};

  &:before {
    content: '';
    display: block;
    left: 0px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: 2px solid ${({ theme }): string => theme.palette.primary[30]};
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background: ${({ theme }): string => theme.palette.primary[30]};
    border-radius: 50%;
    opacity: 0;
    transform: scale(0, 0);
    transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
  }
`;

export const Label = styled.label(({ theme }) => {
  const { fontSize } = useSelector(selectThemeSettings);

  const dir = getDir();

  const paddingSide = dir === 'ltr' ? 'padding-left' : 'padding-right';

  let paddingSize = '85px';

  if (fontSize === FontSize.largest) paddingSize = '65px';
  if (fontSize === FontSize.large) paddingSize = '75px';

  const padding = `${paddingSide}: ${paddingSize}`;

  const dotSide =
    dir === 'ltr'
      ? css`
          left: 4px;
        `
      : css`
          right: 4px;
        `;

  return css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 67px;
    border: 2px solid ${theme.palette.border.secondary};
    border-radius: ${theme.shape.borderRadius.l};
    ${padding};

    ${LabelText}:after {
      ${dotSide};
    }
  `;
});

export const RadioInput = styled.input`
  display: none;
`;

export const RadioWrapper = styled.div`
  ${RadioInput}:checked + ${Label} > ${LabelText}:after {
    opacity: 1;
    transform: scale(1, 1);
  }

  ${RadioInput}:checked + ${Label} {
    border-color: ${({ theme }): string => theme.palette.primary[30]};
  }
`;
