import { TextAreaProps } from './types';

import { Form, Label, StyledTextArea } from './styled';

export default function TextArea({
  placeholder,
  textAreaText,
  setTextAreaText,
}: TextAreaProps) {
  return (
    <Form>
      <Label htmlFor="deviceFeedback">
        <StyledTextArea
          id="deviceFeedback"
          value={textAreaText}
          placeholder={placeholder}
          onChange={(e) => setTextAreaText(e.target.value)}
        />
      </Label>
    </Form>
  );
}
