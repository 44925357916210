import styled, { css } from 'styled-components';

import { ReactComponent as InPostamatErr } from '../../assets/images/noInPostamatError.svg';
import { ReactComponent as NoOrderErr } from '../../assets/images/noOrderError.svg';
import { ReactComponent as OpenCellErr } from '../../assets/images/openCellError.svg';
import { ReactComponent as PaymentErr } from '../../assets/images/paymentError.svg';
import Link from '../../components/Link';
import { infoTextTemplate } from '../../theme/pagesElements';
import { HEADER_AND_MARGIN_HEIGHT } from './constants';

const iconFill = css`
  path {
    fill: ${({ theme }): string => theme.palette.primary[30]};
  }
`;

export const InPostamatErrIcon = styled(InPostamatErr)`
  ${iconFill};
`;

export const OpenCellErrIcon = styled(OpenCellErr)`
  ${iconFill};
`;

export const NoOrderErrIcon = styled(NoOrderErr)`
  ${iconFill};
`;

export const PaymentErrIcon = styled(PaymentErr)`
  ${iconFill};
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - ${HEADER_AND_MARGIN_HEIGHT});
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }): string => theme.spacing(12)};
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const ErrorTitle = styled.h1`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-align: center;
  width: 100%;
  margin-bottom: ${({ theme }): string => theme.spacing(6.5)};
`;

export const ErrorMessage = styled.p`
  width: 100%;
  ${infoTextTemplate};
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const VisualWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Difference = styled.p`
  text-align: center;
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  font-size: 1.18em;
  margin-bottom: ${({ theme }): string => theme.spacing(5.5)};
`;

export const BtnWithDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(4.5)};
`;

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  width: 100%;
`;

export const PhoneText = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing(0.5)};
`;

export const Number = styled.a`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: 1.1em;
  text-decoration: underline;

  &:visited {
    color: ${({ theme }): string => theme.palette.text.primary};
  }
`;

export const LegalInfoWrapper = styled.div`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }): string => theme.spacing(3)};
  width: 100%;
  gap: ${({ theme }): string => theme.spacing()};
`;

export const LegalText = styled.p`
  width: 320px;
`;

export const LegalLink = styled(Link)`
  display: inline;
  text-decoration: underline;
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const PhoneNumber = styled.a`
  color: ${({ theme }): string => theme.palette.text.primary};
  text-decoration: underline;
`;
