import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDeviceInfo } from '../../../../../../store/slices/initialSlice';
import CloseButton from './components/CloseButton';
import PhotoItem from './components/PhotoItem';

import { MainContainer, PhotoGrid, Popup } from './styled';

export default function Photo() {
  const [selectedPhoto, setSelectedPhoto] = useState('');
  const deviceInfo = useSelector(selectDeviceInfo);

  const images = [...deviceInfo.images];

  function selectPhoto(photo: string): void {
    setSelectedPhoto(photo);
  }

  function closePhoto(): void {
    setSelectedPhoto('');
  }

  return (
    <MainContainer isPopup={!!selectedPhoto}>
      <PhotoGrid role="group" aria-label="Photos of postamat">
        {images.map((photo, idx) => {
          // eslint-disable-next-line react/no-array-index-key
          return <PhotoItem key={idx} photo={photo} onClick={selectPhoto} />;
        })}
      </PhotoGrid>
      <Popup>
        <PhotoItem
          closeButton={<CloseButton onClick={closePhoto} />}
          photo={selectedPhoto}
        />
      </Popup>
    </MainContainer>
  );
}
