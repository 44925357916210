import styled from 'styled-components';

import { infoTextTemplate } from '../../theme/pagesElements';

export const Main = styled.main`
  width: 100%;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  margin-top: ${({ theme }): string => theme.spacing(6)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  position: relative;
  margin-bottom: ${({ theme }): string => theme.spacing(7)};
`;

export const Label = styled.label`
  display: block;
  text-align: center;
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing(4)};
`;

export const TextInput = styled.input`
  ${infoTextTemplate};
  position: relative;
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-align: center;

  padding: ${({ theme }): string => theme.spacing(1.5)} 0;
  width: 100%;

  outline: none;
  border: 1px solid ${({ theme }): string => theme.palette.border.secondary};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};

  &:focus {
    border: 1px solid ${({ theme }): string => theme.palette.border.primary};
    box-shadow: 0 0 4px ${({ theme }): string => theme.palette.border.primary};
  }
`;
