import { useSelector } from 'react-redux';

import { selectExpirationDate } from '../store/slices/initialSlice';
import { useTranslate } from './useTranslate';

const MS_IN_HOUR = 3600000;

const MONTHS = {
  0: 'MonthJanuary',
  1: 'MonthFebruary',
  2: 'MonthMarch',
  3: 'MonthApril',
  4: 'MonthMay',
  5: 'MonthJune',
  6: 'MonthJuly',
  7: 'MonthAugust',
  8: 'MonthSeptember',
  9: 'MonthOctober',
  10: 'MonthNovember',
  11: 'MonthDecember',
};

interface StorageDate {
  expDate: string;
  diffHours: number;
}

export function useStorageDate(): StorageDate {
  const expirationDateUnix = useSelector(selectExpirationDate);
  const { staticTranslate } = useTranslate();

  const expirationDate = new Date(expirationDateUnix);
  const now = Date.now();

  const expDay = expirationDate.getDate();
  const expMonth = MONTHS[expirationDate.getMonth() as keyof typeof MONTHS];
  const expYear = expirationDate.getFullYear();

  const diffHours = (expirationDateUnix - now) / MS_IN_HOUR;

  const expDate = `${expDay} ${staticTranslate(expMonth)} ${expYear}`;

  return {
    expDate,
    diffHours,
  };
}
