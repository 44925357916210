import styled from 'styled-components';

import { WrapperProps } from './types';

function applyWrapperBG({ isColorBG, theme }: WrapperProps): string {
  return isColorBG
    ? theme.palette.background.primary
    : theme.palette.background.secondary;
}

export const CommonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const TopWrapper = styled.div`
  width: inherit;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  background-color: ${applyWrapperBG};
  padding-bottom: ${({ theme }): string => theme.spacing(3)};
`;
