import { useTranslate } from '../../../hooks/useTranslate';
import { PopupProps } from './types';

import {
  CloseIcon,
  FunctionsList,
  ListItem,
  PopupContainer,
  PopupTitle,
} from './styled';

export default function Popup({
  isVisible,
  closePopup,
}: PopupProps): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <PopupContainer isVisible={isVisible}>
      <CloseIcon
        role="button"
        aria-label="Close info popup"
        onClick={closePopup}
      />
      <PopupTitle>{staticTranslate('Functions')}:</PopupTitle>
      <FunctionsList>
        <ListItem>{staticTranslate('InformationAboutOrder')}</ListItem>
        <ListItem>
          {staticTranslate('InformationAboutTheParcelLocker')}
        </ListItem>
        <ListItem>{staticTranslate('PaymentForServices')}</ListItem>
        <ListItem>{staticTranslate('OrderCollection')}</ListItem>
        <ListItem>{staticTranslate('ExteningOrderStoringTime')}</ListItem>
      </FunctionsList>
    </PopupContainer>
  );
}
