import styled from 'styled-components';

import { ReactComponent as DefaultImg } from '../../assets/images/landscapeDefault.svg';
import { ReactComponent as LogoDefault } from '../../assets/images/landscapeLogo.svg';
import { ReactComponent as OmisImg } from '../../assets/images/landscapeOmis.svg';
import { ReactComponent as LogoOmis } from '../../assets/images/logoOmis.svg';
import { infoTextTemplate } from '../../theme/pagesElements';

export const LandscapeContainer = styled.main`
  height: 100vh;
  width: 100%;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 650px) {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 263px;
`;

export const Message = styled.p`
  width: 100%;
  text-align: center;
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-top: ${({ theme }): string => theme.spacing(4)};
`;

export const ColorMessage = styled(Message)`
  color: ${({ theme }): string => theme.palette.primary[30]};
`;

export const DefaultLogo = styled(LogoDefault)``;

export const OmisLogo = styled(LogoOmis)`
  path {
    fill: ${({ theme }): string => theme.palette.grey[100]};
  }
`;

export const ImageDefault = styled(DefaultImg)``;

export const ImageOmis = styled(OmisImg)``;
