import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { getDir } from '../../utils/getDir';
import { PaperProps, Step } from './types';

function setCurrentContainer({ step }: PaperProps): FlattenSimpleInterpolation {
  const dir = getDir();
  const operator = dir === 'ltr' ? '-' : '+';

  return step === Step.phone
    ? css`
        transform: translateX(0vw);
      `
    : css`
        transform: translateX(${operator}100vw);
      `;
}

export const ContainersWrapper = styled.main`
  margin-top: ${({ theme }): string => theme.spacing(10)};
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;

export const ContainersPaper = styled.div`
  display: flex;
  flex-direction: row;
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${setCurrentContainer};
`;

export const ContentWrapper = styled.div`
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(4)};
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  padding-bottom: 5px;
`;

export const Text = styled.p`
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
`;
