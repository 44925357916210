import { useSelector } from 'react-redux';

import WorkingHours from '../../../../components/WorkingHours';
import { useCheckFlow } from '../../../../hooks/useCheckFlow';
import { useTranslate } from '../../../../hooks/useTranslate';
import { selectDeviceInfo } from '../../../../store/slices/initialSlice';
import { Flow } from '../../../../types/appLogic';
import { getDir } from '../../../../utils/getDir';

import {
  ChevronIcon,
  IndicatorDot,
  InfoIcon,
  LocationIcon,
  MainInfoContainer,
  MainInfoGroup,
  MainInfoText,
  MoreInfoContainer,
  MoreInfoMessage,
  PostamatInfoContainer,
  PostamatNum,
  StatusText,
  StatusWrapper,
  TimeIcon,
  TopWrapper,
} from './styled';

export default function PostamatInfo(): JSX.Element {
  const deviceInfo = useSelector(selectDeviceInfo);
  const isOnline = deviceInfo.is_online;
  const { dynamicTranslate, staticTranslate } = useTranslate();
  const { flow } = useCheckFlow();

  const dir = getDir();

  const availabilityInfo = isOnline
    ? staticTranslate('Available')
    : staticTranslate('NotAvailable');

  // TODO: resolve case with different description places
  const description =
    flow === Flow.device
      ? deviceInfo.location_description
      : deviceInfo.device_description;

  return (
    <PostamatInfoContainer>
      <TopWrapper>
        <StatusWrapper>
          <IndicatorDot isOnline={isOnline} />
          <StatusText
            isOnline={isOnline}
            aria-label="Postamat availability status"
          >
            {availabilityInfo}
          </StatusText>
        </StatusWrapper>
        <PostamatNum aria-label="Postamat id">
          {staticTranslate('ParcelLockerNum')} {deviceInfo.device_id}
        </PostamatNum>
      </TopWrapper>
      <MoreInfoContainer dir={dir} to="/device-info">
        <MoreInfoMessage>{staticTranslate('DeviceDetail')}</MoreInfoMessage>
        <ChevronIcon dir={dir} />
      </MoreInfoContainer>
      <MainInfoContainer>
        <MainInfoGroup>
          <LocationIcon role="img" aria-label="Location icon" />

          <MainInfoText aria-label="Postamat address">
            {dynamicTranslate(deviceInfo.address)}
          </MainInfoText>
        </MainInfoGroup>
        <MainInfoGroup>
          <InfoIcon role="img" aria-label="Information icon" />

          <MainInfoText aria-label="Postamat additional information">
            {dynamicTranslate(description || '')}
          </MainInfoText>
        </MainInfoGroup>
        <MainInfoGroup>
          <WorkingHours icon={<TimeIcon role="img" aria-label="Time icon" />} />
        </MainInfoGroup>
      </MainInfoContainer>
    </PostamatInfoContainer>
  );
}
