import { Extend, Extends, isDev, isProd } from '../index';

const Ext: Extend = {};

Ext[isProd] = {
  Payment: [
    {
      Type: 'CDEK_PAY',
      Data: {
        ExternalURL: 'https://www.cdek.ru/ru/payment',
      },
    },
  ],
};
Ext[isDev] = {
  Payment: [
    {
      Type: 'CDEK_PAY',
      Data: {
        ExternalURL: 'https://www.cdek.ru/ru/payment',
      },
    },
  ],
};

Extends.MNCDK = Ext;

// TODO: need fix
document.getElementById('logo-icon')?.setAttribute('href', '');

export {};
