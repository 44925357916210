import { useEffect } from 'react';

import { useTypedDispatch } from '../../../../../../../../store';
import { setQuantity } from '../../../../../../../../store/slices/laundrySlice';
import { QuantityProps } from './types';

import { MinusIcon, PlusIcon, QuantityButton, QuantityValue } from './styled';

export default function Quantity({
  quantity,
  clotheId,
  listItemId,
}: QuantityProps): JSX.Element {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (clotheId) {
      dispatch(
        setQuantity({
          listItemId,
          quantity: 1,
        }),
      );
    }
  }, [clotheId, listItemId, dispatch]);

  const setQuantityHandler = (action: 'inc' | 'dec') => () => {
    if (action === 'inc' && quantity) {
      dispatch(
        setQuantity({
          listItemId,
          quantity: quantity + 1,
        }),
      );
    } else if (quantity > 1) {
      dispatch(
        setQuantity({
          listItemId,
          quantity: quantity - 1,
        }),
      );
    }
  };

  return (
    <>
      <QuantityButton
        aria-label="Decrease quantity of selected clothe"
        onClick={setQuantityHandler('dec')}
        isActive={quantity > 1}
      >
        <MinusIcon />
      </QuantityButton>
      <QuantityValue
        aria-label="Quantity of selected clothe"
        isActive={!!quantity}
      >
        {quantity}
      </QuantityValue>
      <QuantityButton
        aria-label="Increase quantity of selected clothe"
        onClick={setQuantityHandler('inc')}
        isActive={!!quantity}
      >
        <PlusIcon />
      </QuantityButton>
    </>
  );
}
