import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../hooks/useTranslate';
import { selectLang } from '../../store/slices/langSlice';
import { getDir } from '../../utils/getDir';
import WorkSchedule from './components/WorkSchedule';
import { useGetWorkingData } from './hooks/useGetWorkingData';
import { WorkingHoursProps } from './types';

import {
  ChevronIcon,
  ClosesValue,
  ScheduleButton,
  StatusValue,
  WorkingDataContainer,
  WorkingInfoContainer,
  WorkingInfoIconWrapper,
  WorkScheduleContainer,
} from './styled';

export default function WorkingHours({ icon }: WorkingHoursProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const list = useRef<HTMLUListElement>(null);

  const lang = useSelector(selectLang);
  const { closes, day, isAvailable, statusValue, workSchedule } =
    useGetWorkingData();
  const dir = getDir();

  const { staticTranslate } = useTranslate();

  function toggleVisibility(): void {
    setIsOpen((s) => !s);
  }

  useEffect(() => {
    if (!list.current) return;
    setListHeight(list.current.getBoundingClientRect().height);
  }, [list, workSchedule]);

  const closesValue = lang === 'he' ? `${day}-${closes}` : closes;

  return (
    <WorkingDataContainer aria-label="Device work schedule">
      <WorkingInfoIconWrapper>
        {icon}
        <WorkingInfoContainer>
          <StatusValue
            isActive={isAvailable}
            aria-label="Device working status"
          >
            {statusValue}
          </StatusValue>
          <ScheduleButton
            onClick={toggleVisibility}
            aria-label="Show device work schedule"
          >
            <ClosesValue>
              {staticTranslate('Closes')} {closesValue}
            </ClosesValue>
            <ChevronIcon dir={dir} isOpen={isOpen} />
          </ScheduleButton>
        </WorkingInfoContainer>
      </WorkingInfoIconWrapper>
      <WorkScheduleContainer height={listHeight} isOpen={isOpen}>
        <WorkSchedule ref={list} workSchedule={workSchedule} />
      </WorkScheduleContainer>
    </WorkingDataContainer>
  );
}
