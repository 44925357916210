import { useNavMarks } from './hooks/useNavMarks';
import { NavigationProps } from './types';

import { Mark, Nav } from './styled';

export default function Navigation({
  setFeature,
  selectedFeature,
}: NavigationProps): JSX.Element {
  const marks = useNavMarks();

  return (
    <Nav aria-label="Navigation between map, photos and feedbacks">
      {marks.map(({ featureId, name }) => {
        const isActive = featureId === selectedFeature;
        return (
          <Mark
            id={featureId}
            aria-label="Toggle button"
            key={name}
            isActive={isActive}
            onClick={() => setFeature(featureId)}
          >
            {name}
          </Mark>
        );
      })}
    </Nav>
  );
}
