import unmomento from '../../../../../../assets/images/laundry/unmomento.png';
import { projectIs } from '../../../../../../constants';
import { useCustomNavigate } from '../../../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../../../store';
import { setLaundry } from '../../../../../../store/slices/laundrySlice';

import {
  BtnWrapper,
  LaundryUnit,
  Logo,
  LogoWrapper,
  PriceLink,
  SelectLaundryBtn,
} from './styled';

export default function Laundries(): JSX.Element {
  const dispatch = useTypedDispatch();
  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();

  const setLaundryHandle = (laundryName: string) => () => {
    dispatch(setLaundry(laundryName));
    navigateWithUid('/laundry/personal-data');
  };

  const priceLink = projectIs.omae ? '' : 'https://ua.unmomento.com.ua/tseny/';

  return (
    <LaundryUnit aria-label="Select Unmomento laundry">
      <SelectLaundryBtn
        // This is just for OMAE Demo
        onClick={setLaundryHandle(projectIs.omae ? 'Demo' : 'UNMOMENTO')}
      />
      <LogoWrapper>
        <Logo src={unmomento} />
      </LogoWrapper>
      <BtnWrapper>
        <PriceLink aria-label="Prices" target="_blank" href={priceLink}>
          {staticTranslate('price')}
        </PriceLink>
      </BtnWrapper>
    </LaundryUnit>
  );
}
