import Button from '../../../../components/Button';
import Postamat from '../../../../components/Postomat';
import { useTranslate } from '../../../../hooks/useTranslate';

import { TextInfoBig, Title } from '../../styled';

export default function OpenCellViewer(): JSX.Element {
  const { staticTranslate } = useTranslate();
  return (
    <>
      <Title>{staticTranslate('placing')}</Title>
      <Postamat />
      <TextInfoBig>{staticTranslate('putClothesIntoOpenedCell')}</TextInfoBig>
      <Button variant="contained" to="/laundry/whats-next">
        {staticTranslate('whatNext')}
      </Button>
    </>
  );
}
