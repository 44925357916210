import { useSelector } from 'react-redux';

import {
  selectDeviceInfo,
  selectOrderInfo,
  selectResponseStatus,
} from '../store/slices/initialSlice';
import { DeviceCommonInfo, OrderInfo } from '../store/types/orderResponse';

interface ResponseInfo {
  isDeviceInfoAvailable: boolean;
  isOrderInfoAvailable: boolean;
  isLoading: boolean;
}

export function useResponseInfo(): ResponseInfo {
  const deviceInfo = useSelector(selectDeviceInfo) as DeviceCommonInfo | null;
  const orderInfo = useSelector(selectOrderInfo) as OrderInfo | null;
  const responseStatus = useSelector(selectResponseStatus);

  return {
    isDeviceInfoAvailable: !!deviceInfo,
    isOrderInfoAvailable: !!orderInfo,
    isLoading: responseStatus === 'loading',
  };
}
