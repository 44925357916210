import { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import Loader from '../../components/Loader';
import OfferCheckbox from '../../components/OfferCheckbox';
import { projectIs } from '../../constants';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useCheckFlow } from '../../hooks/useCheckFlow';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import {
  openDeviceCell,
  selectOpeningStatus,
} from '../../store/slices/configSlice';
import { selectUid, setIsReceivedOrder } from '../../store/slices/initialSlice';
import { selectScannedData } from '../../store/slices/qrSlice';

import { A, InfoCDK, QuestionIcon, QuestionTitle } from './styled';

export default function ToOpenCell(): JSX.Element {
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useTypedDispatch();

  const { flow } = useCheckFlow();
  const scannedData = useSelector(selectScannedData);
  const orderUid = useSelector(selectUid);
  const openingStatus = useSelector(selectOpeningStatus);

  const { staticTranslate } = useTranslate();
  const { navigateToErrorPage, navigateWithUid } = useCustomNavigate();

  const openCellHandler = async () => {
    if (scannedData) {
      dispatch(
        openDeviceCell({
          flow,
          orderUid,
          deviceUid: scannedData,
        }),
      )
        .unwrap()
        .then(() => dispatch(setIsReceivedOrder()))
        .then(() => navigateWithUid('/open-cell'))
        .catch(({ message: errorType }) => navigateToErrorPage(errorType));
    }
  };

  const isActive = projectIs.omkaz ? isChecked : true;
  const isLoading = openingStatus === 'loading';

  useHeadTitle(staticTranslate('CellOpened'));
  usePageHeaderContext(staticTranslate('CellOpened'));

  function acceptOffer(): void {
    setIsChecked((s) => !s);
  }

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <QuestionTitle>{staticTranslate('OpenAsk')}</QuestionTitle>
      <QuestionIcon role="presentation" aria-label="Question">
        ?
      </QuestionIcon>
      {projectIs.omkaz && <OfferCheckbox onChange={acceptOffer} />}
      {projectIs.mncdk && (
        <InfoCDK>
          {staticTranslate('PublicOffer3')}{' '}
          <A target="_blank" href="https://www.cdek.ru" rel="noreferrer">
            www.cdek.ru
          </A>
        </InfoCDK>
      )}
      <ButtonsPaper>
        <Button isActive={isActive} variant="clear" onClick={openCellHandler}>
          {staticTranslate('CellOpen')}
        </Button>
        <Button variant="bordered" to="/">
          {staticTranslate('ToMain')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
