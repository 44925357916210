import step2img from '../../../../assets/images/return/step2.png';
import { useTranslate } from '../../../../hooks/useTranslate';

import {
  ContentWrapper,
  Heading,
  Image,
  ListItem,
  OrderedList,
} from '../../styled';

export default function Packaging(): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <>
      <Image src={step2img} />
      <ContentWrapper>
        <Heading>{staticTranslate('TheShipmentMustBe')}</Heading>
        <OrderedList>
          <ListItem>{staticTranslate('PackTheProductInABoxOrBag')}</ListItem>
          <ListItem>{staticTranslate('TapeThePackagingSo')}</ListItem>
        </OrderedList>
      </ContentWrapper>
    </>
  );
}
