import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { request } from '../../api';
import { InitialState, Order } from '../types/ordersListTypes';
import { RootReducer } from '../types/store';

const TIME_TO_RESEND = 31000;

export const getOrdersList = createAsyncThunk<Order[]>(
  '@@receive/getOrdersList',
  async (_, { getState }) => {
    const {
      receiveReducer: { phone, code },
      initialReducer: { savedUid: deviceUid },
    } = getState() as RootReducer;

    const { data } = await request<Order[]>({
      method: 'POST',
      path: '/c2c/user/confirmation/code/send',
      data: {
        code,
        phone,
        device_uid: deviceUid,
      },
    });

    return data;
  },
);

export const getCode = createAsyncThunk(
  '@@receive/getCode',
  async (_, { getState }) => {
    const {
      receiveReducer: { phone },
      initialReducer: { savedUid: deviceUid },
    } = getState() as RootReducer;
    await request({
      path: `/c2c/user/confirmation/code/send?phone=${phone}&device_uid=${deviceUid}`,
    });
  },
);

const initialState: InitialState = {
  listStatus: 'idle',
  codeStatus: 'idle',
  code: '',
  phone: '',
  countdownValue: '',
  countdownTime: 0,
  dateTo: 0,
  ordersList: [],
};

const receiveSlice = createSlice({
  name: '@@receive',
  initialState,
  reducers: {
    cleanOrdersList: (state) => {
      state.ordersList = [];
      state.listStatus = 'idle';
      state.codeStatus = 'idle';
    },
    setPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    setCode: (state, { payload }: PayloadAction<string>) => {
      state.code = payload;
    },
    setCountdown: (state, { payload }: PayloadAction<string>) => {
      state.countdownValue = payload;
    },
    setCountdownTimer: (state, { payload }: PayloadAction<number>) => {
      state.countdownTime = payload;
    },
    cleanData: (state) => {
      // reset code status:
      state.codeStatus = 'idle';
      // clean entered data:
      state.phone = '';
      state.code = '';
    },
    stopCountdown: (state) => {
      state.dateTo = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersList.pending, (state) => {
        state.listStatus = 'loading';
      })
      .addCase(getOrdersList.rejected, (state) => {
        state.listStatus = 'rejected';
      })
      .addCase(getOrdersList.fulfilled, (state, action) => {
        state.listStatus = 'received';
        state.ordersList = action.payload;
      })
      .addCase(getCode.pending, (state) => {
        state.codeStatus = 'loading';
      })
      .addCase(getCode.rejected, (state) => {
        state.codeStatus = 'rejected';
      })
      .addCase(getCode.fulfilled, (state) => {
        state.codeStatus = 'received';
        state.dateTo = Date.now() + TIME_TO_RESEND;
      });
  },
});

export const receiveReducer = receiveSlice.reducer;
export const {
  cleanOrdersList,
  setCode,
  setPhone,
  setCountdown,
  setCountdownTimer,
  cleanData,
  stopCountdown,
} = receiveSlice.actions;

export const selectOrdersList = ({
  receiveReducer: { ordersList },
}: RootReducer) => ordersList;

export const selectOrdersListStatus = ({
  receiveReducer: { listStatus },
}: RootReducer) => listStatus;

export const selectCodeStatus = ({
  receiveReducer: { codeStatus },
}: RootReducer) => codeStatus;

export const selectCode = ({ receiveReducer: { code } }: RootReducer) => code;

export const selectPhone = ({ receiveReducer: { phone } }: RootReducer) =>
  phone;

export const selectCountdownValue = ({
  receiveReducer: { countdownValue },
}: RootReducer) => countdownValue;

export const selectCountdownTimer = ({
  receiveReducer: { countdownTime },
}: RootReducer) => countdownTime;

export const selectDateTo = ({ receiveReducer: { dateTo } }: RootReducer) =>
  dateTo;
