import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { TRANSLATE_API, TRANSLATE_X_API_KEY } from '../constants';

interface TranslateResponse {
  result: string;
}

interface TranslationData {
  text: string;
  lang: string;
}

export const getOmnicTranslation = async ({
  text = '',
  lang,
}: TranslationData) => {
  if (!TRANSLATE_X_API_KEY) {
    return text;
  }
  try {
    const { data } = await axios.post<
      AxiosRequestConfig<TranslationData>,
      AxiosResponse<TranslateResponse>
    >(
      `${TRANSLATE_API}/api/translate`,
      {
        text,
        lang,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TRANSLATE_X_API_KEY,
        },
      },
    );

    if ('result' in data) {
      return data.result;
    }

    throw new Error('No translation');
  } catch (err: AnyType) {
    // eslint-disable-next-line no-console
    console.error(err);
    return text;
  }
};
