import styled from 'styled-components';

import putClothes from '../../../../assets/images/laundry/putClothes.jpeg';
import tightenSeal from '../../../../assets/images/laundry/tightenSeal.jpeg';
import Link from '../../../../components/Link';

export const TopWrapper = styled.div`
  width: 100%;
`;

export const RulesOl = styled.ol`
  margin: 0 ${({ theme }): string => theme.spacing(5)};
`;

export const RulesLi = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};

  padding-left: ${({ theme }): string => theme.spacing(4)};
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
  list-style-type: none;

  counter-increment: num;

  &::before {
    content: counter(num);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.4em;
    height: 1.4em;
    border-radius: 50%;
    background-color: ${({ theme }): string =>
      theme.palette.background.primary};
    color: ${({ theme }): string => theme.palette.text.secondary};
  }
`;

export const Text = styled.p`
  margin-bottom: ${({ theme }): string => theme.spacing()};
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding-top: 65%;
  border-radius: 14px;
  box-shadow: inset -2px -2px 3px 4px
    ${({ theme }): string => theme.palette.background.secondary};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const PutClothesImg = styled(ImageContainer)`
  background-image: url(${putClothes});
`;

export const TightenSealImg = styled(ImageContainer)`
  background-image: url(${tightenSeal});
`;

export const CheckboxLabel = styled.span``;

export const CustomLink = styled(Link)`
  display: inline;
  color: ${({ theme }): string => theme.palette.text.asTheme};
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(3)};
  width: 100%;
`;
