/* eslint-disable no-console */
import { Extends } from './projects';
import { Lang } from './store/types/langTypes';

import './projects/OMAR/constants';
import './projects/OMIS/constants';
import './projects/OMKAZ/constants';
import './projects/MNCDK/constants';
import './projects/OMUA/constants';

export const isProd = process.env.REACT_APP_PRODUCTION === 'true';

export const PROJECT = process.env.REACT_APP_PROJECT;

export const LANG_LIST: Lang[] = (process.env.REACT_APP_LANG_LIST?.split(
  ',',
) as Lang[]) || ['en'];

export const API_DATA = {
  CUBE_CORE: {
    url: process.env.REACT_APP_API_DATA_CUBE_CORE_URL,
  },
};

export const MAP_TYPE = process.env.REACT_APP_MAP_TYPE || 'MAP_TYPE_GOOGLE';

export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

export const YANDEX_MAP_API_KEY =
  process.env.REACT_APP_YANDEX_MAP_API_KEY || '';

export const TRANSLATE_API = process.env.REACT_APP_TRANSLATE_API || '';

export const TRANSLATE_X_API_KEY =
  process.env.REACT_APP_TRANSLATE_X_API_KEY || '';

export const METRICS_TYPE = process.env.REACT_APP_METRICS_TYPE;

export const METRICS_YANDEX_ID = process.env.REACT_APP_METRICS_YANDEX_ID || '';

console.log(METRICS_TYPE, METRICS_YANDEX_ID);

export const ExtendSetting =
  Extends[PROJECT as string]?.[isProd.toString()] || {};

if (Extends[PROJECT as string]) {
  console.log(Extends[PROJECT as string]);
} else {
  console.warn(`project ${PROJECT} has not extends`);
}

export const projectIs = {
  omis: PROJECT === 'OMIS',
  omua: PROJECT === 'OMUA',
  omkaz: PROJECT === 'OMKAZ',
  omar: PROJECT === 'OMAR',
  mncdk: PROJECT === 'MNCDK',
  omae: PROJECT === 'OMAE',
  omge: PROJECT === 'OMGE',
  omit: PROJECT === 'OMIT',
  omth: PROJECT === 'OMTH',
};
