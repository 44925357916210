import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useNotifications } from '../../../../../context/NotificationsContext';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../../store';
import {
  getCells,
  selectCells,
  selectCellsStatus,
} from '../../../../../store/slices/cellsSlice';
import { Cell } from '../../../../../store/types/cellsResponse';
import { expandsForCells } from '../constants';
import { ExpandedCell } from '../types';

export function useCells() {
  const [cellsForRender, setCellsForRender] = useState<ExpandedCell[]>([]);
  const dispatch = useTypedDispatch();
  const cells = useSelector(selectCells);
  const cellsStatus = useSelector(selectCellsStatus);
  const { setNotification } = useNotifications();
  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();

  function sortCells(cellsForSort: Cell[]) {
    return cellsForSort.sort((a, b) => {
      const {
        params: { height: AHeight, width: AWidth },
      } = a;
      const {
        params: { height: BHeight, width: BWidth },
      } = b;
      const ASquare = AHeight * AWidth;
      const BSquare = BHeight * BWidth;
      if (ASquare > BSquare) return 1;
      if (ASquare < BSquare) return -1;
      return 0;
    });
  }

  function expandSortedCellsValue(sortedCells: Cell[]): void {
    const expandedCells = sortedCells.map((cellValue, idx) => {
      return { ...cellValue, ...expandsForCells[idx] };
    });
    setCellsForRender(expandedCells);
  }

  useEffect(() => {
    if (cellsStatus === 'idle') {
      dispatch(getCells())
        .unwrap()
        .catch(() => {
          setNotification({
            status: 'error',
            value: staticTranslate('SomethingWentWrong'),
          });
          navigateWithUid('/');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellsStatus, dispatch]);

  useEffect(() => {
    if (cellsStatus === 'received' && !cellsForRender.length) {
      const sortedCells = sortCells([...cells]);
      expandSortedCellsValue(sortedCells);
    }
  }, [cellsStatus, cells, cellsForRender]);

  return cellsForRender;
}
