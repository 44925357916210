import { Extend, Extends, isDev, isProd } from '../index';

const Ext: Extend = {};

Ext[isProd] = {};
Ext[isDev] = {};

Extends.OMIS = Ext;

export {};
