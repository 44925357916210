import styled from 'styled-components';

export const LaundryUnit = styled.div`
  position: relative;
  width: 100%;
  height: 100px;

  border: 1px solid ${({ theme }): string => theme.palette.border.primary};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectLaundryBtn = styled.button`
  right: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Logo = styled.img`
  pointer-events: none;
`;

export const BtnWrapper = styled.div`
  height: 100%;
  display: flex;
  padding-top: ${({ theme }): string => theme.spacing()};
  padding-right: ${({ theme }): string => theme.spacing()};
`;

export const PriceLink = styled.a`
  height: fit-content;
  border: 1px solid ${({ theme }): string => theme.palette.border.shadow};
  outline: none;

  background-color: transparent;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};

  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-transform: uppercase;

  padding: ${({ theme: { spacing } }): string => `${spacing()} ${spacing(3)}`};
  z-index: 1;
`;
