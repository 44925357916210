import styled from 'styled-components';

import terminalX from '../../../../assets/images/return/terminalX.svg';

export const ShopName = styled.div`
  position: absolute;
  z-index: 1;
  top: calc(50% - 25.5px);
  width: 100%;
  height: 45px;
  background-color: #000000;
  background-image: url(${terminalX});
  background-repeat: repeat-x;
  background-position: center;
`;

export const ShopsContainer = styled.div`
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const Shop = styled.button`
  width: 100%;
  padding-top: 66%;
  position: relative;
  border: none;
  outline: none;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};
  overflow: hidden;
`;

export const ShopImage = styled.img`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
