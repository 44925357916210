export interface Extend {
  [prod: string]: AnyType;
}

export const Extends: {
  [project: string]: Extend;
} = {};

export const isProd = true.toString();
export const isDev = false.toString();

export interface Payment {
  Type: AnyType;
  Data: AnyType;
}
