import BasePhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

import 'react-phone-input-2/lib/style.css';

export const StyledPhoneInput = styled(BasePhoneInput)`
  display: flex !important;
  gap: ${({ theme }): string => theme.spacing()} !important;
  direction: ltr;
  font-size: inherit;

  .form-control {
    height: inherit !important;
    flex: 1 !important;
    background-color: transparent !important;
    outline: none !important;
    padding: ${({ theme }): string => theme.spacing()} !important;
    font-family: ${({ theme }): string =>
      theme.typography.fontFamily.font3} !important;
    font-weight: ${({ theme }): string =>
      theme.typography.fontWeight[400]} !important;
    font-size: ${({ theme }): string =>
      theme.typography.fontSize.xxl} !important;
    line-height: 0;

    color: ${({ theme }): string => theme.palette.text.primary} !important;
    border-radius: ${({ theme }): string =>
      theme.shape.borderRadius.m} !important;

    border: 1px solid ${({ theme }): string => theme.palette.border.primary} !important;

    &:focus {
      box-shadow: 0 0 4px ${({ theme }): string => theme.palette.border.primary} !important;
    }

    &::placeholder {
      color: ${({ theme }): string => theme.palette.text.primary};
      opacity: 0.4;
    }
  }

  .flag-dropdown {
    order: -1;
    background-color: transparent !important;
    position: relative !important;
    min-width: 40px !important;
    border: none !important;
    border-radius: ${({ theme }): string =>
      theme.shape.borderRadius.m} !important;
    border: 1px solid ${({ theme }): string => theme.palette.border.primary} !important;
  }

  .arrow {
    border-top-color: ${({ theme }): string =>
      theme.palette.text.primary} !important;

    &.up {
      border-bottom-color: ${({ theme }): string =>
        theme.palette.text.primary} !important;
    }
  }

  .selected-flag {
    background-color: transparent !important;
  }

  .country-list {
    bottom: -10px;
    max-height: 150px !important;
  }
`;
