import { useLocation, useMatch, useParams } from 'react-router-dom';

export function usePageCheck() {
  const { pathname } = useLocation();
  const { uid } = useParams();

  const isMainDefault = useMatch(`${uid}/`);
  const isMainDevice = useMatch(`device/${uid}/`);

  return {
    isMainPage: isMainDefault || isMainDevice,
    isInfoPage: pathname.includes('device-info'),
    isQrScanPage: pathname.includes('qr-scan'),
    isToOpenPage: pathname.includes('/to-open-cell'),
    isOpenCellPage: pathname.includes('/open-cell'),
    isExtensionPage: pathname.includes('order-extension'),
    isPaymentPage: pathname.includes('order-payment'),
    isErrorPage: pathname.includes('error'),
    isOffer: pathname.includes('offer'),
    isExtendSuccessPage: pathname.includes('success'),
    isLikeRatePage: pathname.includes('rate/like'),
    isA11yPage: pathname.includes('a11y-features'),
    isA11yStatement: pathname.includes('a11y-statement'),
    isGetOrderPage: pathname.includes('get-order'),
    isOrdersListPage: pathname.includes('orders-list'),
    isWhatsNextPage: pathname.includes('whats-next'),
    isReturnPages: pathname.includes('return'),
  };
}
