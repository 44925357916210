import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { request } from '../../api';
import { Feedback } from '../types/feedbackResponse';
import { FeedbacksInitialState } from '../types/slicesTypes';
import { RootReducer } from '../types/store';

export const getFeedback = createAsyncThunk<Feedback[], string>(
  '@@feedback/getFeedbacks',
  async (deviceId) => {
    const { data } = await request<Feedback[]>({
      path: `/feedback/?device_id=${deviceId}`,
    });

    return data.filter(({ device, type }) => device === deviceId && !type);
  },
);

const initialState: FeedbacksInitialState = {
  isFeedbacksRequestAvailable: true,
  wasLeftFeedback: false,
  status: 'idle',
  feedbacks: [] as Feedback[],
};

const feedbacksSlice = createSlice({
  name: '@@feedbacks',
  initialState,
  reducers: {
    setIsFeedbacksRequestAvailable: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isFeedbacksRequestAvailable = payload;
    },
    setWasLeftFeedback: (state) => {
      state.wasLeftFeedback = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedback.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFeedback.rejected, (state) => {
        state.status = 'rejected';
        // eslint-disable-next-line no-console
        console.error('FEEDBACKS_LOADING_ERROR');
      })
      .addCase(getFeedback.fulfilled, (state, action) => {
        state.status = 'received';
        state.feedbacks = action.payload as Feedback[];
      });
  },
});

export const { setIsFeedbacksRequestAvailable, setWasLeftFeedback } =
  feedbacksSlice.actions;

export const feedbacksReducer = feedbacksSlice.reducer;

export const selectLoadingAvailability = ({
  feedbacksReducer: { isFeedbacksRequestAvailable },
}: RootReducer) => isFeedbacksRequestAvailable;

export const selectWasLeftFeedback = ({
  feedbacksReducer: { wasLeftFeedback },
}: RootReducer) => wasLeftFeedback;

export const selectFeedbacks = ({
  feedbacksReducer: { feedbacks },
}: RootReducer) => feedbacks;

export const selectFeedbacksStatus = ({
  feedbacksReducer: { status },
}: RootReducer) => status;
