import { useSelector } from 'react-redux';

import { useTranslate } from '../../../hooks/useTranslate';
import { selectCode, selectPhone } from '../../../store/slices/receiveSlice';
import { ButtonsData, ButtonsSettingsProps, Step } from '../types';

export function useButtonsSettings({
  step,
  countdownValue,
}: ButtonsSettingsProps): ButtonsData {
  const { staticTranslate } = useTranslate();
  const phone = useSelector(selectPhone);
  const code = useSelector(selectCode);
  return {
    isActiveGetCode: !!phone && !countdownValue,
    isActiveGetOrders: !!code,
    isShowGetOrders: step === Step.code,
    getCodeValue: countdownValue || staticTranslate('SendTheCode'),
    backBtnVariant: step === Step.phone ? 'outlined' : 'contained',
    backBtnText:
      step === Step.phone
        ? staticTranslate('ToMain')
        : staticTranslate('ChangeNumber'),
  };
}
