import { useSelector } from 'react-redux';

import { projectIs } from '../../../constants';
import { usePageHeaderContext } from '../../../context/PageHeaderContext/PageHeaderContext';
import { usePageCheck } from '../../../hooks/usePageCheck';
import {
  selectIsOrderReceived,
  selectSavedError,
} from '../../../store/slices/initialSlice';
import { NavBG, NavSettings } from '../types';

export function useNavSettings(): NavSettings {
  const {
    isMainPage,
    isExtendSuccessPage,
    isErrorPage,
    isLikeRatePage,
    isToOpenPage,
    isOpenCellPage,
    isQrScanPage,
    isA11yPage,
    isReturnPages,
  } = usePageCheck();

  const isOrderReceived = useSelector(selectIsOrderReceived);
  const savedError = useSelector(selectSavedError);

  const pageHeader = usePageHeaderContext();

  const isShowButton =
    !isErrorPage &&
    !isLikeRatePage &&
    !isExtendSuccessPage &&
    !isOpenCellPage &&
    !isMainPage &&
    !isOrderReceived;

  return {
    isShowInfoButton: !!isMainPage && projectIs.omkaz,
    isSecondaryTitleStyle: isToOpenPage || isExtendSuccessPage,
    isSecondaryIconsStyle:
      isExtendSuccessPage || isToOpenPage || isLikeRatePage,
    isShowBackButton: (!isReturnPages && isShowButton) || isA11yPage,
    isShowHomeButton: isShowButton && !savedError,
    pageHeader,
    getNavBGType: (): NavBG => {
      if (isQrScanPage) {
        return NavBG.opacity;
      }
      if (isToOpenPage || isExtendSuccessPage || isLikeRatePage) {
        return NavBG.fullColor;
      }
      return NavBG.clear;
    },
  };
}
