import styled from 'styled-components';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const Cell = styled.div`
  ${stackCellCommon};

  &:nth-child(1) {
    flex: 3;
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8) {
    flex: 1;
  }

  &:nth-child(9),
  &:nth-child(10) {
    flex: 2;
  }
`;
