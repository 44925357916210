import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ym from 'react-yandex-metrika';

import Postamat from '../../components/Postomat';
import { METRICS_TYPE } from '../../constants';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { RateType } from '../../helpers/enums';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useSendFeedback } from '../../hooks/useSendFeedback';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import { setWasLeftFeedback } from '../../store/slices/feedbackSlice';
import {
  selectIsOrderReceived,
  selectUid,
} from '../../store/slices/initialSlice';
import { FeedbackType } from '../types/feedback';

import {
  ButtonsPaper,
  ButtonWrapper,
  CustomButton as Button,
  DislikeFace,
  ExpText,
  LikeFace,
  Main,
  RateButtonsWrapper,
  RateExpContainer,
  Title,
} from './styled';

export default function OpenCell(): JSX.Element {
  const orderUid = useSelector(selectUid);
  const isOrderReceived = useSelector(selectIsOrderReceived);
  const dispatch = useTypedDispatch();

  const sendFeedback = useSendFeedback();

  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();

  const goToLikeRate = async () => {
    await sendFeedback({
      type: FeedbackType.orderLike,
      comment: '',
      rate: 0,
    }).then(() => dispatch(setWasLeftFeedback()));
  };

  const goToDislikeRate = () => navigateWithUid(`/rate/${RateType.dislike}`);

  useEffect(() => {
    if (METRICS_TYPE && METRICS_TYPE === 'YANDEX') {
      ym('reachGoal', 'receiveOrder', { order: orderUid });
    }
  }, [orderUid]);

  useHeadTitle(staticTranslate('LayoutOpenCell'));
  usePageHeaderContext(staticTranslate('CellOpened'));

  return (
    <Main>
      <Title>{staticTranslate('CellOpenedTitle')}</Title>
      <Postamat />
      <ButtonsPaper>
        <RateExpContainer>
          <ExpText>{staticTranslate('FeedbackText')}</ExpText>
          <RateButtonsWrapper>
            <ButtonWrapper
              type="button"
              aria-label="Tap this if you like our service"
              onClick={goToLikeRate}
            >
              <LikeFace />
            </ButtonWrapper>
            <ButtonWrapper
              type="button"
              aria-label="Tap this if you dislike our service"
              onClick={goToDislikeRate}
            >
              <DislikeFace />
            </ButtonWrapper>
          </RateButtonsWrapper>
        </RateExpContainer>
        {!isOrderReceived && (
          <Button variant="outlined" to="/">
            {staticTranslate('ToMain')}
          </Button>
        )}
      </ButtonsPaper>
    </Main>
  );
}
