import styled, { css } from 'styled-components';

import {
  ActionButtonProps,
  InnerElementsProps,
  InputWrapperProps,
} from './types';

function applyUlDirection({ isOpenListUp }: InputWrapperProps) {
  return isOpenListUp
    ? css`
        bottom: 100%;
      `
    : css`
        top: 100%;
      `;
}

function applyActionButtonStyles({ isActive }: ActionButtonProps) {
  return isActive
    ? css`
        svg {
          path {
            stroke: ${({ theme }): string => theme.palette.text.asTheme};
          }
        }
      `
    : css`
        opacity: 0.5;
        pointer-events: none;
        svg {
          path {
            stroke: ${({ theme }): string => theme.palette.border.shadowStrong};
          }
        }
      `;
}

function applyInnerElementsStyle({ isActive }: InnerElementsProps) {
  return isActive
    ? css`
        border-color: ${({ theme }): string => theme.palette.border.primary};
        box-shadow: 0 0 4px
          ${({ theme }): string => theme.palette.border.primary};
      `
    : css`
        border-color: ${({ theme }): string => theme.palette.border.shadow};
      `;
}

export const Clothe = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }): string => theme.spacing()};
`;

export const InnerElements = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  border-style: solid;
  border-width: 1px;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${applyInnerElementsStyle};
`;

export const Search = styled.input`
  width: 100%;
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(1.75)} ${spacing(1.5)}`};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  background-color: transparent;
  outline: none;
  border: none;

  &::placeholder {
    color: ${({ theme }): string => theme.palette.text.primary};
    opacity: 0.4;
  }
`;

export const ItemActionButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 ${({ theme }): string => theme.spacing()};
  svg {
    transform: rotateZ(45deg);
  }
  ${applyActionButtonStyles};
`;

export const InputWrapper = styled.div`
  position: relative;
  flex: 1;

  > ul {
    ${applyUlDirection};
  }
`;
