import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDeviceInfo } from '../../../../../store/slices/initialSlice';
import { Location } from '../types';

export function useLocations() {
  const [deviceLocation, setDeviceLocation] = useState<Location>();
  const [userLocation, setUserLocation] = useState<Location>();
  const deviceInfo = useSelector(selectDeviceInfo);

  useEffect(() => {
    setDeviceLocation({
      lat: +deviceInfo.latitude || 0,
      lng: +deviceInfo.longitude || 0,
    });
  }, [deviceInfo]);

  useEffect(() => {
    const geoPosition = navigator?.geolocation;
    if (!geoPosition) return;

    const watchId = geoPosition.watchPosition(
      ({ coords }) => {
        setUserLocation({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
      },
    );

    return () => geoPosition.clearWatch(watchId);
  }, []);

  return {
    deviceLocation,
    userLocation,
  };
}
