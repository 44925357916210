import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import { selectCode, setCode } from '../../../../store/slices/receiveSlice';
import { CodeContainerProps } from './types';

import { Text } from '../../styled';
import { CodeInput, CodeInputWrapper, ResendInfo } from './styled';

export default function CodeContainer({
  phone,
  countdownValue,
}: CodeContainerProps): JSX.Element {
  const code = useSelector(selectCode);
  const dispatch = useTypedDispatch();

  const { staticTranslate } = useTranslate();

  function onChangeHandler({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void {
    dispatch(setCode(value));
  }

  return (
    <>
      <Text>
        {staticTranslate('EnterTheCodeYou')}: <strong>{phone}</strong>
      </Text>
      <CodeInputWrapper>
        <CodeInput
          aria-label="Input for code from SMS"
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          value={code}
          onChange={onChangeHandler}
        />
        {!!countdownValue && (
          <ResendInfo>
            {staticTranslate('WeCanSendSMS')} {countdownValue}
          </ResendInfo>
        )}
      </CodeInputWrapper>
    </>
  );
}
