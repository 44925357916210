import styled from 'styled-components';

import Link from '../Link';

export const Form = styled.form`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  line-height: 1.3;
  width: 235px;
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.secondary};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const Fieldset = styled.fieldset`
  border: none;
`;

export const Label = styled.label`
  display: flex;
  gap: ${({ theme }): string => theme.spacing(1.5)};
  align-items: center;
`;

export const CustomLink = styled(Link)`
  display: inline;
  color: ${({ theme }): string => theme.palette.text.secondary};
  text-decoration: underline;
`;

export const Span = styled.span`
  width: 190px;
  text-align: center;
`;

export const Input = styled.input``;
