import styled from 'styled-components';

import Link from '../../../components/Link';
import { applyFontSize, applyLinksStyle } from '../../../theme/global';

export const ExampleAreaContainer = styled.div`
  height: 150px;
  overflow-y: auto;
  font-size: ${applyFontSize};
  background-color: ${({ theme }): string =>
    theme.palette.background.secondary};
  padding: ${({ theme }): string => theme.spacing()};

  ${applyLinksStyle};

  p {
    margin-bottom: ${({ theme }): string => theme.spacing()};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ColorText = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font1};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  color: ${({ theme }): string => theme.palette.text.asTheme};
`;

export const CommonLink = styled(Link)`
  display: block;
  pointer-events: none;
  margin-bottom: ${({ theme }): string => theme.spacing()};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font2};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const SuccessText = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  color: ${({ theme }): string => theme.palette.success};
`;

export const ErrorText = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font1};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
  color: ${({ theme }): string => theme.palette.error};
`;
