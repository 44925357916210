import { Extend, Extends, isDev, isProd } from '../index';

const Ext: Extend = {};

Ext[isProd] = {
  Payment: [
    {
      Type: 'HALYK_PAY',
      Data: {
        ScriptURL: 'https://epay.homebank.kz/payform/payment-api.js',
        AccountID: 'test',
        Terminal: '67e34d63-102f-4bd1-898e-370781d0074d',
      },
    },
  ],
};
Ext[isDev] = {
  Payment: [
    {
      Type: 'HALYK_PAY',
      Data: {
        ScriptURL: 'https://test-epay.homebank.kz/payform/payment-api.js',
        AccountID: 'test',
        Terminal: '67e34d63-102f-4bd1-898e-370781d0074d',
      },
    },
  ],
};

Extends.OMKAZ = Ext;

export {};
