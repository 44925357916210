import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { ContextValue, PageHeaderContextProps } from './types';

const Context = createContext<ContextValue | null>(null);

export default function PageHeaderContext({
  children,
}: PageHeaderContextProps): JSX.Element {
  const [pageHeader, setPageHeader] = useState('');

  const value: ContextValue = useMemo(
    () => ({
      pageHeader,
      setPageHeader,
    }),
    [pageHeader],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function usePageHeaderContext(header?: string): string {
  const value = useContext(Context);

  if (!value)
    throw new Error(
      'Please use hook usePageHeaderContext inside PageHeaderContext provider',
    );

  const { pageHeader, setPageHeader } = value;

  useEffect(() => {
    if (header) setPageHeader(header);
  }, [header, setPageHeader]);

  return pageHeader;
}
