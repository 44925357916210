import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../components/Button';
import ButtonsPaper from '../../../../components/ButtonsPaper';
import TextArea from '../../../../components/TextArea';
import { useHeadTitle } from '../../../../hooks/useHeadTitle';
import { useSendFeedback } from '../../../../hooks/useSendFeedback';
import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import { setWasLeftFeedback } from '../../../../store/slices/feedbackSlice';
import { FeedbackType } from '../../../types/feedback';

import { DetailTitle, TextAreaWrapper } from './styled';

export default function DetailRate(): JSX.Element {
  const [textAreaText, setTextAreaText] = useState('');
  const dispatch = useTypedDispatch();
  const { staticTranslate } = useTranslate();

  const sendFeedback = useSendFeedback();
  const navigate = useNavigate();

  const toPrevPage = () => navigate(-1);

  const sendFeedbackHandler = async () => {
    setTextAreaText('');
    await sendFeedback({
      type: FeedbackType.orderDislike,
      rate: 0,
      comment: textAreaText,
    }).then(() => dispatch(setWasLeftFeedback()));
  };

  useHeadTitle(staticTranslate('LayoutDetailFeedback'));

  return (
    <>
      <TextAreaWrapper>
        <DetailTitle>{staticTranslate('FeedbackLike4')}</DetailTitle>
        <TextArea
          placeholder={staticTranslate('FeedbackLike5')}
          setTextAreaText={setTextAreaText}
          textAreaText={textAreaText}
        />
      </TextAreaWrapper>
      <ButtonsPaper>
        <Button variant="outlined" onClick={toPrevPage}>
          {staticTranslate('Back')}
        </Button>
        <Button
          isActive={textAreaText.length > 1}
          variant="contained"
          onClick={sendFeedbackHandler}
        >
          {staticTranslate('Send')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
