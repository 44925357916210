import axios from 'axios';

interface UidResponse {
  result: string;
}

export async function getUid(link: string): Promise<string> {
  const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_TRANSLATE_API}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  try {
    const { data } = await apiClient.get<UidResponse>(`check-uuid?qr=${link}`);

    if ('result' in data) {
      return data.result;
    }

    throw new Error('No result from back-end');
  } catch (err: AnyType) {
    // eslint-disable-next-line no-console
    console.error(err.message || err);

    if (err.response?.status === 404) {
      throw new Error('IncorrectQR-code');
    }
    throw new Error('PleaseReloadThePage');
  }
}
