import { CloseButtonsProps } from './types';

import { CloseContainer, CloseMark } from './styled';

export default function CloseButton({
  onClick: closePhoto,
}: CloseButtonsProps) {
  return (
    <CloseContainer aria-label="Close fullscreen photo" onClick={closePhoto}>
      <CloseMark role="img" />
    </CloseContainer>
  );
}
