import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { request } from '../../api';
import { CreateOrderResponse } from '../../api/types';
import { Params } from '../types/cellsResponse';
import { InitialState } from '../types/returnTypes';
import { RootReducer } from '../types/store';

export const createOrder = createAsyncThunk<string>(
  '@@return/createOrder',
  async (_, { getState }) => {
    const {
      returnReducer: { clientSystemId, cellParams, senderPhone, orderNumber },
      initialReducer: { savedUid: deviceUid },
    } = getState() as RootReducer;

    const { width, depth, height } = cellParams as Params;
    const { data } = await request<CreateOrderResponse>({
      method: 'POST',
      path: '/public/order/',
      data: {
        device_uid: deviceUid,
        order_type: 5,
        status: 0,
        client_system_id: clientSystemId,
        barcode: orderNumber,
        order_number: orderNumber,
        request_number: orderNumber,
        sender_phone: senderPhone,
        cell_width: width,
        cell_height: height,
        cell_depth: depth,
        client_order: true,
        is_return: true,
      },
    });

    return data.uid;
  },
);

const initialState: InitialState = {
  creatingStatus: 'idle',
  clientSystemId: '',
  cellParams: null,
  senderPhone: '',
  orderNumber: '',
};

const returnSlice = createSlice({
  name: '@@return',
  initialState,
  reducers: {
    setClient: (state, action: PayloadAction<string>) => {
      state.clientSystemId = action.payload;
    },
    setParams: (state, action: PayloadAction<Params>) => {
      state.cellParams = action.payload;
    },
    setSenderPhone: (state, action: PayloadAction<string>) => {
      state.senderPhone = action.payload;
    },
    setOrderNumber: (state, action: PayloadAction<string>) => {
      state.orderNumber = action.payload;
    },
    clearState: (state) => {
      state.cellParams = initialState.cellParams;
      state.clientSystemId = initialState.clientSystemId;
      state.creatingStatus = initialState.creatingStatus;
      state.senderPhone = initialState.senderPhone;
      state.orderNumber = initialState.orderNumber;
    },
  },
  extraReducers: (builded) => {
    builded
      .addCase(createOrder.pending, (state) => {
        state.creatingStatus = 'loading';
      })
      .addCase(createOrder.rejected, (state) => {
        state.creatingStatus = 'rejected';
      })
      .addCase(createOrder.fulfilled, (state) => {
        state.creatingStatus = 'received';
      });
  },
});

export const returnReducer = returnSlice.reducer;

export const {
  setClient,
  setOrderNumber,
  setParams,
  setSenderPhone,
  clearState,
} = returnSlice.actions;

export const selectReturnValue = ({
  returnReducer: returnValue,
}: RootReducer) => returnValue;

export const selectCreatingStatus = ({
  returnReducer: { creatingStatus },
}: RootReducer) => creatingStatus;
