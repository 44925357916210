import styled, { css } from 'styled-components';

import { CellBoxProps } from './types';

function applyCellBoxStyle({ isUnavailable }: CellBoxProps) {
  return (
    isUnavailable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `
  );
}

export const CellsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: ${({ theme }): string => theme.spacing(0.5)};
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const CellBox = styled.div`
  ${applyCellBoxStyle};
  pointer-events: all;
  display: flex;
  border: 1px solid ${({ theme }): string => theme.palette.border.shadow};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: ${({ theme }): string => theme.palette.background.order};

  &:nth-child(2n + 1) {
    width: 45%;
  }

  &:nth-child(2n) {
    flex: 1;
  }

  &:nth-child(1),
  &:nth-child(2) {
    min-height: ${({ theme }): string => theme.spacing(7.2)};
  }

  &:nth-child(3),
  &:nth-child(4) {
    min-height: ${({ theme }): string => theme.spacing(10.2)};
  }

  &:nth-child(5),
  &:nth-child(6) {
    min-height: ${({ theme }): string => theme.spacing(11.2)};
  }

  &:nth-child(7) {
    width: 100%;
    min-height: ${({ theme }): string => theme.spacing(10.2)};
  }
  &:nth-child(8) {
    width: 100%;
    min-height: ${({ theme }): string => theme.spacing(14.7)};
  }
`;

export const SVGWrapper = styled.div`
  position: relative;
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    background: ${({ theme }): string => theme.palette.primary[50]};
    mix-blend-mode: hue;
  }

  > svg {
    position: relative;
  }
`;

export const CellInfoWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CellSize = styled.h3`
  color: ${({ theme }): string => theme.palette.text.asTheme};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  margin-bottom: ${({ theme }): string => theme.spacing()};
`;

export const CellParams = styled.p`
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;
