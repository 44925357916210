export const COLORS = {
  yellow: {
    20: '#555200ff',
    40: '#fffa64ff',
    60: '#fff82fff',
    95: '#fff700dc',
    100: '#fff700ff',
  },
  green: {
    3: '#e3ffd2e6',
    10: '#38ab362a',
    30: '#cfff38',
    40: '#d0f833',
    50: '#b1d645',
    90: '#38AB36',
  },
  blue: {
    10: '#032fdd28',
    30: '#032edd',
    40: '#032edd',
    50: '#032edd',
  },
  grey: {
    0: '#ffffff',
    3: '#ffffffe3',
    4: '#f9f9f9ff',
    5: '#ededed',
    10: '#e6e6e6',
    15: '#E4E4E4',
    20: '#dadada',
    25: '#cecece',
    30: '#bababa',
    40: '#adadad',
    50: '#7c7c7c',
    70: '#5B5B5B',
    80: '#414141',
    90: '#393939',
    95: '#2c2c2c',
    100: '#000000',
  },
  error: {
    3: '#ffe7e7e4',
    70: '#ff3a3a',
    100: '#cc0000',
  },
  alphaBlack: {
    30: '#ffffff2b',
    40: '#0000001e',
    60: '#0000008e',
    100: '#000000b4',
  },
  alphaWhite: {
    80: '#ffffffe6',
    60: '#ffffffbe',
  },
};
