import { useTranslate } from '../../hooks/useTranslate';
import { OfferCheckboxProps } from './types';

import { CustomLink, Fieldset, Form, Input, Label, Span } from './styled';

export default function OfferCheckbox({ onChange }: OfferCheckboxProps) {
  const { staticTranslate } = useTranslate();

  return (
    <Form
      onChange={onChange}
      className="offer-checkbox"
      aria-label="Offer approval check"
    >
      <Fieldset>
        <Label htmlFor="offer">
          <Input type="checkbox" name="offer" id="offer" />
          <Span>
            {staticTranslate('TheRulesOfThePublicOffer')}.
            <br />
            <CustomLink to="/offer">
              {staticTranslate('OfferContract')}
            </CustomLink>
          </Span>
        </Label>
      </Fieldset>
    </Form>
  );
}
