import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LANG_LIST } from '../../constants';
import { Lang } from '../types/langTypes';
import { RootReducer } from '../types/store';

function getDefaultLang(): Lang {
  const browserLang = navigator.language.split('-')[0] as Lang;
  return LANG_LIST.includes(browserLang) ? browserLang : 'en';
}

const initialState = {
  lang: getDefaultLang(),
};

const langSlice = createSlice({
  name: '@@lang',
  reducers: {
    setLang: (state, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
    },
  },
  initialState,
});

export const langReducer = langSlice.reducer;
export const { setLang } = langSlice.actions;

export const selectLang = ({ langReducer: { lang } }: RootReducer) => lang;
