import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootReducer } from '../types/store';

const initialState = {
  scannedData: '',
};

const qrSlice = createSlice({
  name: '@@QR',
  initialState,
  reducers: {
    setScannedData: (state, action: PayloadAction<string>) => {
      state.scannedData = action.payload;
    },
  },
});

export const qrReducer = qrSlice.reducer;
export const { setScannedData } = qrSlice.actions;

export const selectScannedData = ({
  qrReducer: { scannedData },
}: RootReducer) => scannedData;
