import { Map } from 'react-yandex-maps';
import styled from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';

export const YandexMap = styled(Map)`
  height: 100%;
  span {
    padding-top: 10px;
    ${infoTextTemplate};
    font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
    width: 150px;
    display: block;
  }
`;
