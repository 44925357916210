import {
  createGlobalStyle,
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  GlobalStyles,
  ThemeProps,
} from 'styled-components';

import { FontSize } from '../types/theme';

export function applyLinksStyle({
  isHighlightLinks,
}: GlobalStyles): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return isHighlightLinks
    ? css`
        a {
          text-decoration: underline;
        }
        button {
          text-decoration: underline;
        }
      `
    : css`
        a {
          text-decoration: none;
        }
      `;
}

export function applyFontSize({ fontSize }: GlobalStyles): string {
  switch (fontSize) {
    case FontSize.small:
      return '16.5px'; // 75%
    case FontSize.large:
      return '33px'; // 150%
    case FontSize.largest:
      return '37px'; // 170%
    default:
      return '22px'; // 100%
  }
}

function applyBlackWhiteImgs({
  isBlackWhiteImgs,
}: GlobalStyles): FlattenSimpleInterpolation | false {
  return (
    !!isBlackWhiteImgs &&
    css`
      img {
        filter: grayscale(100%);
      }
      ymaps {
        filter: grayscale(100%);
      }
      svg {
        filter: grayscale(100%);
      }
    `
  );
}

export const GlobalStyle = createGlobalStyle`
  body {
    font-size: ${applyFontSize};
    min-height: 100vh;

    &, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    }

    ${applyLinksStyle};
    ${applyBlackWhiteImgs};
  }
`;
