import Geolocation from '../../assets/images/geolocation.svg';
// TODO: need to fix style
import Logo from '../../assets/images/logoDefault.svg';
import Oval from '../../assets/images/map_oval.svg';
import PostamatTestImage1 from '../../assets/images/PhotoPostamatTest.png';
import PostamatTestImage2 from '../../assets/images/PhotoPostamatTest1.png';
import PostamatTestImage3 from '../../assets/images/PhotoPostamatTest2.png';
import PostamatIcon from '../../assets/images/post_icon-onmap.png';
import PostamatTestImage from '../../assets/images/postamatImage_test.png';

export const Images = {
  Header: { Logo },
  PostamatTest: {
    Image: PostamatTestImage,
    Image1: PostamatTestImage1,
    Image2: PostamatTestImage2,
    Image3: PostamatTestImage3,
  },
  Map: {
    Oval,
    PostamatTestImage,
    PostamatIcon,
    Geolocation,
  },
};
