import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Flow } from '../../types/appLogic';
import { InitialState } from '../types/flowTypes';
import { RootReducer } from '../types/store';

const initialState: InitialState = {
  appFlow: null,
};

const flowSlice = createSlice({
  name: '@@flow',
  initialState,
  reducers: {
    setAppFlow: (state, action: PayloadAction<Flow>) => {
      state.appFlow = action.payload;
    },
  },
});

export const flowReducer = flowSlice.reducer;
export const { setAppFlow } = flowSlice.actions;

export const selectAppFlow = ({ flowReducer: { appFlow } }: RootReducer) =>
  appFlow;
