import { FontSize } from '../../types/theme';
import { Buttons } from './types';

export const BUTTONS: Buttons[] = [
  {
    size: FontSize.small,
    ariaLabel: 'Set font size at 75%',
  },
  {
    size: FontSize.normal,
    ariaLabel: 'Set font size at 100%',
  },
  {
    size: FontSize.large,
    ariaLabel: 'Set font size at 150%',
  },
  {
    size: FontSize.largest,
    ariaLabel: 'Set font size at 200%',
  },
];
