import { useTypedDispatch } from '../../../../../../../../store';
import { setClothe } from '../../../../../../../../store/slices/laundrySlice';
import { AssortmentItem } from '../../../../../../../../store/types/laundryTypes';
import { AssortmentListProps } from './types';

import { AssortmentLI, AssortmentUL } from './styled';

export default function AssortmentList({
  shownAssortment,
  listItemId,
  closeAssortmentList,
}: AssortmentListProps): JSX.Element {
  const dispatch = useTypedDispatch();

  const setClotheHandler = (assortmentItem: AssortmentItem) => () => {
    dispatch(
      setClothe({
        listItemId,
        ...assortmentItem,
      }),
    );
    closeAssortmentList();
  };

  return (
    <AssortmentUL aria-label="Assortment list">
      {shownAssortment.map((assortmentItem) => {
        const { clothe, clotheId } = assortmentItem;
        return (
          <AssortmentLI
            key={clotheId}
            onClick={setClotheHandler(assortmentItem)}
          >
            {clothe}
          </AssortmentLI>
        );
      })}
    </AssortmentUL>
  );
}
