import { DefaultTheme } from 'styled-components';

export enum NavBG {
  opacity,
  fullColor,
  clear,
}

export interface NavProps {
  bgType: NavBG;
  theme: DefaultTheme;
}

export interface NavChildrenProps {
  isSecondary: boolean;
  theme: DefaultTheme;
}

export interface NavSettings {
  isShowInfoButton: boolean;
  isShowBackButton: boolean;
  isShowHomeButton: boolean;
  isSecondaryTitleStyle: boolean;
  isSecondaryIconsStyle: boolean;
  pageHeader: string;
  getNavBGType: () => NavBG;
}

export interface PopupButtonProps {
  isPressed: boolean;
}
