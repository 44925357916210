import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { ReactComponent as Drugs } from '../../../../assets/images/return/rules/drugs.svg';
import { ReactComponent as Exp } from '../../../../assets/images/return/rules/explosion.svg';
import { ReactComponent as Food } from '../../../../assets/images/return/rules/food.svg';
import { ReactComponent as Gun } from '../../../../assets/images/return/rules/gun.svg';
import { ReactComponent as Money } from '../../../../assets/images/return/rules/money.svg';
import { ReactComponent as Poison } from '../../../../assets/images/return/rules/poison.svg';
import { selectThemeSettings } from '../../../../store/slices/themeSlice';
import { ThemeMode } from '../../../../types/theme';

function useApplyIconStyle() {
  const { themeMode } = useSelector(selectThemeSettings);

  return (
    themeMode === ThemeMode.contrast &&
    css`
      circle,
      line {
        stroke: ${({ theme }): string => theme.palette.primary[50]};
      }
      path {
        fill: ${({ theme }): string => theme.palette.primary[50]};
      }
    `
  );
}

const iconsCommonStyle = css`
  min-width: 48px;
  ${useApplyIconStyle};
`;

export const DrugsIcon = styled(Drugs)`
  ${iconsCommonStyle};
`;
export const ExpIcon = styled(Exp)`
  ${iconsCommonStyle};
`;
export const FoodIcon = styled(Food)`
  ${iconsCommonStyle};
`;
export const GunIcon = styled(Gun)`
  ${iconsCommonStyle};
`;
export const MoneyIcon = styled(Money)`
  ${iconsCommonStyle};
`;
export const PoisonIcon = styled(Poison)`
  ${iconsCommonStyle};
`;

export const ListOfProhibitions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(2)};
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(5)};
`;

export const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }): string => theme.spacing(1.5)};
`;

export const Text = styled.p`
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
`;

export const CheckBoxLabel = styled.span`
  text-transform: uppercase;
`;
