import { useTranslate } from '../../../../hooks/useTranslate';
import DeviceStatus from './components/DeviceStatus';
import MainInfo from './components/MainInfo';
import Rate from './components/Rate';

import { DetailsWrapper, DeviceHeader } from './styled';

export default function DeviceInfoDetails(): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <DetailsWrapper>
      <Rate />
      <DeviceHeader>{staticTranslate('DeviceForDelivery')}</DeviceHeader>
      <DeviceStatus />
      <MainInfo />
    </DetailsWrapper>
  );
}
