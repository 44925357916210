/* eslint-disable prefer-destructuring */
import { ThemePalette } from 'styled-components';

import { projectIs } from '../constants';
import { COLORS } from '../theme/colors';
import { defaultPalette, omisPalette } from '../theme/themeData';
import { ThemeMode } from '../types/theme';

export function getCustomThemePalette(themeMode: ThemeMode): ThemePalette {
  const themePalette =
    projectIs.omar || projectIs.omkaz || projectIs.mncdk
      ? defaultPalette
      : omisPalette;

  if (themeMode === ThemeMode.contrast) {
    const customPalette: ThemePalette = JSON.parse(
      JSON.stringify(themePalette),
    );

    customPalette.palette.primary[30] = COLORS.yellow[100];
    customPalette.palette.primary[40] = COLORS.yellow[100];
    customPalette.palette.primary[50] = COLORS.yellow[100];

    customPalette.palette.cellHightLight = COLORS.yellow[100];

    customPalette.palette.background.secondary = COLORS.grey[100];
    customPalette.palette.background.hightLight = COLORS.yellow[100];
    customPalette.palette.background.primary = COLORS.yellow[100];
    customPalette.palette.background.rateLine = COLORS.yellow[20];
    customPalette.palette.background.order = COLORS.grey[100];
    customPalette.palette.background.attention = COLORS.yellow[100];
    customPalette.palette.background.specialHeader = COLORS.yellow[100];

    customPalette.palette.text.primary = COLORS.yellow[100];
    customPalette.palette.text.asTheme = COLORS.yellow[100];
    customPalette.palette.text.secondary = COLORS.grey[100];
    customPalette.palette.text.hightLight = COLORS.yellow[100];
    customPalette.palette.text.headerActive = COLORS.grey[100];

    customPalette.palette.border.asPrimeText = COLORS.yellow[100];
    customPalette.palette.border.primary = COLORS.yellow[100];
    customPalette.palette.border.secondary = COLORS.yellow[100];
    customPalette.palette.border.special = COLORS.yellow[100];
    customPalette.palette.border.shadow = COLORS.yellow[40];
    customPalette.palette.border.shadowStrong = COLORS.yellow[60];
    customPalette.palette.border.hightLight = COLORS.grey[100];

    customPalette.palette.nav.buttons.primary = COLORS.yellow[100];
    customPalette.palette.nav.buttons.secondary = COLORS.grey[100];

    customPalette.palette.face.dislike = COLORS.yellow[100];

    customPalette.palette.notification.success = COLORS.yellow[95];
    customPalette.palette.notification.error = COLORS.yellow[95];
    customPalette.palette.notification.neutral = COLORS.yellow[95];

    return customPalette;
  }

  if (themeMode === ThemeMode.dark) {
    const customPalette: ThemePalette = JSON.parse(
      JSON.stringify(themePalette),
    );

    customPalette.palette.primary[30] = COLORS.grey[90];
    customPalette.palette.primary[40] = COLORS.grey[90];
    customPalette.palette.primary[50] = COLORS.grey[90];

    customPalette.palette.cellHightLight = COLORS.grey[0];

    customPalette.palette.background.primary = COLORS.grey[90];
    customPalette.palette.background.hightLight = COLORS.grey[0];
    customPalette.palette.background.specialHeader = COLORS.grey[10];

    customPalette.palette.success = COLORS.grey[80];
    customPalette.palette.error = COLORS.grey[95];

    customPalette.palette.text.asTheme = COLORS.grey[90];
    customPalette.palette.text.headerActive = COLORS.grey[100];
    customPalette.palette.text.secondary = COLORS.grey[0];

    customPalette.palette.border.primary = COLORS.grey[90];

    customPalette.palette.nav.buttons.primary = COLORS.grey[100];
    customPalette.palette.nav.buttons.secondary = COLORS.grey[0];

    customPalette.palette.notification.success = COLORS.grey[3];
    customPalette.palette.notification.error = COLORS.grey[3];
    customPalette.palette.notification.neutral = COLORS.grey[3];

    return customPalette;
  }

  return themePalette;
}
