import { Variant } from '../../components/Button/types';

export enum Step {
  phone = 'phoneNumber',
  code = 'code',
}

export interface ReceiveFlowManagerData {
  step: Step;
  isGettingCode: boolean;
  isGettingOrders: boolean;
  countdownValue: string;
  getCodeHandler: () => void;
  getOrdersListHandler: () => void;
  prevStepHandle: () => void;
}

export interface PaperProps {
  step: Step;
}

export interface ButtonsData {
  isActiveGetCode: boolean;
  isActiveGetOrders: boolean;
  isShowGetOrders: boolean;
  getCodeValue: string;
  backBtnVariant: Variant;
  backBtnText: string;
}

export interface ButtonsSettingsProps {
  step: Step;
  countdownValue: string;
}
