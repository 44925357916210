import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';
import { ClientsContainerProps } from './types';

export const ClientsDataContainer = styled.div`
  width: 100%;
  padding: ${({ theme: { spacing } }): string => spacing(3)};
  border-bottom: 1px solid
    ${({ theme }): string => theme.palette.border.secondary};
`;

export const OrderPlace = styled.span`
  ${infoTextTemplate}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing(2.25)};
`;

export const OrderPersonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: ${({ theme }): string => theme.spacing(1.5)};
  height: fit-content;
`;

const commonClients = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: ${({ theme }): string => theme.palette.text.primary};

  &:before {
    content: '';
    position: absolute;
    min-width: 13px;
    min-height: 13px;
    background-color: ${({ theme }): string =>
      theme.palette.background.primary};
    border-radius: 50%;
  }
`;

export const Sender = styled.div`
  ${commonClients};
  &:after {
    top: 50%;
  }
`;

export const Receiver = styled.div`
  ${commonClients};
  &:after {
    bottom: 50%;
  }
  margin-top: ${({ theme }): string => theme.spacing(1.4)};
`;

const commonName = css`
  ${infoTextTemplate}
  line-height: 1.6;

  strong {
    display: contents;
  }
`;

export const SenderName = styled.p`
  ${commonName};
`;

export const ReceiverName = styled.p`
  ${commonName};
`;

function applyClientsStyles({
  direction,
  isShowConnectionLine,
}: ClientsContainerProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  const connectionLine =
    isShowConnectionLine &&
    css`
      ${Sender}, ${Receiver} {
        &:after {
          content: '';
          position: absolute;
          height: 1.63em;
          width: 1px;
          background-color: ${({ theme }): string =>
            theme.palette.background.primary};
        }
      }
    `;

  return direction === 'ltr'
    ? css`
        ${connectionLine};
        padding-left: ${({ theme }): string => theme.spacing(7.5)};
        ${Sender}, ${Receiver} {
          &:before {
            margin-left: -${({ theme }): string => theme.spacing(4)};
          }
          &:after {
            margin-left: -${({ theme }): string => theme.spacing(3.3)};
          }
        }
      `
    : css`
        ${connectionLine};
        padding-right: ${({ theme }): string => theme.spacing(7.5)};
        ${Sender}, ${Receiver} {
          &:before {
            margin-right: -${({ theme }): string => theme.spacing(4)};
          }
          &:after {
            margin-right: -${({ theme }): string => theme.spacing(3.3)};
          }
        }
      `;
}

export const Clients = styled.div`
  ${applyClientsStyles};
`;

export const Phone = styled.p`
  ${infoTextTemplate}
  font-size: 0.6;
`;

export const OrderManagementContainer = styled.div`
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(3)} ${spacing(3)} ${spacing(5)}`};
`;

export const OrderPayment = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoWrapper = styled.div``;

export const AttentionText = styled.p`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
  color: ${({ theme }): string => theme.palette.error};
`;

export const InfoText = styled.p`
  ${infoTextTemplate}
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const Price = styled.span`
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
`;

export const OrderStorage = styled(OrderPayment)`
  margin-top: ${({ theme }): string => theme.spacing(4)};
`;
