import { ThemePalette, ThemeTypography } from 'styled-components';

export enum ThemeMode {
  default = 'default',
  dark = 'dark',
  contrast = 'contrast',
}

export enum FontSize {
  small = 'small',
  normal = 'normal',
  large = 'large',
  largest = 'largest',
}

export interface ThemeSettings {
  themeMode: ThemeMode;
  isHighlightLinks: boolean;
  isArialFont: boolean;
  fontSize: FontSize;
}

export interface ThemeInitialState {
  themeSettings: ThemeSettings;
  themePalette: ThemePalette;
  themeTypography: ThemeTypography;
}
