import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { ReactComponent as CoseSVG } from '../../../assets/images/close.svg';
import { infoTextTemplate } from '../../../theme/pagesElements';
import { PopupContainerProps } from './types';

function applyPopupVisibility({
  isVisible,
}: PopupContainerProps): FlattenSimpleInterpolation {
  return isVisible
    ? css`
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      `
    : css`
        opacity: 0;
        pointer-events: none;
        transform: translateY(5px);
      `;
}

export const PopupContainer = styled.div`
  ${applyPopupVisibility};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  background-color: ${({ theme }): string =>
    theme.palette.background.secondary};
  margin-top: 17px;
  margin-left: -7px;
  padding: ${({ theme }): string => theme.spacing(1.5)};
  width: 300px;

  position: absolute;
  top: 100%;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};
  box-shadow: 0 0 6px 0px
    ${({ theme }): string => theme.palette.border.shadowStrong};

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 16px;
    width: 0px;
    height: 0px;

    border: 9px solid black;
    transform: rotate(45deg);

    border-color: ${({ theme }): string => theme.palette.background.secondary}
      transparent transparent
      ${({ theme }): string => theme.palette.background.secondary};

    box-shadow: -2px -2px 3px -2px
      ${({ theme }): string => theme.palette.border.shadowStrong};
  }
`;

export const PopupTitle = styled.h3`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing()};
`;

export const FunctionsList = styled.ol`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const ListItem = styled.li`
  margin-bottom: ${({ theme }): string => theme.spacing()};
  list-style-position: inside;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CloseIcon = styled(CoseSVG)`
  position: absolute;
  right: 8px;
  top: 8px;
  width: 22px;
  height: 22px;

  path {
    fill: ${({ theme }): string => theme.palette.text.primary};
  }
`;
