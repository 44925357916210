import { DefaultTheme, ThemePalette, ThemeTypography } from 'styled-components';

import { commonThemeData } from '../theme/themeData';

export function composeTheme(
  palette: ThemePalette,
  themeTypography: ThemeTypography,
): DefaultTheme {
  return {
    ...palette,
    ...themeTypography,
    ...commonThemeData,
  };
}
