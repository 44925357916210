import { v4 as uuidv4 } from 'uuid';

import { AssortmentItem } from '../types/laundryTypes';

export const ASSORTMENT: AssortmentItem[] = [
  {
    clothe: 'Постільна білизна',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Берет',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Блуза',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Босоніжки',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Бриджі',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Гаманець',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Гольф',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Джинси',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Дублянка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Жакет',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Жалюзі',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Жилет',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Капелюх',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Кардиган',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Кепка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Килими',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Ковдра',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Кожушок/напівшуба',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Комбінезон',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Комір',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Конверт дитячий',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Корсет',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Краватка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Кросівки',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Купальник',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Куртка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Ламбрекен',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Майка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Наволочка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Наматрацник',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Напівпальто',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Напівчоботи',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Напівшуба',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Пальто',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Підстібка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Підхват',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Піжама-блуза',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Піжама-штани',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Плащ',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Плед',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Подушка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Покривало',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Портьєра',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Реглан',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Ремінь',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Рукавички',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Рушник',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Рюкзак',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Сарафан',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Светр',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Серветка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Скатертина',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Сорочка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Спідниця',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Сукня',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Сумка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Теніска',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Топ',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Туніка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Туфлі',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Тюль',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Фата',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Футболка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Халат',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Хустка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Черевики',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Підкладка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Чоботи',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Чохол',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Шапка',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Шарф',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Шкура',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Шорти',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Штани',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Штора',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Шуба',
    clotheId: uuidv4(),
  },
];

export const ASSORTMENT_EN: AssortmentItem[] = [
  {
    clothe: 'Linens',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Beret',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Blouse',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Sandals',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Trousers',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Wallet',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Golf',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Jeans',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Sheepskin coat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Jacket',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Blinds',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Vest',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Hat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Cardigan',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Cap',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Carpets',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Blanket',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Fur coat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Jumpsuit',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Collar',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Envelope',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Corset',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Tie',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Sneakers',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Swimsuit',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Jacket',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Lambrequin',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Singlet',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Pillowcase',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Mattress',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Half coat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Boots',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Fur coat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Coat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Garter',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Tieback',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Pajama-blouse',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Pajama-pants',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Raincoat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Plaid',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Pillow',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Bedspread',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Curtain',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Raglan',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Belt',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Gloves',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Towel',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Backpack',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Sundress',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Sweater',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Napkin',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Tablecloth',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Shirt',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Skirt',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Dress',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Bag',
    clotheId: uuidv4(),
  },
  {
    clothe: 'T-shirt',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Top',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Tunic',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Shoes',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Tulle',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Veil',
    clotheId: uuidv4(),
  },
  {
    clothe: 'T-shirt',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Bathrobe',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Scarf',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Boots',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Lining',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Boots',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Cover',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Hat',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Scarf',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Skin',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Shorts',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Pants',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Curtain',
    clotheId: uuidv4(),
  },
  {
    clothe: 'Fur coat',
    clotheId: uuidv4(),
  },
];
