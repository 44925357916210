import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import ButtonsPaper from '../../components/ButtonsPaper';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useTranslate } from '../../hooks/useTranslate';
import { useTypedDispatch } from '../../store';
import {
  cleanOrdersList,
  selectOrdersList,
} from '../../store/slices/receiveSlice';

import { Order, OrdersWrapper } from './styled';

export default function OrdersListPage(): JSX.Element {
  const orders = useSelector(selectOrdersList);
  const dispatch = useTypedDispatch();
  const { navigateWithUid } = useCustomNavigate();

  const { staticTranslate } = useTranslate();

  function changeClientNumber(): void {
    dispatch(cleanOrdersList());
    navigateWithUid('/get-order', { replace: true });
  }

  usePageHeaderContext(staticTranslate('Orders3'));

  return (
    <>
      <OrdersWrapper>
        {orders.map(({ order_number: orderNumber, uid }) => {
          return (
            <Order key={uid} to={`/${uid}`}>
              {orderNumber}
            </Order>
          );
        })}
      </OrdersWrapper>
      <ButtonsPaper>
        <Button variant="contained" onClick={changeClientNumber}>
          {staticTranslate('EnterAnotherNumber')}
        </Button>
        <Button variant="outlined" to="/">
          {staticTranslate('ToMain')}
        </Button>
      </ButtonsPaper>
    </>
  );
}
