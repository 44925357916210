import DeviceRatingStars from '../../../../../DeviceRatingStars';
import { LastFeedbackProps } from './types';

import {
  Comment,
  DateOfComment,
  LastFeedbackWrapper,
  Rate,
  RateStarsWrapper,
  Recipient,
  RecipientDateWrapper,
} from './styled';

export default function LastFeedback({ lastFeedback }: LastFeedbackProps) {
  const date = new Date(lastFeedback.created_at).toLocaleDateString();
  const recipientName = lastFeedback.recipient_name;

  return (
    <LastFeedbackWrapper>
      <RecipientDateWrapper>
        {recipientName && (
          <Recipient aria-label="Recipient name">{recipientName}</Recipient>
        )}
        <DateOfComment aria-label="Date of leaving comment">
          {date}
        </DateOfComment>
      </RecipientDateWrapper>
      <RateStarsWrapper>
        <DeviceRatingStars rating={lastFeedback.rating} />
        <Rate aria-label="Rating that left recipient">
          {lastFeedback.rating}
        </Rate>
      </RateStarsWrapper>
      <Comment aria-label="Comment that left recipient">
        {lastFeedback.comment}
      </Comment>
    </LastFeedbackWrapper>
  );
}
