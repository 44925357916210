import { useEffect } from 'react';

import { projectIs } from '../constants';

export function useHeadTitle(title?: string): void {
  const project =
    projectIs.omkaz || projectIs.omar || projectIs.mncdk ? 'OMNI HUB' : 'OMNI';

  useEffect(() => {
    document.title = title ? `${title} | ${project}` : project;
  }, [title, project]);
}
