import styled from 'styled-components';

import { ReactComponent as CloseSVG } from '../../../../../../../../assets/images/close.svg';

export const CloseContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: absolute;
  top: ${({ theme }): string => theme.spacing(0.5)};
  right: ${({ theme }): string => theme.spacing(0.5)};
  background-color: ${({ theme }): string => theme.palette.alphaBlack[100]};
  border: none;
  outline: none;
  height: 35px;
  width: 35px;
  border-radius: 50%;
`;

export const CloseMark = styled(CloseSVG)`
  width: 50%;
  path {
    fill: ${({ theme }): string => theme.palette.grey[0]};
  }
`;
