import styled, { css } from 'styled-components';

import { getDir } from '../../utils/getDir';
import { ReturnStep, SliderProps } from './types';

function applyStep({ step, type }: SliderProps): string {
  const dir = getDir();
  const operator = dir === 'ltr' ? '-' : '+';

  if (type === 'content') {
    if (step === ReturnStep.returnPolicy) return `translateX(${operator}100vw)`;
    if (step === ReturnStep.packaging) return `translateX(${operator}200vw)`;
    if (step === ReturnStep.parcelSize) return `translateX(${operator}300vw)`;
    if (step === ReturnStep.prohibitions) return `translateX(${operator}400vw)`;
  }
  if (type === 'buttons') {
    if (step === ReturnStep.returnPolicy || step === ReturnStep.packaging) {
      return `translateX(${operator}100vw)`;
    }
    if (step === ReturnStep.parcelSize) {
      return `translateX(${operator}200vw)`;
    }
    if (step === ReturnStep.prohibitions) {
      return `translateX(${operator}300vw)`;
    }
    return 'translateX(0vw)';
  }
  return 'translateX(0vw)';
}

function applyOlPadding() {
  const dir = getDir();
  return dir === 'ltr'
    ? css`
        padding-left: ${({ theme }): string => theme.spacing(2)};
        li {
          padding-left: ${({ theme }): string => theme.spacing()};
        }
      `
    : css`
        padding-right: ${({ theme }): string => theme.spacing(2)};
        li {
          padding-right: ${({ theme }): string => theme.spacing()};
        }
      `;
}

export const ReturnContainer = styled.main`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Slider = styled.div`
  display: flex;
  flex-direction: row;
  transform: ${applyStep};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
`;

export const StepContent = styled.div`
  min-width: 100vw;
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const SingleButtonWrapper = styled.div`
  width: 100vh;
  min-width: 100vw;
`;

export const MultiButtonsWrapper = styled.div`
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }): string => theme.spacing()};

  button {
    margin: 0;
  }
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }): string => theme.spacing(4)};
`;

export const ContentWrapper = styled.div`
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const Heading = styled.h1`
  color: ${({ theme }): string => theme.palette.text.primary};
  text-align: center;
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};

  margin-bottom: ${({ theme }): string => theme.spacing(4)};
`;

export const HeadingWithBg = styled.h1`
  text-align: center;
  color: ${({ theme }): string => theme.palette.grey[100]};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  padding: ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};

  background-color: ${({ theme }): string =>
    theme.palette.background.specialHeader};
`;

export const OrderedList = styled.ol`
  ${applyOlPadding};
`;

export const ListItem = styled.li`
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};

  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;
