import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(3)};
`;

export const BottomWrapper = styled.div`
  width: 100%;
`;

export const Acceptation = styled.p`
  width: 100%;
  padding: 0 ${({ theme }): string => theme.spacing(2)};

  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-align: center;

  margin-bottom: ${({ theme }): string => theme.spacing(5)};
`;
