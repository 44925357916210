import styled from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';
import { ThanksContainerProps } from './types';

function applyColor({ textVariant, theme }: ThanksContainerProps): string {
  return textVariant === 'secondary'
    ? theme.palette.text.secondary
    : theme.palette.text.primary;
}

export const ThanksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${applyColor};
  padding: 0px ${({ theme: { spacing } }): string => spacing(2)};
`;

export const ThanksTitle = styled.h1`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  margin-top: ${({ theme }): string => theme.spacing(6)};
`;

export const ThanksText = styled.p`
  ${infoTextTemplate};
  margin-top: ${({ theme }): string => theme.spacing(1.5)};
`;
