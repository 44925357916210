import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { request } from '../../api';
import { Extra } from '../../api/types';
import { Flow } from '../../types/appLogic';
import {
  ConfigResponse,
  InitialState,
  OpenCellArg,
} from '../types/configResponse';
import { RootReducer } from '../types/store';

export const openDeviceCell = createAsyncThunk<Extra | undefined, OpenCellArg>(
  '@@config/openDeviceCell',
  async ({ deviceUid, orderUid, flow }) => {
    const pathPart = flow === Flow.common ? 'receipt' : 'placing';

    const { extra } = await request({
      method: 'POST',
      path: `/order/${pathPart}/web/`,
      data: {
        device_uid: deviceUid,
        order_uid: orderUid,
      },
    });

    return extra;
  },
);

export const getDeviceConfig = createAsyncThunk<ConfigResponse, string>(
  '@@config/getDeviceConfig',
  async (deviceUid) => {
    const { data } = await request<ConfigResponse>({
      path: `/device/${deviceUid}/configuration/view/`,
    });

    return data;
  },
);

const initialState: InitialState = {
  configStatus: 'idle',
  openingStatus: 'idle',
  config: null,
  openedCellData: null,
};

const configSlice = createSlice({
  name: '@@order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceConfig.pending, (state) => {
        state.configStatus = 'loading';
      })
      .addCase(getDeviceConfig.rejected, (state) => {
        // eslint-disable-next-line no-console
        console.error('CONFIG_LOADING_ERROR');
        state.configStatus = 'rejected';
      })
      .addCase(getDeviceConfig.fulfilled, (state, action) => {
        state.configStatus = 'received';
        state.config = action.payload as ConfigResponse;
      })
      .addCase(openDeviceCell.pending, (state) => {
        state.openingStatus = 'loading';
      })
      .addCase(openDeviceCell.rejected, (state) => {
        state.openingStatus = 'rejected';
      })
      .addCase(openDeviceCell.fulfilled, (state, action) => {
        state.openingStatus = 'received';
        state.openedCellData = action.payload as Extra;
      });
  },
});

export const configReducer = configSlice.reducer;

export const selectConfig = ({ configReducer: { config } }: RootReducer) =>
  config;

export const selectConfigStatus = ({
  configReducer: { configStatus },
}: RootReducer) => configStatus;

export const selectOpeningStatus = ({
  configReducer: { openingStatus },
}: RootReducer) => openingStatus;

export const selectOpenedCellData = ({
  configReducer: { openedCellData },
}: RootReducer) => openedCellData;
