import { ForwardedRef, forwardRef } from 'react';

import { projectIs } from '../../../../constants';
import { SUNDAY } from './constants';
import { WorkScheduleProps } from './types';

import { DayName, PipeLine, WorkingTime, WSContainer, WSItem } from './styled';

export const WorkSchedule = forwardRef(
  (
    { workSchedule }: WorkScheduleProps,
    ref: ForwardedRef<HTMLUListElement>,
  ): JSX.Element => {
    return (
      <WSContainer ref={ref} aria-label="Working time list">
        {workSchedule.map(
          ({ dayName, time: [openTime, closeTime], dayNumber }) => {
            const isFirstWeeklyDay = dayNumber === SUNDAY && projectIs.omis;

            return (
              <WSItem isFirstWeeklyDay={isFirstWeeklyDay} key={dayName}>
                <DayName aria-label="Name of the week day">{dayName}</DayName>
                <PipeLine />
                <WorkingTime aria-label="Working time">
                  {openTime} - {closeTime}
                </WorkingTime>
              </WSItem>
            );
          },
        )}
      </WSContainer>
    );
  },
);
