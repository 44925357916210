import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { projectIs } from '../../constants';
import {
  defaultPalette,
  omisPalette,
  themeTypography,
} from '../../theme/themeData';
import { ThemeInitialState, ThemeSettings } from '../../types/theme';
import { getCustomThemePalette } from '../../utils/getCustomThemePalette';
import { getThemeFonts } from '../../utils/getThemeFonts';
import { DEFAULT_THEME_SETTINGS } from '../constants';
import { RootReducer } from '../types/store';

const initialState: ThemeInitialState = {
  themeSettings: DEFAULT_THEME_SETTINGS,
  themePalette:
    projectIs.omar || projectIs.omkaz || projectIs.mncdk
      ? defaultPalette
      : omisPalette,
  themeTypography,
};

const themeSlice = createSlice({
  name: '@@Theme',
  initialState,
  reducers: {
    setThemeSettings: (state, { payload }: PayloadAction<ThemeSettings>) => {
      state.themeSettings = payload;
      state.themePalette = getCustomThemePalette(payload.themeMode);
      state.themeTypography = getThemeFonts(payload.isArialFont);
    },
  },
});

export const themeReducer = themeSlice.reducer;
export const { setThemeSettings } = themeSlice.actions;

export const selectThemeSettings = ({
  themeReducer: { themeSettings },
}: RootReducer) => themeSettings;

export const selectThemePalette = ({
  themeReducer: { themePalette },
}: RootReducer) => themePalette;

export const selectThemeTypography = ({
  themeReducer: { themeTypography: typography },
}: RootReducer) => typography;
