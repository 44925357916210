import { useSelector } from 'react-redux';
import { DefaultTheme } from 'styled-components';

import {
  selectThemePalette,
  selectThemeTypography,
} from '../store/slices/themeSlice';
import { composeTheme } from '../utils/composeTheme';

export function useComposeTheme(): DefaultTheme {
  const themePalette = useSelector(selectThemePalette);
  const themeTypography = useSelector(selectThemeTypography);
  return composeTheme(themePalette, themeTypography);
}
