import styled, { keyframes } from 'styled-components';

const barStripe = keyframes`
  0% {
    background-position: 1em 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const Loader = styled.span`
  width: 120px;
  height: 10px;
  display: inline-block;
  border-radius: 7px;
  background-image: linear-gradient(
    45deg,
    ${({ theme }): string => theme.palette.background.primary} 25%,
    transparent 25%,
    transparent 50%,
    ${({ theme }): string => theme.palette.background.primary} 50%,
    ${({ theme }): string => theme.palette.background.primary} 75%,
    transparent 75%,
    transparent
  );
  font-size: 30px;
  background-size: 1em 1em;
  animation: ${barStripe} 1s linear infinite;
`;
