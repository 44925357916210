import styled from 'styled-components';

import { infoTextTemplate } from '../../../../../../theme/pagesElements';

export const FeedbacksWrapper = styled.div`
  padding: 0 ${({ theme }): string => theme.spacing(3)};
`;

export const RatingTitle = styled.h2`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const AverageRatingWrapper = styled.div`
  display: flex;
`;

export const RatingLinesGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(1.5)};
`;

export const NumberLineGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};
`;

export const Number = styled.div`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
`;

export const BackLine = styled.div`
  position: relative;
  width: 170px;
  height: 11px;
  border-radius: 5px;
  background-color: ${({ theme }): string => theme.palette.background.rateLine};
`;

export const ColorLine = styled.div`
  height: inherit;
  border-radius: 5px;
  background-color: ${({ theme }): string => theme.palette.primary[30]};
`;

export const AverageRate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.primary[30]};
  font-size: 70px;
  flex-grow: 1;
`;

export const FeedbacksCount = styled(RatingTitle)`
  margin-top: ${({ theme }): string => theme.spacing(3)};
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
`;

export const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: ${({ theme }): string => theme.palette.background.rateLine};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: 30px;
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 200px;
`;
