import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { request } from '../../api';
import { CellsResponse, InitialState } from '../types/cellsResponse';
import { RootReducer } from '../types/store';

export const getCells = createAsyncThunk<CellsResponse>(
  '@@cells/getCells',
  async (_, { getState }) => {
    const {
      initialReducer: { savedUid: deviceUid },
    } = getState() as RootReducer;
    const { data } = await request<CellsResponse>({
      path: `/public/orders/device/${deviceUid}/cells/statuses/?type=5`,
    });

    return data;
  },
);

const initialState: InitialState = {
  status: 'idle',
  cells: [],
  deviceUid: '',
};

const cellsSlice = createSlice({
  name: '@@cells',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCells.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCells.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(getCells.fulfilled, (state, action) => {
        state.status = 'received';
        state.cells = action.payload.cell_types;
        state.deviceUid = action.payload.device_uid;
      });
  },
});

export const cellsReducer = cellsSlice.reducer;

export const selectCells = ({ cellsReducer: { cells } }: RootReducer) => cells;

export const selectCellsStatus = ({ cellsReducer: { status } }: RootReducer) =>
  status;
