import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

import { ReactComponent as CloseSVG } from '../../assets/images/close.svg';
import { NotificationProps } from './types';

function applyNotificationStyle({
  isActive,
}: NotificationProps): FlattenSimpleInterpolation {
  return isActive
    ? css`
        transform: translateX(0px);
      `
    : css`
        transform: translateX(297px);
      `;
}

function applyBackground({
  status,
}: NotificationProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  if (status === 'success') {
    return css`
      background: ${({ theme }): string => theme.palette.notification.success};
    `;
  }
  if (status === 'error') {
    return css`
      background: ${({ theme }): string => theme.palette.notification.error};
    `;
  }
  return css`
    background: ${({ theme }): string => theme.palette.notification.neutral};
  `;
}

export const Notification = styled.div`
  position: fixed;
  right: ${({ theme }): string => theme.spacing()};
  top: ${({ theme }): string => theme.spacing(3)};
  width: 288px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;

  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);

  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${applyNotificationStyle};
  ${applyBackground};
`;

export const Message = styled.p`
  flex-grow: 1;
  padding: ${({ theme }): string => theme.spacing(3)};

  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  color: ${({ theme }): string => theme.palette.grey[100]};
`;

export const CloseIcon = styled(CloseSVG)`
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  align-self: flex-start;
  margin-right: ${({ theme }): string => theme.spacing(3)};
  margin-top: ${({ theme }): string => theme.spacing(3.35)};
`;
