import { useTranslate } from '../../../../hooks/useTranslate';
import Laundries from './components/Laundries/Laundries';

import { CommonWrapper, MainDataWrapper, Title } from '../../styled';

export default function MainPage(): JSX.Element {
  const { staticTranslate } = useTranslate();
  return (
    <CommonWrapper>
      <Title>{staticTranslate('chooseLaundry')}</Title>
      <MainDataWrapper>
        <Laundries />
      </MainDataWrapper>
    </CommonWrapper>
  );
}
