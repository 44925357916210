import styled from 'styled-components';

import { ReactComponent as LF } from '../../../../assets/images/likeFace.svg';

export const LikeFaceIcon = styled(LF)`
  width: 94px;
  height: 94px;
  margin-top: ${({ theme }): string => theme.spacing(10)};
  path {
    fill: ${({ theme }): string => theme.palette.text.secondary};
  }
`;

export const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
