import styled, { css } from 'styled-components';

import { HEIGHT_OF_HEADER_FOOTER } from './constants';

import { Wrapper } from '../../components/Paper/styled';

const commonStyle = css`
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

const Headings = css`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  height: calc(100vh - ${HEIGHT_OF_HEADER_FOOTER});
`;

export const H1 = styled.h1`
  ${commonStyle};
  ${Headings};
  font-size: 0.9em;
`;

export const H2 = styled.h2`
  ${commonStyle};
  ${Headings};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  margin-top: ${({ theme }): string => theme.spacing(3)};
`;

export const H4 = styled.h4`
  ${commonStyle};
  ${Headings};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  margin-top: ${({ theme }): string => theme.spacing(3)};
`;

export const Paragraph = styled.p`
  ${commonStyle};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  strong {
    font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  }
`;

export const Main = styled(Wrapper)`
  min-height: 50%;
  flex-shrink: 1;
  position: relative;
  display: flex;
`;

export const ContentWindow = styled.div`
  overflow-y: auto;
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const Ul = styled.ul`
  ${commonStyle};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  list-style-position: inside;
`;

export const Li = styled.li``;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }): string => theme.spacing(2)};
`;

export const A = styled.a`
  color: ${({ theme }): string => theme.palette.text.asTheme};
`;
