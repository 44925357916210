import { useEffect, useState } from 'react';
import axios from 'axios';

export function usePhoneInputSettings(savedPhone: string) {
  const [country, setCountry] = useState('');

  function getGeoInfo() {
    if (savedPhone) return;
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const { data } = response;
        const currentCountry = data.country.toLowerCase();
        setCountry(currentCountry);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  useEffect(() => {
    getGeoInfo();
    // We must check this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return country;
}
