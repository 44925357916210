import { useTranslate } from '../../../../hooks/useTranslate';
import { ThanksProps } from './types';

import { ThanksContainer, ThanksText, ThanksTitle } from './styled';

export default function Thanks({ textVariant }: ThanksProps): JSX.Element {
  const { staticTranslate } = useTranslate();

  return (
    <ThanksContainer textVariant={textVariant}>
      <ThanksTitle>{staticTranslate('FeedbackLike')}</ThanksTitle>
      <ThanksText>{staticTranslate('FeedbackLike3')}</ThanksText>
    </ThanksContainer>
  );
}
