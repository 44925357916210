import { useEffect } from 'react';
import Favicon from 'react-favicon';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { projectIs } from './constants';
import NotificationsContext from './context/NotificationsContext';
import PageHeaderContext from './context/PageHeaderContext/PageHeaderContext';
import { useComposeTheme } from './hooks/useComposeTheme';
import { useRoutes } from './hooks/useRoutes';
import { selectThemeSettings } from './store/slices/themeSlice';
import { GlobalStyle } from './theme/global';
import { ThemeMode } from './types/theme';

export default function App(): JSX.Element {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const routes = useRoutes();

  const { fontSize, isHighlightLinks, themeMode } =
    useSelector(selectThemeSettings);

  const isBlackWhiteImgs = themeMode === ThemeMode.dark;

  const favicon =
    projectIs.omkaz || projectIs.omar || projectIs.mncdk
      ? 'faviconCommon.ico'
      : 'faviconOmis.ico';

  const theme = useComposeTheme();

  const queryUid = searchParams.get('uid');

  useEffect(() => {
    if (queryUid) {
      navigate(`/${queryUid}`);
    }
    // We must check this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle
        fontSize={fontSize}
        isHighlightLinks={isHighlightLinks}
        isBlackWhiteImgs={isBlackWhiteImgs}
      />
      <Favicon url={favicon} />
      <NotificationsContext>
        <PageHeaderContext>{!queryUid && routes}</PageHeaderContext>
      </NotificationsContext>
    </ThemeProvider>
  );
}
