import dictionary from './translate.json';

interface ILang {
  [key: string]: { [key: string]: string };
}

export const localize = (key: string, lang = 'ru') =>
  (dictionary as ILang)[key][lang];

interface ILangDescriptor {
  key: string;
  short3: string; // https://www.w3schools.com/tags/ref_language_codes.asp
  html_code: string;
  dir?: string;
}

export const LangDescriptorList: { [id: string]: ILangDescriptor } = {
  ru: {
    key: 'ru',
    html_code: 'ru-RU',
    short3: 'РУС',
  },
  en: {
    key: 'en',
    html_code: 'en-US',
    short3: 'ENG',
  },
  he: {
    key: 'he',
    html_code: 'he-IL',
    short3: 'עִברִית',
    dir: 'rtl',
  },
  hy: {
    key: 'hy',
    html_code: 'hy_AM',
    short3: 'ARM',
  },
  kk: {
    key: 'kk',
    html_code: 'kk_KZ',
    short3: 'KAZ',
  },
  uk: {
    key: 'uk',
    html_code: 'uk-UA',
    short3: 'УКР',
  },
  it: {
    key: 'it',
    html_code: 'it-IT',
    short3: 'ITA',
  },
  th: {
    key: 'th',
    html_code: 'th-TH',
    short3: 'THA',
  },
  ka: {
    key: 'ka',
    html_code: 'ka-GE',
    short3: 'GEO',
  },
};
