import styled from 'styled-components';

import { ReactComponent as ErrorSVG } from '../../../../assets/images/extendError.svg';
import { infoTextTemplate } from '../../../../theme/pagesElements';

export const ErrorIcon = styled(ErrorSVG)`
  position: absolute;
  path {
    fill: ${({ theme }): string => theme.palette.error};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 0 ${({ theme }): string => theme.spacing(1.5)};
`;

export const ErrorHeading = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  color: ${({ theme }): string => theme.palette.error};
  margin-top: ${({ theme }): string => theme.spacing(3)};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const ErrorMessage = styled.p`
  width: 100%;
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
