import styled from 'styled-components';

import { ReactComponent as Operator } from '../../../../assets/images/laundry/operator.svg';

import { TextInfoBig } from '../../styled';

export const OperatorIcon = styled(Operator)`
  path {
    fill: ${({ theme }): string => theme.palette.text.asTheme};
  }
`;

export const ContentWrapper = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const CustomBigInfo = styled(TextInfoBig)`
  padding: 0;
`;
