import {
  CommonThemeData,
  ThemePalette,
  ThemeTypography,
} from 'styled-components';

import { COLORS } from './colors';

export const themeTypography: ThemeTypography = {
  typography: {
    fontFamily: {
      font1: 'Inter, sans-serif',
      font2: 'Montserrat, sans-serif',
      font3: 'Open Sans, sans-serif',
    },
    fontWeight: {
      300: '300',
      400: '400',
      500: '500',
      600: '600',
      700: '700',
    },
    fontSize: {
      xxl: '1em', // 22px (100%)
      xl: '0.77em', // 17px according markup
      l: '0.7em', // 15px according markup
      m: '0.64em', // 14px according markup
      s: '0.6em', // 13px according markup
      xs: '0.55em', // 12px according markup
      xxs: '0.5em', // 11px according markup
    },
  },
};

export const commonThemeData: CommonThemeData = {
  shape: {
    borderRadius: {
      s: '4px',
      m: '5px',
      l: '10px',
      xl: '20px',
    },
  },
  transitionDuration: {
    fast: '0.2s',
    normal: '0.3s',
  },
  spacing: (value = 1) => `${value * 8}px`,
};

export const omisPalette: ThemePalette = {
  palette: {
    primary: COLORS.blue,
    secondary: COLORS.grey[100],
    grey: COLORS.grey,
    error: COLORS.error[70],
    success: COLORS.green[90],
    alphaBlack: COLORS.alphaBlack,
    cellHightLight: COLORS.blue[30],
    background: {
      primary: COLORS.blue[50],
      secondary: COLORS.grey[0],
      hightLight: COLORS.grey[0],
      transparently: COLORS.alphaWhite[60],
      rateLine: COLORS.grey[10],
      footer: COLORS.grey[95],
      order: COLORS.grey[4],
      attention: COLORS.grey[5],
      specialHeader: COLORS.blue[10],
    },
    border: {
      primary: COLORS.blue[50],
      secondary: COLORS.grey[25],
      shadow: COLORS.alphaBlack[40],
      shadowStrong: COLORS.grey[70],
      special: COLORS.grey[50],
      asPrimeText: COLORS.grey[100],
      hightLight: COLORS.grey[0],
    },
    text: {
      primary: COLORS.grey[100],
      secondary: COLORS.grey[0],
      special: COLORS.grey[70],
      asTheme: COLORS.blue[30],
      hightLight: COLORS.grey[0],
      headerActive: COLORS.blue[30],
    },
    notification: {
      success: COLORS.green[3],
      error: COLORS.error[3],
      neutral: COLORS.grey[3],
    },
    nav: {
      qrBackground: COLORS.alphaWhite[80],
      buttons: {
        primary: COLORS.blue[50],
        secondary: COLORS.grey[0],
      },
    },
    face: {
      dislike: COLORS.grey[40],
    },
  },
};

export const defaultPalette: ThemePalette = {
  palette: {
    primary: COLORS.green,
    secondary: COLORS.grey[100],
    grey: COLORS.grey,
    error: COLORS.error[70],
    success: COLORS.green[90],
    cellHightLight: COLORS.green[30],
    alphaBlack: COLORS.alphaBlack,
    background: {
      primary: COLORS.green[30],
      secondary: COLORS.grey[0],
      hightLight: COLORS.grey[50],
      transparently: COLORS.alphaWhite[60],
      rateLine: COLORS.grey[10],
      footer: COLORS.grey[95],
      order: COLORS.grey[4],
      attention: COLORS.grey[5],
      specialHeader: COLORS.green[10],
    },
    border: {
      primary: COLORS.green[30],
      secondary: COLORS.grey[25],
      shadow: COLORS.alphaBlack[40],
      shadowStrong: COLORS.grey[70],
      special: COLORS.grey[50],
      asPrimeText: COLORS.grey[100],
      hightLight: COLORS.grey[0],
    },
    text: {
      primary: COLORS.grey[100],
      secondary: COLORS.grey[100],
      special: COLORS.grey[70],
      asTheme: COLORS.green[30],
      hightLight: COLORS.grey[0],
      headerActive: COLORS.green[30],
    },
    notification: {
      success: COLORS.green[3],
      error: COLORS.error[3],
      neutral: COLORS.grey[3],
    },
    nav: {
      qrBackground: COLORS.alphaWhite[80],
      buttons: {
        primary: COLORS.green[30],
        secondary: COLORS.grey[100],
      },
    },
    face: {
      dislike: COLORS.grey[40],
    },
  },
};
