import { ChangeEvent } from 'react';

import { CheckBoxProps } from './typed';

import { CheckBoxComponent, Form, Label } from './styled';

export default function CheckBox({
  isChecked,
  children,
  storageKey,
  onChangeState,
}: CheckBoxProps): JSX.Element {
  function onChangeHandle({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>): void {
    if (storageKey) {
      sessionStorage.setItem(storageKey, JSON.stringify(checked));
    }
    onChangeState(checked);
  }
  return (
    <Form>
      <CheckBoxComponent
        id="acceptance-with-rules"
        type="checkbox"
        onChange={onChangeHandle}
        checked={isChecked}
      />
      <Label htmlFor="acceptance-with-rules">{children}</Label>
    </Form>
  );
}
