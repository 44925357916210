import { useSelector } from 'react-redux';

import { LangDescriptorList } from '../dictionary';
import { selectLang } from '../store/slices/langSlice';

export function useSetHTMLlang(): void {
  const currentLang = useSelector(selectLang);

  document.body.dir = LangDescriptorList[currentLang].dir || 'ltr';
  document.documentElement.lang = LangDescriptorList[currentLang].html_code;
  document.head.lang = LangDescriptorList[currentLang].html_code;
}
