import styled, { css } from 'styled-components';

import { ReactComponent as CarSVG } from '../../../../../../assets/images/car.svg';
import { ReactComponent as MapSVG } from '../../../../../../assets/images/map.svg';
import { ReactComponent as PhoneSVG } from '../../../../../../assets/images/phone.svg';
import { ReactComponent as SizeSVG } from '../../../../../../assets/images/size.svg';
import { ReactComponent as TimeSVG } from '../../../../../../assets/images/time.svg';
import { ReactComponent as WeightSVG } from '../../../../../../assets/images/weight.svg';
import { infoTextTemplate } from '../../../../../../theme/pagesElements';

const svgFill = css`
  width: 20px;
  path {
    fill: ${({ theme }): string => theme.palette.text.asTheme};
  }
`;

export const Car = styled(CarSVG)`
  ${svgFill};
`;

export const Map = styled(MapSVG)`
  ${svgFill};
`;

export const Phone = styled(PhoneSVG)`
  ${svgFill};
`;

export const Size = styled(SizeSVG)`
  ${svgFill};
`;

export const Time = styled(TimeSVG)`
  ${svgFill};
`;

export const Weight = styled(WeightSVG)`
  ${svgFill};
`;

export const InfoGroup = styled.div`
  display: flex;
  gap: ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(3)};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoText = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const PhoneNumber = styled.a`
  color: inherit;
  text-decoration: underline;
`;
