import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../components/Button';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import {
  addClothe,
  selectAssortment,
  selectClothes,
  selectClothesVault,
  selectName,
} from '../../../../store/slices/laundrySlice';
import ClotheItem from './components/ClotheItem';

import { CommonWrapper, MainDataWrapper, Title } from '../../styled';
import { ClothesList } from './styled';

export default function Clothes(): JSX.Element {
  const clothesVault = useSelector(selectClothesVault);
  const clothes = useSelector(selectClothes);
  const assortment = useSelector(selectAssortment);
  const dispatch = useTypedDispatch();

  const isAddBtnAvailable = clothesVault.length < assortment.length;
  const isNextBtnAvailable = !!clothes.length;

  const { staticTranslate } = useTranslate();
  const { navigateWithUid } = useCustomNavigate();
  const name = useSelector(selectName);

  useEffect(() => {
    if (!name) {
      navigateWithUid('/laundry/personal-data', { replace: true });
    }
    // check once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addClotheHandler() {
    if (isAddBtnAvailable) {
      dispatch(addClothe());
    }
  }

  return (
    <>
      <CommonWrapper>
        <Title>{staticTranslate('clothesAndQuantity')}</Title>
        <MainDataWrapper>
          <ClothesList>
            {clothesVault.map((clothe) => {
              const { listItemId } = clothe;
              return <ClotheItem key={listItemId} clothe={clothe} />;
            })}
          </ClothesList>
          <Button
            isActive={isAddBtnAvailable}
            variant="outlined"
            onClick={addClotheHandler}
          >
            {staticTranslate('addClothes')}
          </Button>
        </MainDataWrapper>
      </CommonWrapper>
      <Button
        isActive={isNextBtnAvailable}
        variant="contained"
        to="/laundry/packaging-rules"
      >
        {staticTranslate('Next')}
      </Button>
    </>
  );
}
