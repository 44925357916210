import styled from 'styled-components';

import { infoTextTemplate } from '../../../../../../theme/pagesElements';

export const DeviceStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(5)};
  margin-bottom: ${({ theme }): string => theme.spacing(3.5)};
`;

export const Status = styled.div`
  display: flex;
  gap: ${({ theme }): string => theme.spacing(2)};
  align-items: center;
`;

export const StatusText = styled.span`
  ${infoTextTemplate}
  text-transform: lowercase;
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
