import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../hooks/useTranslate';
import { selectDeviceInfo } from '../../../store/slices/initialSlice';
import { selectLang } from '../../../store/slices/langSlice';
import { WorkingHours } from '../../../store/types/orderResponse';
import { DEFAULT_STATUS } from '../constants';
import { DeviceWorkData, TransformedWorkingData, WorkStatus } from '../types';

export function useGetWorkingData(): DeviceWorkData {
  const [workData, setWorkData] = useState<DeviceWorkData>({
    ...DEFAULT_STATUS,
    workSchedule: [],
  });

  const currentLang = useSelector(selectLang);
  const { staticTranslate } = useTranslate();

  const WEEKLY_DAYS = {
    1: staticTranslate('Monday'),
    2: staticTranslate('Tuesday'),
    3: staticTranslate('Wednesday'),
    4: staticTranslate('Thursday'),
    5: staticTranslate('Friday'),
    6: staticTranslate('Saturday'),
    7: staticTranslate('Sunday'),
  };

  const deviceInfo = useSelector(selectDeviceInfo);

  useEffect(() => {
    function getDeviceWorkingStatus(
      workSchedule: TransformedWorkingData[],
    ): WorkStatus {
      const today = new Date(Date.now());

      const day = today.getDay();
      const currentTime = today.toLocaleTimeString('en-GB'); // For 24h format

      const todayWorkingTime = workSchedule.find(
        ({ dayNumber }) => dayNumber === day,
      )?.time;

      if (todayWorkingTime?.length === 2) {
        const openTime = todayWorkingTime[0];
        const closeTime = todayWorkingTime[1];

        const isAvailable = currentTime >= openTime && currentTime < closeTime;

        return {
          day: WEEKLY_DAYS[day as keyof WorkingHours],
          statusValue: isAvailable
            ? staticTranslate('Open')
            : staticTranslate('Close'),
          isAvailable,
          closes: closeTime,
        };
      }

      return {
        ...DEFAULT_STATUS,
        statusValue: staticTranslate('NoData'),
      };
    }

    const workSchedule = (function transformWorkingHours() {
      if (deviceInfo.working_hours) {
        return Object.entries(deviceInfo.working_hours).reduce<
          TransformedWorkingData[]
        >((acc, [day, time]) => {
          const workingTime = time?.[0];

          if (workingTime) {
            const weeklyDay = +day as keyof WorkingHours;
            const workingData: TransformedWorkingData = {
              dayNumber: weeklyDay,
              dayName: WEEKLY_DAYS[weeklyDay],
              time: workingTime.split('-'),
            };
            acc.push(workingData);
          }

          return acc;
        }, []);
      }
    })();

    if (workSchedule?.length) {
      setWorkData({
        ...getDeviceWorkingStatus(workSchedule),
        workSchedule,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInfo, currentLang]);

  return workData;
}
