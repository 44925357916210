import { useSelector } from 'react-redux';

import WorkingHours from '../../../../../../components/WorkingHours';
import { projectIs } from '../../../../../../constants';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  selectDeviceInfo,
  selectSupportPhone,
} from '../../../../../../store/slices/initialSlice';

import {
  Car,
  InfoGroup,
  InfoText,
  Map,
  Phone,
  PhoneNumber,
  Size,
  Time,
  Weight,
} from './styled';

export default function MainInfo() {
  const supportPhone = useSelector(selectSupportPhone);
  const deviceInfo = useSelector(selectDeviceInfo);

  const { staticTranslate, dynamicTranslate } = useTranslate();

  const deviceDescription = deviceInfo.device_description;
  const deviceAddress = deviceInfo.address;

  function typeDeviceWeightLimit(): string {
    const weight = staticTranslate('Weight');
    const deviceWeightLimit = (deviceInfo.weight_limit as string).replace(
      /[^\d.\s-]/g,
      '',
    );
    const dimKg = staticTranslate('DimKG');
    return `${weight}: ${deviceWeightLimit} ${dimKg}`;
  }
  return (
    <>
      <InfoGroup role="group" aria-label="Working time">
        <WorkingHours icon={<Time role="img" />} />
      </InfoGroup>
      <InfoGroup role="group" aria-label="Location">
        <Map role="img" />
        <InfoText>
          {dynamicTranslate(deviceAddress)}{' '}
          {dynamicTranslate(deviceDescription)}
        </InfoText>
      </InfoGroup>
      {deviceInfo.station && (
        <InfoGroup role="group" aria-label="Nearest station">
          <Car role="img" />
          <InfoText>{dynamicTranslate(deviceInfo.station)}</InfoText>
        </InfoGroup>
      )}
      {deviceInfo.weight_limit && (
        <InfoGroup role="group" aria-label="Weight information">
          <Weight role="img" />
          <InfoText>{typeDeviceWeightLimit()}</InfoText>
        </InfoGroup>
      )}
      <InfoGroup role="group" aria-label="Sizes information">
        <Size role="img" />
        <InfoText>
          {staticTranslate('MaxSize')}: {deviceInfo.max_cell_size.width}x
          {deviceInfo.max_cell_size.height}x{deviceInfo.max_cell_size.depth}{' '}
          {staticTranslate('DimSM')}
        </InfoText>
      </InfoGroup>
      {supportPhone && !projectIs.omis && (
        <InfoGroup role="group" aria-label="Support phone">
          <Phone role="img" />
          <InfoText>
            <PhoneNumber
              href={`tel:${supportPhone}`}
              className="device-info__details__number"
            >
              {supportPhone}
            </PhoneNumber>
          </InfoText>
        </InfoGroup>
      )}
    </>
  );
}
