import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import { Images } from '../../../../components/Images';
import { selectDeviceInfo } from '../../../../store/slices/initialSlice';

import 'slick-carousel/slick/slick.css';

import {
  LeftArrow,
  LeftArrowWrapper,
  RightArrow,
  RightArrowWrapper,
  Slide,
  SliderWrapper,
} from './styled';

export default function ImageSlider(): JSX.Element {
  const deviceInfo = useSelector(selectDeviceInfo);

  const testImages = [
    Images.PostamatTest.Image,
    Images.PostamatTest.Image1,
    Images.PostamatTest.Image2,
    Images.PostamatTest.Image3,
  ];

  const images =
    deviceInfo && deviceInfo?.images.length ? deviceInfo.images : testImages;

  const renderSlides = () =>
    images.map((item) => <Slide key={item} src={item} alt="Postamat" />);

  return (
    <SliderWrapper aria-label="Image slider">
      <Slider
        nextArrow={
          <RightArrowWrapper aria-label="Next slide button">
            <RightArrow role="img" />
          </RightArrowWrapper>
        }
        prevArrow={
          <LeftArrowWrapper aria-label="Prev slide button">
            <LeftArrow role="img" />
          </LeftArrowWrapper>
        }
      >
        {renderSlides()}
      </Slider>
    </SliderWrapper>
  );
}
