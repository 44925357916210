import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { ReactComponent as NavBackSVG } from '../../assets/images/navBack.svg';
import { ReactComponent as NavHomeSVG } from '../../assets/images/navHome.svg';
import { NavBG, NavChildrenProps, NavProps, PopupButtonProps } from './types';

function applyNavBg({ bgType, theme }: NavProps): string {
  switch (bgType) {
    case NavBG.fullColor:
      return theme.palette.background.primary;
    case NavBG.opacity:
      return theme.palette.nav.qrBackground;
    default:
      return theme.palette.background.secondary;
  }
}

function applyTitleColor({ isSecondary, theme }: NavChildrenProps): string {
  return isSecondary
    ? theme.palette.text.secondary
    : theme.palette.text.primary;
}

function applySVGColor({ isSecondary, theme }: NavChildrenProps): string {
  return isSecondary
    ? theme.palette.nav.buttons.secondary
    : theme.palette.nav.buttons.primary;
}

function applyButtonStyle({
  isPressed,
}: PopupButtonProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return isPressed
    ? css`
        opacity: 0.6;
        box-shadow: inset 0 0 6px
          ${({ theme }): string => theme.palette.border.shadowStrong};
      `
    : css`
        opacity: 1;
        box-shadow: 0 0 6px
          ${({ theme }): string => theme.palette.border.shadowStrong};
      `;
}
export const Nav = styled.nav`
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${applyNavBg};
  z-index: 1;
`;

export const NavBorder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 25px;
  margin: 0 ${({ theme }): string => theme.spacing(2.5)};
  z-index: 1;
`;

export const NavTitle = styled.h2`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  font-size: 0.8em;
  flex-grow: 1;
  text-align: center;
  color: ${applyTitleColor};
`;

export const NavHome = styled(NavHomeSVG).withConfig({
  shouldForwardProp: (prop) => !'isSecondary'.includes(prop),
})`
  position: absolute;
  right: 0;
  path {
    stroke: ${applySVGColor};
  }
`;

export const NavBack = styled(NavBackSVG).withConfig({
  shouldForwardProp: (prop) => !'isSecondary'.includes(prop),
})`
  position: absolute;
  left: 0;
  path {
    stroke: ${applySVGColor};
  }
`;

export const InfoContainer = styled.div``;

export const InfoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  min-height: 35px;
  min-width: 35px;
  border-radius: 50%;
  border: none;
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${applyButtonStyle};
`;

export const InfoButtonCenter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 14px;
  font-family: ${({ theme }): string => theme.typography.fontFamily.font1};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[600]};
  color: ${({ theme }): string => theme.palette.text.primary};
  border: 2px solid ${({ theme }): string => theme.palette.border.asPrimeText};
  border-radius: 50%;
`;
