import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HEIGHT_OF_HEADER_BUTTONS = '270px';

export const OrdersWrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  padding-bottom: ${({ theme }): string => theme.spacing()};
  gap: ${({ theme }): string => theme.spacing()};
  max-height: calc(100vh - ${HEIGHT_OF_HEADER_BUTTONS});
  overflow-y: auto;
`;

export const Order = styled(Link)`
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }): string => theme.palette.background.order};
  border: 1px solid ${({ theme }): string => theme.palette.border.primary};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};

  width: 100%;
  min-height: 72px;

  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  text-transform: uppercase;
`;
