import styled from 'styled-components';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  ${stackCellCommon};

  &:first-child {
    grid-column: 1 / 3;
    grid-template: 1;
  }

  &:last-child {
    grid-column: 1 / 3;
    grid-template: 10;
  }
`;

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: grid;
  column-gap: 1px;
  row-gap: 2px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20% 12% repeat(3, 9%) repeat(4, 6.5%) auto;
`;
