import { useSelector } from 'react-redux';

import InfoPageTemplate from '../../../../components/InfoPageTemplate';
import { selectUid } from '../../../../store/slices/initialSlice';

import {
  A,
  ContentWindow,
  H1,
  H2,
  Li,
  Paragraph,
  Ul,
} from '../../../../components/InfoPageTemplate/styled';

export default function LaundryOffer(): JSX.Element {
  const uid = useSelector(selectUid);
  const location = `${window.location.origin.toString()}`;
  const URL = `${location}/#/device/${uid}`;
  return (
    <InfoPageTemplate>
      <ContentWindow aria-label="Laundry offer">
        <H1>
          Договір публічної оферти користування послугами хімчистки за допомогою
          поштоматів
        </H1>
        <Paragraph>
          Фізична особа-підприємець Гончар Олег Леонідович, що діє на підставі
          Виписки з Єдиного державного реєстру юридичних осіб, фізичних осіб-
          підприємців та громадських формувань, іменоване надалі «Виконавець»
          публікує даний договір, що є публічним договором-офертою (далі —
          Договір оферти) і має відповідну юридичну силу, про надання послуг
          (виконання робіт), відображених на офіційному інтернет-сайті
          Виконавця, на адресу фізичних осіб (далі — Замовник).
        </Paragraph>
        <H2>1. Загальні положення</H2>
        <Paragraph>
          1.1. Даний Договір оферти є публічним, відповідно до ст. ст. 633, 641
          Цивільного кодексу України, та його умови є однакові для всіх
          Замовників, беззастережне прийняття яких вважається акцептуванням цієї
          оферти Замовником, для чого Виконавець публікує цей Договір.
        </Paragraph>
        <Paragraph>
          1.2. Використання послуг, що надаються Виконавцем, передбачає згоду
          особи, що користується послугами, з правилами, строками, обмеженнями
          та іншими умовами співпраці, викладеними в даному документі.
        </Paragraph>
        <Paragraph>
          1.3. Приймаючи умови даного Договору оферти, Замовник підтверджує, що:
        </Paragraph>
        <Ul>
          <Li>
            на момент оформлення замовлення послуг йому виповнилось 18 років;
          </Li>
          <Li>
            інформація, що надається під час замовлення послуг, є правильною та
            актуальною;
          </Li>
          <Li>
            надана особиста та контактна інформація може бути використана з
            метою повідомлення про зміни в роботі, акційні пропозиції чи надання
            іншої інформації, пов’язаної з діяльністю Виконавця.
          </Li>
        </Ul>
        <Paragraph>
          1.4. Підтвердженням повного та безумовного акцептування Замовником
          даного Договору є одна з таких дій з боку Замовника:
        </Paragraph>
        <Ul>
          <Li>
            внесення Замовником плати або часткової плати за замовлені Послуги,
            що свідчить про прийняття ним публічної оферти;
          </Li>
          <Li>
            передача речей/виробів Замовником Виконавцю за допомогою поштомату
            задля виконання Послуг;
          </Li>
          <Li>
            заповнення електронної форми Замовником на веб-сайті Виконавця{' '}
            <A href={URL} target="_blanc">
              {URL}
            </A>
          </Li>
        </Ul>
        <Paragraph>
          1.5. Договір оферти вважається укладеним з боку Замовника без його
          подальшого підписання з моменту надання інформації про себе
          (Замовника) після просканування QR коду , розміщеного на поштоматі (що
          використовується для отримання та видачі замовлення), отримання
          Виконавцем оплати Замовником замовлених Послуг чи вчинення інших дій,
          передбачених Договором, що свідчать про згоду дотримуватися умов
          Договору, без підписання письмового примірника договору чи будь-якого
          іншого документу Сторонами.
        </Paragraph>
        <Paragraph>
          1.6. Замовник, користуючись послугами Виконавця, дає безумовну згоду
          дотримуватися умов даного Договору та згоду отримати Послуги на
          встановлених Виконавцем умовах ( в т.ч. розміщених за посиланнями,
          вказаними в даному Договорі) з оплати замовлених Послуг.
        </Paragraph>
        <Paragraph>
          1.7. Виконавець має право змінити або доповнити даний Договір оферти в
          будь-який момент як з повідомленням Замовників, так і без нього. Чинна
          редакція завжди знаходиться на веб-сайті Виконавця{' '}
          <A href={URL} target="_blanc">
            {URL}
          </A>
        </Paragraph>
        <H2>2. Терміни, що використовуються в цьому Договорі</H2>
        <Paragraph>
          2.1. Договір оферти — публічна пропозиція Виконавця, адресована
          будь-якій фізичній особі з метою укласти договір надання послуг на
          існуючих умовах, зазначених у Договорі оферти та за усіма посиланнями,
          які вказані в даному Договорі оферти.
        </Paragraph>
        <Paragraph>
          2.2. Акцепт - надання Замовником повної й безумовної згоди на
          укладення даного Договору в повному обсязі, без підпису письмового
          примірника Договору Сторонами.
        </Paragraph>
        <Paragraph>
          2.3. Замовник - будь-яка дієздатна фізична особа, що вступила в
          договірні відносини з Виконавцем на умовах, зазначених у Договорі
          оферти.
        </Paragraph>
        <Paragraph>
          2.4. Виконавець –фізична особа ( та усі її представники) , що надають
          послуги (виконує роботи), представлені (відображені) в на
          інтернет-сайті Виконавця, але не обмежені тільки сайтом.
        </Paragraph>
        <Paragraph>
          2.5. Послуги – послуга або декілька послуг, що надаються Виконавцем
          Замовнику з асортиментного переліку послуг, вказані Замовником при
          розміщенні заявки на інтернет-сайті Виконавця або замовлені будь-яким
          іншим способом.
        </Paragraph>
        <Paragraph>
          2.6. Речі/вироби – це предмети (одяг, взуття, сумки) виготовлені з
          текстилю, шкіри, хутра та/або інших матеріалів, пір’єві вироби (крім
          подушок), предмети домашнього вжитку.
        </Paragraph>
        <Paragraph>
          2.7. Доставка за допомогою поштомату — безпосередня передача
          речей/виробів Замовника Виконавцю шляхом його поміщення в поштомат
          Виконавця і у зворотній бік.
        </Paragraph>
        <Paragraph>
          2.8. Сайт — веб-сторінка Виконавця{' '}
          <A href={URL} target="_blanc">
            {URL}
          </A>
        </Paragraph>
        <Paragraph>
          2.9. Поштомат – автономний пункт прийому речей для надання Послуг та
          видачі речей після надання Послуг, з комірками для прийому замовлень
          та для їх видачі.
        </Paragraph>
        <Paragraph>
          2.10. Інструкція - правила, що при роботі з поштоматом встановлюють
          послідовність дій Замовника для отримання мішка, здачі та отримання
          речей.
        </Paragraph>
        <Paragraph>
          2.11. Ідентифікація — здійснюється шляхом введення на клавіатурі
          поштомату, або в Особистому кабінеті, номера телефону та пароля,
          отриманого Замовником під час реєстрації та дозволяє однозначно
          ідентифікувати Замовника.
        </Paragraph>
        <H2>3. Предмет Договору</H2>
        <Paragraph>
          3.1. Виконавець зобов’язується надати Послуги із хімчистки, прання -
          та інші супутні послуги з переліку послуг, вказаних на сайті
          Виконавця, а Замовник прийняти та оплатити такі Послуги, на умовах
          цього Договору.
        </Paragraph>
        <Paragraph>
          3.2. Цей Договір оферти є офіційним документом Виконавця та
          невід’ємною частиною Оферти.
        </Paragraph>
        <H2>4. Порядок оформлення замовлення через поштомат</H2>
        <Paragraph>
          4.1. Замовник отримує доступ до поштомата для оформлення замовлення
          після реєстрації/ідентифікації, що відбувається за номером телефону,
          паролем та/або смс кодом.
        </Paragraph>
        <Paragraph>
          4.2. Для вибору відповідного типу операції Замовнику необхідно
          дотримуватись інструкції та скористатись веб-сторінкою.
        </Paragraph>
        <Paragraph>
          4.3. Замовник передає речі Виконавцю шляхом їх закладки в комірку
          поштомата в спеціальному мішку із стяжкою; при цьому Замовник перед
          поштоматом (в зоні дії камери відеофіксації поштомату) робить дії в
          такому порядку:
        </Paragraph>
        <Ul>
          <Li>взяти пакет зі стелажа коло поштомату</Li>
          <Li>покласти речі в пакет</Li>
          <Li>зірвати клейку пломбу з пакета</Li>
          <Li>зав’язати пакет з речами на зав’язки</Li>
          <Li>клейкою пломбою обв’язати пакет навкого зав’язок</Li>
          <Li>покласти пакет в комірку поштомата</Li>
        </Ul>
        <Paragraph>
          Після закладки мішка в поштомат, Замовнику надходить підтвердження про
          прийняття речей.
        </Paragraph>
        <Paragraph>
          4.4. Поміщені Замовником в комірку поштомата речі вилучаються з
          поштомата представником Виконавця, доставляються у відділення
          Виконавця для їх оформлення та подальшого надання Послуг, після чого
          речі після надання Послуг (та/чи надання інших супутніх замовлених
          Замовником послуг) повертаються назад в поштомат для їх видачі
          Замовнику.
        </Paragraph>
        <Paragraph>
          4.5. Доставлений Виконавцю опломбований Замовником мішок
          розкривається, кожна річ оглядається з метою визначення можливості її
          обробки, за допомогою фото-та відеозйомки фіксуються: зовнішній стан
          речей, наявні дефекти речей, ступінь зносу, дефекти (плями), які
          неможливо усунути, тощо. Після цього представником Виконавця
          погоджується (по даних № телефону) із Замовником види Послуг, що ним
          замовляються, та їх ціна, вартість прийнятих речей та строки виконання
          замовлення. Телефонна розмова щодо такого погодження записується, на
          що прийняттям даної Оферти дають попередню згоду обидві сторони і
          запис зберігається протягом 3 (трьох) місяців після виконання
          замовлення, а у випадку наявності спору – до врегулювання спору.
        </Paragraph>
        <Paragraph>
          4.6. Всі погоджені із Замовником деталі замовлення, зазначені в п.
          4.5. цього Договору оферти, фіксуються Виконавцем шляхом оформлення
          квитанції, яка є договором про надання Послуг між Виконавцем та
          Замовником. Замовник зобов'язаний докладно і точно викласти всі свої
          побажання і вимоги, щоб уникнути непорозумінь в квитанції. У разі
          невиконання цієї умови, претензії до результатів надання Послуг не
          приймаються, за умови, що Послуги надані якісно.
        </Paragraph>
        <Paragraph>
          4.7. Після оформлення квитанції. Виконавець надсилає Замовнику
          повідомлення на мобільний телефон чи адресу електронної пошти про
          необхідність ознайомлення із змістом квитанції. Повідомлення може бути
          надіслано у вигляді QR коду чи посилання , за яким Замовник отримує
          відповідну інформацію після відсканування чи переходу по ньому.
        </Paragraph>
        <Paragraph>
          4.8. Замовник розуміє, що строк виконання замовлення може бути змінено
          чи подовжено через їх складність в залежності від стану виробу, або
          властивостей матеріалів виробу, або технологічних аспектів (в т.ч.
          наявність електроенергії) та приймає обґрунтування Виконавця. Замовник
          розуміє та погоджується, що ціна Послуг, вказана на сайті Виконавця,
          не є остаточною, а є орієнтовною і залежить від ступеня забруднення,
          пошкодження, складності робіт, матеріалу, з якого виготовлена річ та
          інших особливостей речі.
        </Paragraph>
        <Paragraph>
          4.9. Якщо під час надання Послуг виникає необхідність у додаткових
          послугах, що не були передбачені умовами Договору про надання Послуг
          (квитанцією), Виконавець зобов'язаний одержати від споживача
          погодження на надання таких послуг в порядку, визначеному цим
          Договором оферти. У разі надання Виконавцем додаткових послуг, не
          передбачених замовленням, їх ціна оплачується Замовником за цінами, що
          діяли на час оформлення замовлення на ці послуги за погодженням із
          Виконавцем.
        </Paragraph>
        <Paragraph>
          4.10. Виконавець має право на відмову у прийманні замовлення на
          надання Послуг, якщо він не може забезпечити належне їх виконання.
        </Paragraph>
        <Paragraph>
          4.11. Замовник погоджується з тим, що для надання Послуг Виконавець
          має право залучати до виконання замовлення будь-яких третіх осіб на
          свій розсуд та за своїм вибором, залишаючись при цьому відповідальним
          перед Замовником за належне надання Послуг.
        </Paragraph>
        <Paragraph>4.12. Хімічному чищенню та пранню не підлягають:</Paragraph>
        <Ul>
          <Li>вироби із плащових тканин з поліуретановим покриттям;</Li>
          <Li>
            вироби із відходів натурального хутра і шкіри, а також які
            дубльовані клейовим методом;
          </Li>
          <Li>
            хутряні виробі кустарного вичинки з ламучою шкіряною тканиною;
          </Li>
          <Li>килими, килимові вироби, покриття для підлоги та паласи;</Li>
          <Li>виробі із слабким трафаретним малюнком;</Li>
          <Li>виробі старі, пошкоджені міллю із зносом більше 75%.</Li>
        </Ul>
        <Paragraph>
          4.13. Для виробів, що приймаються в хімічне чищення, установлено
          чотири ступені зносу: 10, 30, 50 та 75 відсотків:
        </Paragraph>
        <Ul>
          <Li>
            знос 10 відсотків - вироби, які не були в ужитку і на довгому
            зберіганні, не мають дефектів виготовлення, пошкодження волокон і
            забарвлення;
          </Li>
          <Li>
            знос 30 відсотків - вироби малозношені із незначним забрудненням та
            заплямуванням, які не піддавались пранню, хімічній чистці або
            перефарбуванню і не змінили свого забарвлення, не мають пошкодження
            волокон і поривів; відрізи тканин з незначними порізами біля кромки
            або іншими невеликими пошкодженнями;
          </Li>
          <Li>
            знос 50 відсотків - вироби ношені, прані, чищені, перефарбовані,
            вигорілі; із збитим ворсом, потерті, із спущеними петлями; з
            невеликими поривами та штопанням; із значним забрудненням і
            заплямуванням, які мають зачіпки, незначні припали; відрізи тканин
            із забрудненням, заплямуванням або незначними пошкодженнями
            всередині відрізу; вироби з тканин, дубльованих пінополіуретаном, що
            мають заломи на ліктьових згинах; килимові вироби, що мають місця з
            пошкодженням забарвлення, обтріпані краї; гардинно-тюлеві вироби,
            які мають незначне пошкодження волокон;
          </Li>
          <Li>
            знос 75 відсотків - вироби дуже забруднені, що піддавалися пранню,
            хімічній чистці, перефарбуванню, реставровані, мають витертість і
            вигорілі місця, припали, штопання, розриви, спущені петлі, плями, що
            зіпсували колір і волокна тканини, незначні пошкодження міллю,
            зваляний волосяний покров, пошкодження шкіряної тканини; вироби із
            тканин, дубльованих пінополіуретаном, які мають відшарування або
            роздуті місця.
          </Li>
        </Ul>
        <Paragraph>
          4.14. Для виробів, які приймаються для прання, установлюються чотири
          ступені зносу – 20, 40, 60 та 80 відсотків:
        </Paragraph>
        <Ul>
          <Li>
            знос 20 відсотків - білизна що не піддавалася пранню, не має
            дефектів при виготовленні, пошкоджень волокон та забарвлення, але
            була в ужитку;
          </Li>
          <Li>
            знос 40 відсотків – білизна, що була в ужитку і піддавалася пранню,
            але не має плям, пошкоджень волокон та забарвлення;
          </Li>
          <Li>
            знос 60 відсотків – білизна прана, має плями і незначні пошкодження
            від механічного впливу від механічного впливу, а також припали;
          </Li>
          <Li>
            знос 80 відсотків – білизна стара, має припали, пориви і плями.
          </Li>
        </Ul>
        <Paragraph>
          4.15. Замовник має право анулювати замовлення і розірвати договір про
          надання Послуг з власної ініціативи до моменту виконання Виконавцем
          його замовлення. При цьому Замовник оплачує Виконавцю вартість наданих
          Послуг і відшкодовує йому збитки, заподіяні розірванням договору про
          надання Послуг.
        </Paragraph>
        <H2>5. Ціна Послуг та та порядок розрахунків</H2>
        <Paragraph>
          5.1. Ціна Послуг визначається на основі прейскуранту Виконавця станом
          на дату оформлення замовлення , що вказаний за посиланням{' '}
          <A
            href="https://ua.unmomento.com.ua/tseny/"
            target="_blank"
            title="UNMOMENTO prices"
          >
            https://ua.unmomento.com.ua/tseny/
          </A>
          .
        </Paragraph>
        <Paragraph>
          5.2. Ціна Послуг може відрізнятися від цін, що вказані у прейскуранті,
          залежно від складності роботи, матеріалів, ступеня забруднення/зносу
          та інших факторів. Конкретна ціна певного замовлення формується
          представником Виконавця після погодженняіз Замовником.
        </Paragraph>
        <Paragraph>
          5.3. Замовник оплачує Послуги Виконавцю на підставі цього Договору та
          квитанції у національній валюті України – гривні – безготівковим
          способом через сервіс{' '}
          <A href="https://www.liqpay.ua/" target="_blank" title="Liqpay">
            https://www.liqpay.ua/
          </A>
          .
        </Paragraph>
        <Paragraph>
          5.4. Замовник зобов’язаний повністю оплатити ціну наданих Виконавцем
          Послуг згідно з умовами цього Договору оферти та квитанції перед
          отриманням речей з поштомату.
        </Paragraph>
        <H2>6. Порядок приймання-передачі наданих Послуг через поштомат</H2>
        <Paragraph>
          6.1. Замовник отримує речі від Виконавця після надання Послуг через
          поштомат після отримання на мобільний телефон SMS-повідомлення про
          готовність замовлення до видачі. Для отримання речей Замовник
          проходить Ідентифікацію та вибирає відповідний тип дії на клавіатурі
          поштомата. Замовник оплачує замовлення електронним платежем. Отримання
          речей Замовником можливе лише після проведення повної оплати Послуг
          Виконавця. При отриманні речей в комірці поштомата – Замовник
          зобов’язується негайно пересвідчитися щодо якості наданої послуги та
          її відповідності замовленню (належність речей Замовнику, кількість
          речей, якість послуги та ін.), що фіксується на камеру відео
          спостереження поштомату. У випадку наявності у Замовника будь-яких
          претензій щодо речей чи якості Послуги – він зобов’язаний негайно про
          це повідомити Виконавця шляхом дзвінка за № моб.тел.{' '}
          <A href="tel:380635627172">+380635627172</A>. Будь-які претензії
          Замовника щодо кількості речей, якості Послуги та її відповідності
          замовленню, що висловлені ним не одразу при прийманні замовлення
          (тобто, після того, як Замовник відійшов від поштомату) – не
          приймаються , за винятком умов гарантійного строку на надані Послуги,
          визначеного даним Договором оферти.
        </Paragraph>
        <Paragraph>
          6.2. Замовник зобов’язаний забрати готове замовлення у поштоматі
          протягом 3 календарних днів з дня отримання дзвінка чи повідомлення
          від Виконавця про готовність замовлення, або повідомити Виконавця про
          обставини, що не дають можливості забрати замовлення у вказаний термін
          та погодити новий термін отримання замовлення.
        </Paragraph>
        <Paragraph>
          6.3. У разі, якщо Замовник не отримав замовленням у зазначений термін
          та не попередив Виконавця про обставини, що впливають на можливість
          звернутися у вказаний термін, за кожен день такого прострочення
          додається тариф 50 (п’ятдесят) гривень до загальної вартості
          замовлення. Загальну суму за прострочення терміну забору замовлення
          Замовник сплачує Виконавцю під час отримання замовлення.
        </Paragraph>
        <Paragraph>
          6.4. У разі неявки Замовника за отриманням виробу протягом 6 місяців –
          даний виріб переходить у власність Замовника і його вартість
          вважається платою за надану послугу та зберігання протягом 6 міс.;
          Виконавець має право через шість місяців після визначеного договором
          про надання послуг (квитанцією) терміну виконання замовлення і після
          дворазового нагадування на наявну у Виконавця адресу електронної пошти
          чи номер телефона Замовника (з підтвердженням про це) реалізувати
          виріб чи розпорядитися виробом будь-яким іншим чином на власний
          розсуд.
        </Paragraph>
        <H2>7. Права та обов'язки Сторін</H2>
        <Paragraph>7.1. Виконавець зобов'язаний забезпечити:</Paragraph>
        <Ul>
          <Li>
            надання Послуг відповідно до вимог нормативно-правових актів, умов
            договору про надання Послуг (квитанції) та цього Договору оферти;
          </Li>
          <Li>виконання гарантійних зобов'язань;</Li>
          <Li>дотримання термінів виконання замовлення;</Li>
          <Li>
            надання Замовникові повної, доступної та достовірної інформації про
            Послуги;
          </Li>
          <Li>збереження прийнятих від Замовника для надання Послуги речей;</Li>
          <Li>
            відшкодування збитків, заподіяних Замовнику невиконанням або
            неналежним виконанням умов угоди, а також у разі втрати, псування чи
            пошкодження із своєї вини речей, прийнятих від Замовника для надання
            Послуг, у розмірах, передбачених договором про надання Послуг;
          </Li>
          <Li>
            попередження Замовника про незалежні від Виконавця обставини, що
            можуть погіршувати споживчі властивості замовлення.
          </Li>
        </Ul>
        <Paragraph>
          7.2. Виконавець зобов'язаний негайно попередити Замовника про
          призупинення надання Послуг до отримання від Замовника подальших
          вказівок при виявленні:
        </Paragraph>
        <Ul>
          <Li>
            непридатності до обробки або недоброякісності переданого Замовником
            виробу;
          </Li>
          <Li>
            можливих несприятливих для Замовника наслідків виконання його
            вказівок про спосіб надання Послуги;
          </Li>
          <Li>
            інших незалежних від Виконавця обставин, які загрожують придатності
            або цілісності результатів Послуги, що надається, або створюють
            неможливість її завершення в строк. Попередження здійснюється шляхом
            дзвінка представника Виконавця Замовникові на вказаний останнім №
            телефону.
          </Li>
        </Ul>
        <Paragraph>
          7.3. Виконавець приступає до надання Послуг тільки після повного
          узгодження із Замовником всіх деталей замовлення, зазначених в п. 4.5.
          цього Договору оферти та після погодження Замовником квитанції.
        </Paragraph>
        <Paragraph>7.4. Виконавець має право:</Paragraph>
        <Ul>
          <Li>
            не приступати до роботи, призупинити її, якщо Замовник порушує свої
            обов'язки за цим Договором оферти та договором про надання Послуг, а
            також вимагати відшкодування збитків;
          </Li>
          <Li>
            утримати (не передавати) результат надання Послуг при невиконанні
            Замовником обов'язку оплатити надані Виконавцем Послуги;
          </Li>
          <Li>
            в разі, якщо після узгодження із з Замовником всіх деталей
            замовлення, зазначених в п. 4.5. цього Договору оферти та після
            погодження Замовником квитанції , Замовник змінить думку або
            несвоєчасно сповістить про бажані зміни, вимагати оплати вже наданих
            ним Послуг.
          </Li>
        </Ul>
        <Paragraph>
          7.5. Виконавець має право відмовитися від виконання договору про
          надання Послуг та цього Договору оферти у разі, коли Замовник,
          незважаючи на своєчасне і обґрунтоване попередження, не змінить
          вказівок про спосіб виконання Послуги.
        </Paragraph>
        <Paragraph>
          7.6. У разі невиконання або неналежного виконання обов'язків,
          визначених цим Договором оферти та договором про надання Послуг
          (квитанції), Виконавець несе відповідальність у порядку, передбаченому
          законодавством, цим Договором оферти та договором про надання Послуг
          (квитанцією).
        </Paragraph>
        <Paragraph>
          7.7. Виконавець звільняється від відповідальності за повне або
          часткове пошкодження речей/виробів, прийнятих ним від Замовника, якщо
          Замовник попереджений Виконавцем про особливі властивості
          речей/виробів (дефекти), які можуть негативно вплинути на їхго стан
          під час надання Послуг. Незнання Замовником особливих властивостей
          речей/виробів не звільняє його від відповідальності.
        </Paragraph>
        <Paragraph>
          7.8. Виконавець не несе відповідальності за неналежну експлуатацію та
          неправильний догляд за речами/виробами Замовником після отримання
          замовлення, а також за можливі наслідки псування речей/виробів через
          вплив зовнішніх факторів як навмисних, так і не навмисних (дощ, град,
          сонце, механічні пошкодження, прання у пральній машинці, сушка біля
          обігрівачів тощо) після здачі замовлення Замовнику. В тому числі
          Виконавець не несе відповідальність за можливі дефекти фурнітури, що
          встановлюється під час ремонту за погодженням з Замовником, тому що не
          є виробником фурнітури з металу чи пластику.
        </Paragraph>
        <Paragraph>
          7.9. Виконавець не несе відповідальності за недоліки у наданих
          Послугах, якщо доведе, що вони виникли не з його вини.
        </Paragraph>
        <Paragraph>
          7.10. Претензії до якості виконання замовлення не задовольняються у
          разі, якщо Замовник не підтвердить своїх вимог.
        </Paragraph>
        <Paragraph>
          7.11. Замовник зобов'язується надати Виконавцю всю необхідну
          інформацію про бажаний результат Послуг, що замовляються.
        </Paragraph>
        <Paragraph>
          7.12. Замовник має право на повну, доступну та достовірну інформацію
          про послуги і Виконавця, безпеку послуг для його життя, здоров’я,
          майна та навколишнього середовища, перевірку якості наданої йому
          Послуги.
        </Paragraph>
        <Paragraph>
          7.13. У разі невиконання або неналежного виконання Виконавцем умов
          договору про надання Послуг (квитанції) Замовник користується правами,
          наданими законодавством.
        </Paragraph>
        <H2>8. Особливі попередження щодо надання Послуг</H2>
        <Paragraph>
          8.1. Вироби, на яких відсутнє маркування виробника, або маркуванням
          виробника заборонене хімічне чищення та прання, приймаються без
          гарантії якості виконання замовлення. За можливі наслідки надання
          Послуг претензії пред'являються до виробника речей/виробів або до
          продавця таких речей/вирробів. Відповідальність за шкоду, заподіяну
          Замовнику внаслідок неповної та/або недостовірної інформації на
          маркуванні, лежить на продавцеві або виробникові речей/виробів.
          Замовник погоджується з тим, що можлива втрата товарного вигляду
          речей/виробів у зв'язку з проявом прихованих дефектівне є виною
          Виконавця.
        </Paragraph>
        <Paragraph>
          8.2. Виконавець попереджає Замовника про те, що плями і затіки від
          фарб, духів, ліків, крові, чорнил, жиру, олії, клею , лаку,
          дезодоранту, вина, плісняви, іржі та іншого, а такожзастарілі плями,
          закріплені самостійним обробленням в домашніх умовах, погано
          піддаються виведенню. Речі/вироби з такими плямами приймаються без
          гарантії виведення плям.
        </Paragraph>
        <Paragraph>
          8.3. Після обробки виробів можлива їх усадка та зміна лінійних
          розмірів.
        </Paragraph>
        <Paragraph>
          8.4. Виконавець Послуг не несе відповідальності за наявність на
          виробах після обробки дефектів, що були виявлені під час оформлення
          замовлення та позначені в квитанції, та прихованих дефектів, що
          виникли при виготовленні виробу чи в процесі носіння та експлуатації
          виробу, і виявлені після обробки, а саме:
        </Paragraph>
        <Ul>
          <Li>
            закріплені плями і затіки від фарб, духів, ліків, крові, чорнил,
            жиру, олії, клею , лаку, плісняви, іржі та інші;
          </Li>
          <Li>
            зміну інтенсивності кольору чи забарвлення, зрив кольору барвника,
            зміну форми малюнка, білястість, пожовтіння, вицвітання, зблякнення,
            витерті та витравлені місця, утворені від дії поту, хімікатів,
            світла і тривалого тертя в процесі носіння;
          </Li>
          <Li>
            точкові дірки, які утворилися на виробах від механічних пошкоджень,
            попадання іскри та гарячого попілу, дії хімікатів чи молі;
          </Li>
          <Li>
            полиск, приховані та явні запали, які виникли від порушення режиму
            прасування в домашніх умовах;
          </Li>
          <Li>
            відшарування та деформація внутрішньої клейової прокладки на
            комірах, манжетах, бортах;
          </Li>
          <Li>
            спуск, витягнутість петель, затягнення нитки, обтріпування на кінцях
            виробів, дірок, петель, розтягування, перкіс виробів або окремих
            деталей;
          </Li>
          <Li>
            блиск, пілінг, заломи, зморщування виробів через нерівномірності
            усадки змішаних ниток;
          </Li>
          <Li>ослаблення тканини в місцях запалів та потертих місцях;</Li>
          <Li>порушення стійкості складок;</Li>
          <Li>руйнування металізованої нитки або їх потьмяніння;</Li>
          <Li>подріблення пуху і пера перохпухових виробів;</Li>
          <Li>
            утворення грудок пуху та зсування його у виробах з пуховим
            утеплювачем;{' '}
          </Li>
          <Li>місцеві руйнування та пожовтіння гардинно-тюлевих виробів;</Li>
          <Li>втрата об’ємності виробів на синтепоні;</Li>
          <Li>
            незначне потемніння білого кольору в комбінованих виробах чорного та
            білого кольорів;
          </Li>
          <Li>випадання ослаблених зубців на застібках-блискавках;</Li>
          <Li>
            порідіння ворсу, зваляність, розшарування, прим’ятість, відшарування
            ворсу, витерті ділянки виробу;
          </Li>
          <Li>
            різнотонність, збільшення жорсткості шкіри і шкіряної тканини,
            деформацію на виробах із натурального та штучного хутра, замші,
            велюру та шкіри;
          </Li>
          <Li>
            часткове вимивання латексу, слід просоченого клею, місцеві здутини,
            випнуті ділянки на виробах дубльованих матеріалах, виробах із шкіри
            та дублянках;
          </Li>
          <Li>вади шкіри;</Li>
          <Li>зняття плівкового покриття на виробах із шкіри та дублянках;</Li>
          <Li>
            пошкодження та зрив плівкового покриття (крек, пропитка, наппатон,
            нуплин, наппалан тощо), а дефекти шкіри, приховані під таким
            покриттям.
          </Li>
        </Ul>
        <Paragraph>
          8.5. При наявності на виробі незнімної фурнітури, матеріалів
          оздоблення і деталей з штучних матеріалів, Виконавець не несе
          відповідальності в разі їх пошкодження і втрати виробом товарного
          вигляду.
        </Paragraph>
        <Paragraph>
          8.6. Вироби після обробки повинні відповідати вимогам стандарту,
          замовленню та загальним вимогам, які висуваються до такого типу
          виробів.
        </Paragraph>
        <Paragraph>
          8.7. Зауваження щодо якості наданих Послуг можуть бути пред'явлені
          Замовником тільки в момент приймання-передачі замовлення або на
          протязі гарантійного строку. Гарантійний строк відповідності якості
          хімічної чистки та прання виробів становить 5 (п’ять) днів від дня
          видачі замовлення, за умов дотримання вимог зберігання та
          експлуатації.
        </Paragraph>
        <Paragraph>
          8.8. Виконавець не несе відповідальності за дефекти на виробах, що
          виникли з вини Замовника у період гарантійного строку.
        </Paragraph>
        <Paragraph>
          8.9. У період гарантійного строку дефекти, що виникли з вини
          Виконавця, усуваються безкоштовно.
        </Paragraph>
        <H2>9. Вирішення суперечок</H2>
        <Paragraph>
          9.1. У разі виникнення питань і претензій з боку Замовника він повинен
          звернутися до Виконавця по телефону або іншим доступним способом.
        </Paragraph>
        <Paragraph>
          9.2. Розбіжності, які можуть виникнути між Сторонами у зв'язку з
          виконанням цього Договору, вирішуються шляхом переговорів на підставі
          виставленої письмової претензії. Термін розгляду претензії – 1(один)
          місяць з дати її вручення Виконавцю.
        </Paragraph>
        <Paragraph>
          9.3. Якщо суперечка між Сторонами не буде розв’язана в претензійному
          порядку, будь-яка із Сторін має право звернутися за розв’язанням
          суперечки в суд.
        </Paragraph>
        <H2>10. Форс-мажорні обставини</H2>
        <Paragraph>
          10.1. Сторони звільняються від відповідальності за невиконання або
          неналежне виконання зобов'язань, що передбачені даним Договором, якщо
          воно виникло внаслідок форс-мажорних обставин.
        </Paragraph>
        <Paragraph>
          10.2. Під форс-мажорними обставинами у даному Договорі розуміються
          будь-які обставини, що виникли поза волею або всупереч волі чи бажанню
          Сторін і яких не можна передбачити чи уникнути, включаючи: військові
          дії, воєнний стан, надзвичайний стан, громадські заворушення,
          епідемії, блокаду, землетруси, повені, пожежі, блекнути, перебої у
          постачанні е/е, а також рішення чи приписи органів державної влади та
          управління , місцевого самоврядування, внаслідок яких на Сторони (або
          одну із Сторін) покладатимуться додаткові обов'язки чи
          встановлюватимуться додаткові обмеження і які роблять неможливим
          подальше повне або часткове належне виконання Договору, а також інші
          дії чи події, що виникли та існують поза волею Сторін.
        </Paragraph>
        <Paragraph>
          10.3. Якщо форс-мажорні обставини триватимуть більше трьох місяців
          поспіль, то кожна із Сторін матиме право відмовитись від подальшого
          виконання зобов'язань за цим Договором і, в такому разі, жодна із
          Сторін не матиме права на відшкодування іншою Стороною можливих
          збитків.
        </Paragraph>
        <H2>
          11. Конфіденційність і захист інформації (політика конфіденційності)
        </H2>
        <Paragraph>
          11.1. Персональні дані Замовнка надаються останнім та обробляються
          Виконавцем відповідно до Закону України в «Про захист персональних
          даних». Особисті дані збираються виключно з метою дотримання вимог, що
          діють у сфері регулювання податкових відносин, відносин у сфері
          бухгалтерського обліку.
        </Paragraph>
        <Paragraph>
          11.2. Сторони зобов’язані забезпечити захист персональних даних, що
          стали їм відомі з електронних документів (повідомлень) під час
          вчинення електронних правочинів, у порядку, передбаченому Законом
          України "Про захист персональних даних".
        </Paragraph>
        <Paragraph>
          11.3. При оформлення Замовлення Замовник надає Виконавцеві наступну
          персональну інформацію: прізвище, ім'я, адресу електронної пошти,
          контактний номер телефону.
        </Paragraph>
        <Paragraph>
          11.4. При оформленні Замовлення Користувач зобов’язаний вказувати
          правдиві персональні дані та несе повну відповідальність за будь-які
          помилки, невідповідності. У випадку зміни персональних даних –
          Замовник негайно повідомляє про це Виконавця і несе всі ризики
          неповідомлення (несвоєчасного повідомлення).
        </Paragraph>
        <Paragraph>
          11.5. Надаючи свої персональні дані, Замовник погоджується на їх
          обробку Виконавцем.
        </Paragraph>
        <Paragraph>
          11.6. Замовник дає Виконавцеві право здійснювати обробку його
          персональних даних, у тому числі: поміщати персональні дані в бази
          даних(без додаткового повідомлення Замовника про це), здійснювати
          довічне зберігання даних, їх накопичення, оновлення, зміну (у міру
          необхідності). Виконавець бере на себе зобов'язання забезпечити захист
          даних від несанкціонованого доступу третіх осіб, не поширювати і не
          передавати дані будь-якій третій стороні (крім передачі даних
          пов'язаним особам, комерційним партнерам, особам, уповноваженим
          Виконавцем на здійснення безпосередньої обробки даних для зазначених
          цілей, а також на обов'язковий запит компетентного державного органу).
        </Paragraph>
        <Paragraph>
          11.7. Виконавець може використовувати персональні дані Замовника:
        </Paragraph>
        <Paragraph>
          11.7.1. Для виконання своїх зобов'язань перед Замовником згідно цього
          Договору оферти та договору про надання Послуг (квитанції).
        </Paragraph>
        <Paragraph>
          11.7.2. Для здійснення підтвердження менеджером або іншою
          представником Виконавця Замовлення.
        </Paragraph>
        <Paragraph>
          11.7.3. Для відправки Замовнику Замовлення (речей після надання
          Послуг).
        </Paragraph>
        <Paragraph>
          11.8. Виконавець має право відправляти інформаційні, в тому числі
          рекламні повідомлення, на електронну пошту і мобільний телефон
          Замовника з його згоди.
        </Paragraph>
        <Paragraph>
          11.9. Сервісні повідомлення, що інформують Замовника про замовлення та
          етапах його обробки, відправляються автоматично і не можуть бути
          відхилені Замовником.
        </Paragraph>
        <Paragraph>
          11.10. Виконавець має право здійснювати записи телефонних розмов з
          Замовником. При цьому Продавець зобов'язується: запобігати спробам
          несанкціонованого доступу до інформації, отриманої в ході телефонних
          переговорів, та /або передачу її третім особам, які не мають
          безпосереднього відношення до виконання Замовлень, відповідно до
          Закону України від 02.10.1992 року № 2657-XII «Про захист інформації».
        </Paragraph>
        <H2>12. Термін дії та інші умови договору</H2>
        <Paragraph>
          12.1. Договір оферти набуває чинності з моменту, зазначеного в п. 1.5.
          цього Договору, і діє до виконання Сторонами своїх зобов'язань.
        </Paragraph>
        <Paragraph>
          12.2.Даний Договір публічної оферти, на умовах якого Виконавцем в
          інтересах Замовника виконуються роботи, розміщений на сайті Виконавця{' '}
          <A href={URL} target="_blanc">
            {URL}
          </A>
        </Paragraph>
        <Paragraph>
          12.3. Замовник згоден з тим, що Виконавець може використовувати фото
          чи відеоматеріали, зроблені з його виробом під час виконання робіт,
          задля рекламування своїх Послуг.
        </Paragraph>
        <Paragraph>
          12.4. Виконавцем за цим Договором оферти є: <br />
          <strong>Найменування:</strong> Фізична особа – підприємець Гончар Олег
          Леонідович
          <br />
          <strong>Адреса офісу Виконавця:</strong> 03127, м.Київ,
          просп.Академіка Глушкова, 1 (ВДНГ, НК «Експоцентр України»), корп.V
          <br />
          <strong>Контактний телефон:</strong>{' '}
          <A href="tel:380635627172">+380635627172</A>
          <br />
          <strong>Адреса електронної пошти:</strong>{' '}
          <A href="mailto:rent@omnicampus.io">rent@omnicampus.io</A>
          <br />
        </Paragraph>
        <Paragraph>
          12.5. Органом державного нагляду (контролю) за дотриманням
          законодавства про захист прав споживачів є Головне управління
          Держпродспоживслужби в місті Києві:
          <br />
          <strong>- Номери телефонів:</strong> <br />
          <A href="tel:0444865486">(044) 486-54-86</A>;{' '}
          <A href="tel:0636390909">(063) 639-09-09</A> (гаряча лінія); <br />
          <strong>- Поштова адреса:</strong> 04053, м. Київ, вул. Некрасовська,
          10/8; <br />
          <strong>- Електронна адреса:</strong>{' '}
          <A href="mailto:zvernen@kyiv-dpss.gov.ua">zvernen@kyiv-dpss.gov.ua</A>
        </Paragraph>
      </ContentWindow>
    </InfoPageTemplate>
  );
}
