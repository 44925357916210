import { useSelector } from 'react-redux';
import { LinkProps } from 'react-router-dom';

import { useCheckFlow } from '../../hooks/useCheckFlow';
import { selectUid } from '../../store/slices/initialSlice';

import { StyledLink } from './styled';

export default function Link({ to, children, ...props }: LinkProps) {
  const { basePath, query } = useCheckFlow();
  const uid = useSelector(selectUid);

  const link = (to as string).includes('/')
    ? `${basePath}/${uid}${to}${query}`
    : `/${to}/${uid}${query}`;

  return (
    <StyledLink to={link} {...props}>
      {children}
    </StyledLink>
  );
}
