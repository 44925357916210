import styled, { keyframes } from 'styled-components';

import { usePageCheck } from '../../hooks/usePageCheck';
import { ReactComponent as FilterSVG } from './filter.svg';
import { ApplyColorProps } from './types';

export const Filter = styled(FilterSVG)`
  display: none;
`;

const blobs = keyframes`
0% {
		opacity: 0;
		transform: scale(0) translate(calc(-1500% - 50%), -50%);
	}
	1% {
		opacity: 1;
	}
	35%,65% {
		opacity: 1;
		transform: scale(.9) translate(-50%, -50%);
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: scale(0) translate(calc(1500% - 50%), -50%);
	}
`;

const blobGrow = keyframes`
  0%,  39% {
		transform: scale(0) translate(-50%, -50%);
	}
	40%, 42% {
		transform: scale(1, .9) translate(-50%, -50%);
	}
	43%, 44% {
		transform: scale(1.2, 1.1) translate(-50%, -50%);
	}
	45%, 46% {
		transform: scale(1.3, 1.2) translate(-50%, -50%);
	}
	47%, 48% {
		transform: scale(1.4, 1.3) translate(-50%, -50%);
	}
	52%{
		transform: scale(1.5, 1.4) translate(-50%, -50%);
	}
	54% {
		transform: scale(1.7, 1.6) translate(-50%, -50%);
	}
	58% {
		transform: scale(1.8, 1.7) translate(-50%, -50%);
	}
	68%, 70% {
		transform: scale(1.7, 1.5) translate(-50%, -50%);
	}
	78% {
		transform: scale(1.6, 1.4) translate(-50%, -50%);
	}
	80%, 81% {
		transform: scale(1.5, 1.4) translate(-50%, -50%);
	}
	82%, 83% {
		transform: scale(1.4, 1.3) translate(-50%, -50%);
	}
	84%, 85% {
		transform: scale(1.3, 1.2) translate(-50%, -50%);
	}
	86%, 87% {
		transform: scale(1.2, 1.1) translate(-50%, -50%);
	}
	90%, 91% {
		transform: scale(1, .9) translate(-50%, -50%);
	}
	92%, 100% {
		transform: scale(0) translate(-50%, -50%);
	}
`;

function useApplyColor({ theme }: ApplyColorProps): string {
  const { isToOpenPage } = usePageCheck();
  return isToOpenPage
    ? theme.palette.nav.buttons.secondary
    : theme.palette.nav.buttons.primary;
}

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const SizeWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const BlobsWrapper = styled.div`
  filter: url(#goo);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

  border-radius: 70px;
  transform-style: preserve-3d;
`;

export const BlobCenter = styled.div`
  transform-style: preserve-3d;
  position: absolute;
  background: ${useApplyColor};
  top: 50%;
  left: 50%;
  width: 10%;
  height: 10%;
  transform-origin: left top;
  transform: scale(0.9) translate(-50%, -50%);
  animation: ${blobGrow} linear 3.4s infinite;
  border-radius: 50%;
  box-shadow: 0 0 1.3em -0.2em ${useApplyColor};
`;

export const Blob = styled.div`
  position: absolute;
  background: ${useApplyColor};
  top: 50%;
  left: 50%;
  width: 10%;
  height: 10%;
  border-radius: 50%;
  animation: ${blobs} ease-out 3.4s infinite;
  transform: scale(0.9) translate(-50%, -50%);
  transform-origin: center top;
  opacity: 0;

  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.4s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }

  &:nth-child(4) {
    animation-delay: 0.8s;
  }

  &:nth-child(5) {
    animation-delay: 1s;
  }

  &:nth-child(6) {
    animation-delay: 1.2s;
  }
`;
