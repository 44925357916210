import { ReactNode } from 'react';

import Link from '../Link';
import MiniLoader from '../MiniLoader';
import { ButtonProps } from './types';

import { A, ButtonBase } from './styled';

export default function Button({
  to,
  href,
  variant,
  children,
  size = 'xxl',
  isActive = true,
  isLoading = false,
  onClick,
  ...props
}: ButtonProps): JSX.Element {
  const isActiveBtn = isActive ? !isLoading : isActive;

  const element = (): ReactNode => {
    const buttonBase = (
      <ButtonBase
        size={size}
        onClick={onClick}
        variant={variant}
        isActive={isActiveBtn}
        {...props}
      >
        {isLoading ? <MiniLoader /> : children}
      </ButtonBase>
    );

    if (to) {
      return <Link to={to}>{buttonBase}</Link>;
    }
    if (href) {
      return <A href={href}>{buttonBase}</A>;
    }
    return buttonBase;
  };

  return <>{element()}</>;
}
