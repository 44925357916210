import { ThemeProvider } from 'styled-components';

import { composeTheme } from '../../../utils/composeTheme';
import { getCustomThemePalette } from '../../../utils/getCustomThemePalette';
import { getThemeFonts } from '../../../utils/getThemeFonts';
import { ExampleAreaProps } from './types';

import {
  ColorText,
  CommonLink,
  ErrorText,
  ExampleAreaContainer,
  SuccessText,
} from './styled';

export default function ExampleArea({
  themeSettings: { themeMode, isHighlightLinks, fontSize, isArialFont },
}: ExampleAreaProps): JSX.Element {
  const customPalette = getCustomThemePalette(themeMode);
  const customCommonThemeData = getThemeFonts(isArialFont);
  const theme = composeTheme(customPalette, customCommonThemeData);

  return (
    <ThemeProvider theme={theme}>
      <ExampleAreaContainer
        fontSize={fontSize}
        isHighlightLinks={isHighlightLinks}
      >
        <ColorText>Example color text</ColorText>
        <CommonLink aria-label="Link example" to="/">
          Example common link text
        </CommonLink>
        <SuccessText>Example success text</SuccessText>
        <ErrorText>Example error text</ErrorText>
      </ExampleAreaContainer>
    </ThemeProvider>
  );
}
