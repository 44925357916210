export enum ReturnStep {
  begin,
  returnPolicy,
  packaging,
  parcelSize,
  prohibitions,
}

export interface SliderProps {
  step: ReturnStep;
  type: 'content' | 'buttons';
}

export type Way = 'next' | 'prev';
