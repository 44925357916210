import styled from 'styled-components';

import { infoTextTemplate } from '../../../../../../../../theme/pagesElements';

export const LastFeedbackWrapper = styled.div`
  margin-left: ${({ theme }): string => theme.spacing(3)};
  margin-bottom: ${({ theme }): string => theme.spacing(3.5)};
`;

export const RecipientDateWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const Recipient = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  opacity: 0.66;
`;

export const DateOfComment = styled(Recipient)``;

export const RateStarsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }): string => theme.spacing(1.5)};
  align-items: center;
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const Rate = styled(Recipient)`
  padding-top: 2px;
`;

export const Comment = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
