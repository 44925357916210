import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../../components/Button';
import { projectIs } from '../../../../../constants';
import { useCustomNavigate } from '../../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { selectPayment } from '../../../../../store/slices/initialSlice';

interface UsePaymentsValue {
  isShowPayButton: boolean;
  PayButton: ReactNode;
}

export function usePayments(): UsePaymentsValue {
  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();

  const payment = useSelector(selectPayment);

  const toPaymentPage = () => {
    if (projectIs.mncdk) {
      window.open('https://www.cdek.ru/ru/payment');
    }
    if (projectIs.omkaz) {
      navigateWithUid('/order-payment');
      window.scroll(0, 0);
    }
  };

  return {
    isShowPayButton: !!payment,
    PayButton: projectIs.omua ? (
      <form
        method="POST"
        action="https://www.liqpay.ua/api/3/checkout"
        acceptCharset="utf-8"
      >
        <input type="hidden" name="data" value={payment?.data || ''} />
        <input
          type="hidden"
          name="signature"
          value={payment?.signature || ''}
        />
        <Button variant="contained" size="m" type="submit">
          {staticTranslate('Pay')}
        </Button>
      </form>
    ) : (
      <Button variant="contained" size="m" onClick={toPaymentPage}>
        {staticTranslate('Pay')}
      </Button>
    ),
  };
}
