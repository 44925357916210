import styled from 'styled-components';

import ButtonBase from '../../../../components/Button';

import { ButtonsWrapper as BaseButtonsWrapper } from '../../../../components/ButtonsPaper/styled';

export const ButtonsWrapper = styled(BaseButtonsWrapper)`
  margin-top: ${({ theme }): string => theme.spacing(3)};

  button {
    height: 60px;
    font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  }
`;

export const ButtonLaundry = styled(ButtonBase)`
  margin-bottom: 100px;
`;
