import styled from 'styled-components';

import { stackCellCommon, stackCellsContainerCommon } from '../../styled';

export const CellsContainer = styled.div`
  ${stackCellsContainerCommon};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1px;
`;

export const Cell = styled.div`
  ${stackCellCommon};

  flex: 1;
  height: 50%;
  min-width: 49%;
`;
