import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCheckFlow } from '../../../hooks/useCheckFlow';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useTypedDispatch } from '../../../store';
import { openDeviceCell } from '../../../store/slices/configSlice';
import { selectUid } from '../../../store/slices/initialSlice';
import { clearState, createOrder } from '../../../store/slices/returnSlice';
import { Step } from '../../GetOrderPage/types';
import { RETURN_FORM_STEP_KEY } from '../constants';
import { ClickHandlerData } from '../types';

export function useClickHandlers(): ClickHandlerData {
  const [step, setStep] = useState(Step.phone);
  const dispatch = useTypedDispatch();

  const deviceUid = useSelector(selectUid);

  const { flow } = useCheckFlow();
  const navigate = useNavigate();
  const { navigateToErrorPage, navigateWithUid } = useCustomNavigate();

  function nextStepHandler(): void {
    if (step === Step.phone) {
      setStep(Step.code);
      sessionStorage.setItem(RETURN_FORM_STEP_KEY, Step.code);
    } else {
      dispatch(createOrder())
        .unwrap()
        .then((orderUid) => {
          return dispatch(
            openDeviceCell({
              flow,
              deviceUid,
              orderUid,
            }),
          ).unwrap();
        })
        .then(() => {
          navigateWithUid('/open-cell');
        })
        .catch((err) => navigateToErrorPage(err.message))
        .finally(() => {
          sessionStorage.removeItem('returnPageStep');
          sessionStorage.removeItem('returnFormStep');
          dispatch(clearState());
        });
    }
  }

  function prevStepHandler(): void {
    if (step === Step.code) {
      setStep(Step.phone);
      sessionStorage.setItem(RETURN_FORM_STEP_KEY, Step.phone);
    } else {
      navigate(-1);
    }
  }

  return {
    step,
    setStep,
    nextStepHandler,
    prevStepHandler,
  };
}
