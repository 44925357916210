import styled from 'styled-components';

import { ReactComponent as Clock } from '../../assets/images/extendClock.svg';
import { infoTextTemplate } from '../../theme/pagesElements';

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const BoxImg = styled.img`
  width: 100%;
`;

export const Heading = styled.h1`
  ${infoTextTemplate};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  text-align: center;
  margin: ${({ theme }): string => theme.spacing(2.5)} 0;
  padding: 0 ${({ theme }): string => theme.spacing(2.5)};
`;

export const Form = styled.form`
  width: 275px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(1.5)};
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
  width: 100%;
`;

export const ExtendClock = styled(Clock)`
  position: absolute;
  z-index: 1;
  path {
    fill: ${({ theme }): string => theme.palette.primary[30]};
  }
`;
