import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLang } from '../store/slices/langSlice';
import { getOmnicTranslation } from '../utils/getOmnicTranslation';

export function useDynamicTranslate(text: string): string {
  const [value, setValue] = useState(text);

  const currentLang = useSelector(selectLang);

  useEffect(() => {
    getOmnicTranslation({ text, lang: currentLang }).then((data) =>
      setValue(data),
    );
  }, [currentLang, text]);

  return value;
}
