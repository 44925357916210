import styled from 'styled-components';

import { ReactComponent as DF } from '../../../../assets/images/dislikeFace.svg';

export const ButtonsWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(4)};
  gap: ${({ theme }): string => theme.spacing(3.5)};
`;

export const DislikeFaceIcon = styled(DF)`
  opacity: 0.6;
  height: 94px;
  width: 94px;
  margin: ${({ theme }): string => theme.spacing(4)} 0;

  path {
    fill: ${({ theme }): string => theme.palette.text.primary};
  }
`;
