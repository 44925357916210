import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { PaymentErrorTypes } from '../../helpers/enums';
import { selectUid } from '../../store/slices/initialSlice';
import { ErrorType } from '../../store/types/errors';

const PaymentResult: FC = () => {
  const orderUid = useSelector(selectUid);

  const { status } = useParams();

  const successType = status === PaymentErrorTypes.success;

  return (
    <div>
      {successType ? (
        <Navigate to={`/${orderUid}/`} replace />
      ) : (
        <Navigate
          to={`/${orderUid}/error`}
          state={ErrorType.PAYMENT_ERROR}
          replace
        />
      )}
    </div>
  );
};

export default PaymentResult;
