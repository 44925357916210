import { useNavigate } from 'react-router-dom';

import Button from '../../../../components/Button';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useSendFeedback } from '../../../../hooks/useSendFeedback';
import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import { setWasLeftFeedback } from '../../../../store/slices/feedbackSlice';
import { FeedbackType } from '../../../types/feedback';
import Thanks from '../Thanks';

import { ButtonsWrapper, DislikeFaceIcon } from './styled';

export default function DislikeRate(): JSX.Element {
  const { staticTranslate } = useTranslate();
  const dispatch = useTypedDispatch();
  const sendFeedback = useSendFeedback();

  const { navigateWithUid } = useCustomNavigate();
  const navigate = useNavigate();

  async function sendFirstReason(): Promise<void> {
    await sendFeedback({
      type: FeedbackType.orderDislike,
      rate: 0,
      comment: staticTranslate('Reason1'),
    }).then(() => dispatch(setWasLeftFeedback()));
  }

  async function sendSecondReason(): Promise<void> {
    await sendFeedback({
      type: FeedbackType.orderDislike,
      rate: 0,
      comment: staticTranslate('Reason2'),
    }).then(() => dispatch(setWasLeftFeedback()));
  }

  function toDetailFeedback(): void {
    navigateWithUid('/rate/detail-rate');
  }

  return (
    <>
      <Thanks textVariant="primary" />
      <DislikeFaceIcon />
      <ButtonsWrapper>
        <Button size="xl" variant="strong" onClick={sendFirstReason}>
          {staticTranslate('Reason1')}
        </Button>
        <Button size="xl" variant="strong" onClick={sendSecondReason}>
          {staticTranslate('Reason2')}
        </Button>
        <Button size="xl" variant="contained" onClick={toDetailFeedback}>
          {staticTranslate('Reason3')}
        </Button>
      </ButtonsWrapper>
      <Button variant="outlined" onClick={() => navigate(-1)}>
        {staticTranslate('Back')}
      </Button>
    </>
  );
}
