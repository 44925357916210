import { ThemeTypography } from 'styled-components';

import { themeTypography } from '../theme/themeData';

export function getThemeFonts(isArialFont: boolean): ThemeTypography {
  if (isArialFont) {
    const customThemeTypography: ThemeTypography = JSON.parse(
      JSON.stringify(themeTypography),
    );

    customThemeTypography.typography.fontFamily.font1 = 'Arial, sans-serif';
    customThemeTypography.typography.fontFamily.font2 = 'Arial, sans-serif';
    customThemeTypography.typography.fontFamily.font3 = 'Arial, sans-serif';

    return customThemeTypography;
  }

  return themeTypography;
}
