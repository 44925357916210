import styled from 'styled-components';

import { ReactComponent as StarSVG } from '../../../../assets/images/star.svg';
import { StarProps } from './types';

export const Star = styled(StarSVG)`
  pointer-events: none;
`;

export const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }): string => theme.spacing()};
  width: fit-content;
`;

export const StarWrapper = styled.figure<StarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${Star} {
    path {
      fill: ${({ theme }): string => theme.palette.primary[30]};
      opacity: ${({ isFilled }): string => (!isFilled ? '0.5' : '1')};
    }
  }
`;
