import InfoPageTemplate from '../../components/InfoPageTemplate';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';

import {
  ContentWindow,
  H1,
  H2,
  Li,
  Paragraph,
  Ul,
} from '../../components/InfoPageTemplate/styled';

export default function A11yStatement(): JSX.Element {
  const { staticTranslate } = useTranslate();
  const a11yStatement = staticTranslate('AccessibilityStatement');

  useHeadTitle(a11yStatement);

  return (
    <InfoPageTemplate>
      <ContentWindow aria-label={a11yStatement}>
        <H1>הצהרת נגישות - אומני</H1>
        <Paragraph>
          חברת אומני רשת לוקרים הפתוחה בע"מ נוקטת את מירב המאמצים ומשקיע ה
          משאבים רבים על מנת לספק לכלל לקוחותיה שירות שוויוני, נגיש ומקצועי. לשם
          כך, חברתנו פועלת לביצוע התאמות אשר יאפשרו שירות הוגן גם ללקוחות עם
          מוגבלות אשר זקוקים לנגישות. התאמות אלה נעשות בכפוף ובהתאם להוראות חוק
          שוויון זכויות לאנשים עם מוגבלות (התשנ"ח - 1998) התקנות והתקנים
          המשויכים אליו.
        </Paragraph>
        <H2>נגישות השירות </H2>
        <Paragraph>
          <strong>מענה קולי נגיש</strong> - המענה הקולי הונגש לטובת לקוחות עם
          מוגבלות, כך שהמידע מועבר בשפה ברורה וללא מוסיקת רקע.
        </Paragraph>
        <Paragraph>
          פניה באמצעות הדואר: <br />
          <strong>Emek a shalom 22Yoqneam ilit Israel</strong>
        </Paragraph>
        <H2>נגישות אתר האינטרנט</H2>
        <Paragraph>
          אתר אינטרנט נגיש הוא אתר המאפשר לאנשים עם מוגבלות ולאנשים מבוגרים
          לגלוש באותה רמה של יעילות והנאה ככל הגולשים.
        </Paragraph>
        <H2>מידע על נגישות האתר</H2>
        <Paragraph>
          אתר זה עומד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות
          נגישות לשירות), התשע"ג 2013 התאמות הנגישות בוצעו עפ"י המלצות התקן
          הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.0
          הבינלאומי. הבדיקות נבחנו לתאימות הגבוהה ביותר עבור דפדפנים: Explorer
          Internet, Firefox, Chrome.
        </Paragraph>
        <Paragraph>
          להלן חלק מהפעולות שבוצעו במסגרת הנגשת האתר והאפשרויות הקיימות בו:
        </Paragraph>
        <Ul>
          <Li>הגדלת התצוגה האתר</Li>
          <Li>צביעת האתר לגווני אפור</Li>
          <Li>יצירת מצב נגטיב (צבעים הפוכים)</Li>
        </Ul>
        <H2>יצירת קשר</H2>
        <Paragraph>
          יש לציין שלמרות מאמצנו להנגיש את כלל הדפים באתר, יתכן ויתגלו חלקים
          באתר שטרם הונגשו. אנו פועלים על מנת לשפר את נגישות האתר מתוך מחויבותנו
          לאפשר שימוש בו עבור כלל האוכלוסייה.
        </Paragraph>
        <Paragraph>
          אם נתקלתם בבעיית נגישות, נשמח אם תיידעו אותנו על מנת שנוכל לטפל.
        </Paragraph>
        <Paragraph>
          לפניות ניתן לשלוח מייל לכתובת <strong>dg@omnic.net</strong>
        </Paragraph>
        <Paragraph>
          <strong>עודכן לאחרונה ביום:1.9.2022</strong>
        </Paragraph>
      </ContentWindow>
    </InfoPageTemplate>
  );
}
