export enum ErrorType {
  TECHNICAL = 'TECHNICAL',
  AUTH = 'AUTH',
  NO_ORDER_ERROR = 'NO_ORDER_ERROR',
  ORDER_IN_POSTAMAT_ERROR = 'ORDER_IN_POSTAMAT_ERROR',
  OPEN_CELL_ERROR = 'OPEN_CELL_ERROR',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

export enum ErrorIds {
  orderNotDeliveredYet = 'order_not_delivered_yet',
  orderHasAlreadyDelivered = 'order_has_already_delivered',
  notFound = 'not_found',
  cellDidNotOpen = 'cell_did_not_open',
  cellBlocked = 'cell_blocked',
  thereAreOpenCells = 'there_are_open_cells',
  deviceOffline = 'device_offline',
  orderDoesNotExist = 'order_does_not_exist',
  paymentRequired = 'payment_required',
  orderHasAlreadyPaid = 'order_has_already_paid',
}
