import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useTranslate } from '../../hooks/useTranslate';
import { selectCellsStatus } from '../../store/slices/cellsSlice';
import Packaging from './components/Packaging';
import ParcelSize from './components/ParcelSize';
import Prohibitions from './components/Prohibitions';
import ReturnPolicy from './components/ReturnPolicy';
import Shops from './components/Shops';
import { PROHIBITIONS_KEY, RETURN_PAGE_STEP_KEY } from './constants';
import { ReturnStep, Way } from './types';

import {
  ButtonsContainer,
  MultiButtonsWrapper,
  ReturnContainer,
  SingleButtonWrapper,
  Slider,
  StepContent,
} from './styled';

export default function ReturnPage(): JSX.Element {
  const [returnStep, setReturnStep] = useState(ReturnStep.begin);
  const [isProhibitionsApproval, setIsProhibitionsApproval] = useState(false);

  const cellsStatus = useSelector(selectCellsStatus);
  const { staticTranslate } = useTranslate();
  const { navigateWithUid } = useCustomNavigate();

  useEffect(() => {
    const savedStep = sessionStorage.getItem(RETURN_PAGE_STEP_KEY);
    const savedIsProhibitionsApproval =
      sessionStorage.getItem(PROHIBITIONS_KEY);

    if (savedStep) setReturnStep(+savedStep);
    if (savedIsProhibitionsApproval) {
      setIsProhibitionsApproval(
        JSON.parse(savedIsProhibitionsApproval) as boolean,
      );
    }
  }, []);

  function changeStep(way: Way): void {
    if (way === 'next') {
      setReturnStep((s) => {
        const nextStep = s + 1;
        sessionStorage.setItem(RETURN_PAGE_STEP_KEY, String(nextStep));
        return nextStep;
      });
    } else {
      setReturnStep((s) => {
        const prevStep = s - 1;
        sessionStorage.setItem(RETURN_PAGE_STEP_KEY, String(prevStep));
        return prevStep;
      });
    }
  }

  function getPageHeader(): string {
    if (returnStep === ReturnStep.returnPolicy) return '1Of3Step';
    if (returnStep === ReturnStep.packaging) return '2Of3Step';
    if (
      returnStep === ReturnStep.parcelSize ||
      returnStep === ReturnStep.prohibitions
    )
      return '3Of3Step';
    return 'ChooseAnOnlineStore';
  }

  usePageHeaderContext(staticTranslate(getPageHeader()));

  return cellsStatus === 'loading' ? (
    <Loader />
  ) : (
    <>
      <ReturnContainer>
        <Slider step={returnStep} type="content">
          <StepContent>
            <Shops changeStep={changeStep} />
          </StepContent>
          <StepContent>
            <ReturnPolicy />
          </StepContent>
          <StepContent>
            <Packaging />
          </StepContent>
          <StepContent>
            <ParcelSize changeStep={changeStep} />
          </StepContent>
          <StepContent>
            <Prohibitions
              isProhibitionsApproval={isProhibitionsApproval}
              setIsProhibitionsApproval={setIsProhibitionsApproval}
            />
          </StepContent>
        </Slider>
      </ReturnContainer>
      <ButtonsContainer>
        <Slider step={returnStep} type="buttons">
          <SingleButtonWrapper>
            <Button variant="outlined" to="/">
              {staticTranslate('ToMain')}
            </Button>
          </SingleButtonWrapper>
          <MultiButtonsWrapper>
            <Button variant="outlined" onClick={() => changeStep('prev')}>
              {staticTranslate('Back')}
            </Button>
            <Button variant="contained" onClick={() => changeStep('next')}>
              {staticTranslate('Next')}
            </Button>
          </MultiButtonsWrapper>
          <SingleButtonWrapper>
            <Button variant="outlined" onClick={() => changeStep('prev')}>
              {staticTranslate('Back')}
            </Button>
          </SingleButtonWrapper>
          <MultiButtonsWrapper>
            <Button variant="outlined" onClick={() => changeStep('prev')}>
              {staticTranslate('Back')}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigateWithUid('/return/return-form')}
              isActive={isProhibitionsApproval}
            >
              {staticTranslate('Next')}
            </Button>
          </MultiButtonsWrapper>
        </Slider>
      </ButtonsContainer>
    </>
  );
}
