import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useTypedDispatch } from '../store';
import { selectWasLeftFeedback } from '../store/slices/feedbackSlice';
import { selectAppFlow } from '../store/slices/flowSlice';
import {
  selectIsOrderReceived,
  selectSavedError,
  setSavedError,
} from '../store/slices/initialSlice';
import { selectOrdersList } from '../store/slices/receiveSlice';
import { ErrorType } from '../store/types/errors';
import { Flow } from '../types/appLogic';
import { useCheckFlow } from './useCheckFlow';
import { useCustomNavigate } from './useCustomNavigate';
import { usePageCheck } from './usePageCheck';

export function useRedirect(isValidOrderUid: boolean) {
  const isOrderReceived = useSelector(selectIsOrderReceived);
  const wasLeftFeedback = useSelector(selectWasLeftFeedback);
  const savedError = useSelector(selectSavedError);
  const orders = useSelector(selectOrdersList);
  const appFlow = useSelector(selectAppFlow);

  const { pathname } = useLocation();
  const { navigateToErrorPage, navigateWithUid } = useCustomNavigate();

  const dispatch = useTypedDispatch();

  const { flow } = useCheckFlow();

  const {
    isMainPage,
    isInfoPage,
    isQrScanPage,
    isToOpenPage,
    isExtensionPage,
    isPaymentPage,
    isOffer,
    isA11yPage,
    isA11yStatement,
    isGetOrderPage,
    isOrdersListPage,
  } = usePageCheck();

  useEffect(() => {
    if (flow === Flow.device) {
      if (isGetOrderPage && orders.length) {
        navigateWithUid('/orders-list', { replace: true });
        return;
      }
      if (isOrdersListPage && !orders.length) {
        navigateWithUid('/get-order', { replace: true });
        return;
      }
    }

    if (isMainPage && isValidOrderUid) {
      dispatch(setSavedError(null));
      return;
    }

    if (savedError && !isOffer && !isA11yPage && !isA11yStatement) {
      navigateToErrorPage(savedError);
      return;
    }

    if (wasLeftFeedback && appFlow === Flow.common) {
      navigateWithUid('/rate/like');
      return;
    }

    if (
      isOrderReceived &&
      (isMainPage ||
        isInfoPage ||
        isQrScanPage ||
        isToOpenPage ||
        isExtensionPage ||
        isPaymentPage)
    ) {
      navigateToErrorPage(ErrorType.ORDER_IN_POSTAMAT_ERROR);
    }
    // We must check this only at first render
    // and when path is changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
}
