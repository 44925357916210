import styled from 'styled-components';

import { TextareaAutosize } from '../../utils/TextAreaAutosize';

export const Form = styled.form`
  width: 100%;
`;

export const Label = styled.label``;

export const StyledTextArea = styled(TextareaAutosize)`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  color: ${({ theme }): string => theme.palette.text.primary};
  resize: none;
  padding: ${({ theme }): string => theme.spacing(1.5)};
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  border: 1px solid ${({ theme }): string => theme.palette.border.special};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: ${({ theme }): string =>
    theme.palette.background.secondary};

  &::placeholder {
    color: ${({ theme }): string => theme.palette.text.primary};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }): string => theme.palette.border.primary};
    box-shadow: 0 0 4px ${({ theme }): string => theme.palette.border.primary};
  }
`;
