import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';
import { MarkProps } from './types';

function applyBorder({
  isActive,
  theme,
}: MarkProps): FlattenSimpleInterpolation {
  const borderColor = isActive ? theme.palette.primary[30] : 'transparent';
  return css`
    border-bottom: 4px solid ${borderColor};
  `;
}

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};

  margin: ${({ theme }): string => theme.spacing(2)} 0;
  padding: 0 ${({ theme }): string => theme.spacing(3)};
`;

export const Mark = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  ${infoTextTemplate};
  text-transform: uppercase;
  font-size: 0.64em;
  color: ${({ theme }): string => theme.palette.text.primary};

  padding: ${({ theme }): string => theme.spacing()} 0;
  ${applyBorder};
`;
