import styled from 'styled-components';

export const MapContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const MapManagerWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
`;
