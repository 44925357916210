import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useStorageDate } from '../../../../../hooks/useStorageDate';
import {
  selectDeviceInfo,
  selectOrderInfo,
} from '../../../../../store/slices/initialSlice';
import {
  HOURS_IN_DAY,
  OWN_PASTAMAT,
  PARTNER_POSTAMAT,
  PREPARED_TO_WITHDRAWAL_STATUS,
} from '../constants';

interface UseExtendSettingsValue {
  expDate: string;
  isShowExtendButton: boolean;
}

export function useExtendSettings(): UseExtendSettingsValue {
  const [isOurPost, setIsOurPost] = useState(false);
  const { diffHours, expDate } = useStorageDate();

  const { status } = useSelector(selectOrderInfo);
  const { device_type: deviceType } = useSelector(selectDeviceInfo);

  useEffect(() => {
    if (
      status === PREPARED_TO_WITHDRAWAL_STATUS &&
      deviceType === OWN_PASTAMAT
    ) {
      setIsOurPost(true);
    }
  }, [status, deviceType]);

  return {
    expDate,
    isShowExtendButton:
      (isOurPost || diffHours <= HOURS_IN_DAY) &&
      deviceType !== PARTNER_POSTAMAT,
  };
}
