import styled, { css } from 'styled-components';

import { getDir } from '../../utils/getDir';

function selectMarkerSide() {
  const dir = getDir();

  return dir === 'ltr'
    ? css`
        left: 5px;
      `
    : css`
        right: 5px;
      `;
}

export const CheckBoxComponent = styled.input`
  display: none;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2.5)};

  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};

  margin: 0 ${({ theme }): string => theme.spacing()};

  &:before {
    content: '';
    display: block;
    min-width: 32px;
    min-height: 32px;
    border: 1px solid ${({ theme }): string => theme.palette.primary[50]};
    border-radius: 4px;
  }

  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    display: block;
    min-width: 24px;
    min-height: 24px;
    background-color: ${({ theme }): string => theme.palette.primary[50]};
    border-radius: 2px;
    transform: scale(0, 0);
    transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
    ${selectMarkerSide}
  }
`;

export const Form = styled.form`
  width: 100%;
  padding: 0 ${({ theme }): string => theme.spacing(2)};

  ${CheckBoxComponent}:checked + ${Label}:after {
    opacity: 1;
    transform: scale(1, 1);
  }
`;
