import styled from 'styled-components';

import { infoTextTemplate } from '../../../../theme/pagesElements';

export const DetailsWrapper = styled.div`
  padding: ${({ theme }): string => theme.spacing(3)};
  padding-bottom: ${({ theme }): string => theme.spacing(5)};
  box-shadow: 0 2px 4px ${({ theme }): string => theme.palette.border.shadow};
  border-radius: 0 0 5px 5px;
`;

export const DeviceHeader = styled.h2`
  ${infoTextTemplate};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
