import { useSelector } from 'react-redux';

import InfoPageTemplate from '../../components/InfoPageTemplate';
import { usePageHeaderContext } from '../../context/PageHeaderContext/PageHeaderContext';
import { useHeadTitle } from '../../hooks/useHeadTitle';
import { useTranslate } from '../../hooks/useTranslate';
import { selectUid } from '../../store/slices/initialSlice';

import {
  ContentWindow,
  H1,
  H2,
  H4,
  Paragraph,
} from '../../components/InfoPageTemplate/styled';

export default function Offer(): JSX.Element {
  const { staticTranslate } = useTranslate();

  const offerContract = staticTranslate('OfferContract');

  useHeadTitle(offerContract);
  usePageHeaderContext(staticTranslate('OfferContract'));

  const uid = useSelector(selectUid);

  const location = `${window.location.origin.toString()}`;
  const URL = `${location}/#/${uid}`;

  return (
    <InfoPageTemplate>
      <ContentWindow aria-label={offerContract}>
        <H1>ПУБЛИЧНЫЙ ДОГОВОР-ОФЕРТА</H1>
        <Paragraph>
          Настоящий договор между Товариществом с ограниченной̆ ответственностью
          «Friendship business group» оказывающий, сервис, через сайт{' '}
          <a href={URL} target="_blanc">
            {URL}
          </a>
          , который определяет условия получение почтовых отправлений, именуемым
          в дальнейшем <strong>«Сервис»</strong>, и пользователем услуг сервиса,
          именуемым в дальнейшем <strong>«Заказчик»</strong>.
        </Paragraph>
        <H4>Термины, употребляемые в договоре:</H4>
        <Paragraph>
          <strong>Заказчик</strong> – юридическое или физическое лицо.
        </Paragraph>
        <Paragraph>
          <strong>Исполнитель</strong> – юридическое лицо, выполняющее Поручения
          Заказчика за вознаграждение.
        </Paragraph>
        <Paragraph>
          <strong>Сервис</strong> – получение почтовых отправлений через
          Почтоматную сеть.
        </Paragraph>
        <Paragraph>
          <strong>Почтовое отправление</strong> – товар, которое передается
          Заказчиком Сервису для доставки Получателю товаров до Почтоматной
          сети.
        </Paragraph>
        <Paragraph>
          <strong>Наложенный платёж</strong> — денежная сумма, которую Сервис
          взыскивает с Получателя при вручении ему почтового отправления по
          поручению Заказчика, и которая пересылается Заказчику посредством
          взаиморасчета.
        </Paragraph>
        <Paragraph>
          <strong>Почтоматная сеть</strong> - это сеть автоматизированных
          почтоматов <strong>«OMNIHUB»</strong> или партнерской сети{' '}
          <strong>«TASTAMAT»</strong> по бесконтактной выдаче товаров купленных
          онлайн.
        </Paragraph>
        <Paragraph>
          <strong>Получатель</strong> – это физическое лицо, который заказал или
          купил товар онлайн у интернет-магазинов.
        </Paragraph>
        <H2>1. ОБЩИЕ ПОЛОЖЕНИЯ</H2>
        <Paragraph>
          1.1. Товарищество с ограниченной̆ ответственностью «Friendship business
          group» именуемое в дальнейшем «Исполнитель», публикует настоящий
          договор, являющийся публичным договором - офертой (предложением) в
          адрес физических и юридических лиц в соответствии со ст. 447
          Гражданского Кодекса Республики Казахстан (далее – ГК РК).
        </Paragraph>
        <Paragraph>
          1.2. Настоящая публичная оферта (именуемая в дальнейшем «Оферта»)
          определяет все существенные условия договора между ТОО «Friendship
          business group» и физическим или юридическим лицом, акцептовавшим
          Оферту.
        </Paragraph>
        <Paragraph>
          1.3. Оферта может быть принята любым физическим или юридическим лицом
          на территории Республики Казахстан, имеющим намерение приобрести
          услуги, реализуемые/предоставляемые ТОО «Friendship business group»
          через сервис, расположенный на сайте{' '}
          <a href={URL} target="_blanc">
            {URL}
          </a>
          .
        </Paragraph>
        <Paragraph>
          1.4. В случае принятия условий настоящей Оферты, физическое или
          юридическое лицо, производящее акцепт оферты, становится
          автоматический Заказчиком и безоговорочно принимает все условия,
          содержащиеся в оферте в целом (т.е. в полном объеме и без исключений).
        </Paragraph>
        <Paragraph>
          1.5. Акцептом является получение Сервисом сообщения о намерении
          Заказчиком приобрести услуги на условиях, предложенных Сервисом.
        </Paragraph>
        <Paragraph>
          1.6. Оферта, все приложения к ней, а также вся дополнительная
          информация о товарах/услугах ТОО «Friendship business group»,
          опубликована на сайте{' '}
          <a href={URL} target="_blanc">
            {URL}
          </a>
          .
        </Paragraph>
        <H2>2. ПРЕДМЕТ ОФЕРТЫ</H2>
        <Paragraph>
          2.1. Исполнитель оказывает Заказчику комплекс услуг по выдаче
          корреспонденции, почтовых отправлений и грузов (далее – Отправления)
          до Почтоматной сети, а также по принципу, осуществляет прием от
          Плательщика в размере, указанном в Заявке безналичных и наличных
          денежных средств (доставка товаров с наложенным платежом до
          Почтоматной сети), с последующим перечислением Заказчику. Перечисление
          наложенного платежа Заказчика осуществляется раз в неделю.
        </Paragraph>
        <Paragraph>
          2.2. Услуги, оказываемые Исполнителем Заказчику в пределах территории
          Республики Казахстан, осуществляются в соответствии законодательством
          Республики Казахстан. К отношениям между Заказчиком и Исполнителем
          применяются положения ГК РК, Закон Республики Казахстан от 04.05.2010
          N 274-IV "О защите прав потребителей", а также иные нормативные
          правовые акты, принятые в соответствии с ними.
        </Paragraph>
        <H2>3. СТАТУС ОНЛАЙН СЕРВИСА</H2>
        <Paragraph>
          3.1. Сервис ТОО «Friendship business group» предназначен для
          организации по выдачи товаров через OMNIHUB.
        </Paragraph>
        <Paragraph>
          3.2. Сервис не несет ответственности за содержание и достоверность
          информации, предоставленной Заказчиком при оформлении Поручения.
        </Paragraph>
        <H2>4. ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ</H2>
        <Paragraph>
          4.1. Исполнитель обязан обеспечить сохранность Почтового отправления с
          момента их приема до вручения Заказчику;
        </Paragraph>
        <Paragraph>
          4.2. Исполнитель обязан по требованию Заказчика (дата запроса не
          должна превышать 6 (шесть) месяцев с момента доставки) предоставить
          информацию о доставке отправления в электронном варианте;
        </Paragraph>
        <Paragraph>
          4.4. Исполнитель вправе отказать Заказчику в оказании Услуги в случае
          отсутствия свободных ячеек в Почтоматной сети, несоответствия товара
          допустимым размерам ячеек или неисправности запрашиваемого почтомата;
        </Paragraph>
        <Paragraph>
          4.5. Исполнитель вправе запрашивать от Заказчика актуальную статистику
          проведенных Услуг в целях сверки со своими данными;
        </Paragraph>
        <H2>5. ПРАВА, ОБЯЗАННОСТИ И ОТВЕТСТВЕННОСТЬ ЗАКАЗЧИКА</H2>
        <Paragraph>
          5.1. Заказчик обязуется в начале оказания каждой Услуги передавать
          информацию об Абонентах и товарах Отправления, передаваемых через
          OMNIHUB;
        </Paragraph>
        <Paragraph>
          5.2. Заказчик обязуется не допускать к отправке Отправления,
          запрещенных к перевозке законодательством Республики Казахстан и
          международным правом;
        </Paragraph>
        <Paragraph>
          5.3. Заказчик обязуется обеспечить готовность отправлений (в упаковке,
          пригодной̆ для транспортировки);
        </Paragraph>
        <Paragraph>
          5.4. Заказчик обязуется указать полный̆ адрес доставки отправлений,
          фамилию конкретного получателя, контактный̆ телефон получателя;
        </Paragraph>
        <Paragraph>
          5.5. Заказчик обязуется по требованию Исполнителя предоставить копии
          документов, подтверждающих юридический̆ статус компании и документ
          предоставляющий̆ право подписи настоящего договора;
        </Paragraph>
        <Paragraph>
          5.6. Заказчик несет ответственность за передачу к доставке отправлений
          с вложениями, запрещенными к перевозке Законодательством Республики
          Казахстан: оружием, взрывоопасными, легковоспламеняющимися,
          отравляющими, радиоактивными, наркотическими веществами и пр. Кроме
          того, Заказчик гарантирует, что вложения не классифицируются как
          опасные материалы, вещества или отходы;
        </Paragraph>
        <H2>6. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</H2>
        <Paragraph>
          6.1 Стороны освобождаются от ответственности за частичное или полное
          неисполнение обязательств по Договору, если ненадлежащее исполнение
          либо неисполнение Сторонами обязательств вызвано обстоятельствами
          непреодолимой силы, к которым относятся: пожар, стихийные бедствия,
          военные действия, гражданские беспорядки, издание нормативного
          правового акта запретительного или ограничительного характера в стране
          оказания Услуг, Заказчика/Исполнителя.
        </Paragraph>
        <Paragraph>
          6.2 Сторона, на исполнение обязательств которой влияют обстоятельства
          непреодолимой силы, обязана в течение 5 (пяти) рабочих дней с даты
          начала их действия письменно уведомить об этом другую Сторону.
          Уведомление должно содержать причинно-следственную связь между фактом
          наступления обстоятельств непреодолимой силы и неисполнением Стороной
          обязательств. При этом к уведомлению прикладывается документ, выданный
          уполномоченным органом и подтверждающий факт наступления
          обстоятельства непреодолимой силы. Несвоевременное и ненадлежащее
          уведомление об обстоятельствах непреодолимой силы лишает
          соответствующую Сторону права на освобождение от ответственности за
          частичное или полное неисполнение обязательств по Договору. При
          прекращении действия обстоятельств непреодолимой силы Сторона, на
          исполнение обязательств которой они повлияли, обязана письменно
          уведомить другую Сторону.
        </Paragraph>
        <Paragraph>
          6.3 При возникновении обстоятельств непреодолимой силы срок исполнения
          Сторонами своих обязательств по Договору продлевается соразмерно сроку
          действия обстоятельств непреодолимой силы.
        </Paragraph>
        <Paragraph>
          6.4 В случае действия обстоятельств непреодолимой силы свыше 2 (двух)
          месяцев каждая из Сторон вправе расторгнуть Договор, письменно
          уведомив за 5 (пять) рабочих дней до даты расторжения с обязательным
          проведением взаиморасчетов.
        </Paragraph>
        <H2>7. СТОИМОСТЬ УСЛУГ</H2>
        <Paragraph>
          7.1. Стоимость Услуг по Договору определяются Исполнителем.
        </Paragraph>
        <Paragraph>
          7.2. Стоимость Услуг включает все расходы, связанные с оказанием
          Услуг, включая налоги и иные обязательные платежи в бюджет,
          предусмотренные законодательством Республики Казахстан.
        </Paragraph>
        <Paragraph>
          7.3. Заказчиком производится оплата Услуги путем оплаты онлайн через
          сайт Исполнителя. Без проведения оплаты услуга Клиенту Заказчика не
          оказывается.
        </Paragraph>
        <H2>8. ОПЛАТА ТОВАРА</H2>
        <Paragraph>
          8.1. Получатель имеет возможность оплачивать за получение почтовой
          посылки, и произвести оплату безналичным способом через выбранный
          сервис;
        </Paragraph>
        <Paragraph>
          8.2. Расчеты Сторон при оплате заказа осуществляются в тенге
          Республики Казахстан.
        </Paragraph>
        <H2>9. ГАРАНТИИ НА ТОВАР</H2>
        <Paragraph>
          9.1. Гарантийный срок на услуги по настоящему Договору не
          предусмотрен.
        </Paragraph>
        <H2>10. КОНФИДЕНЦИАЛЬНОСТЬ</H2>
        <Paragraph>
          10.1. Стороны принимают на себя обязательства не разглашать полученные
          в ходе исполнения Договора сведения, являющиеся конфиденциальными для
          каждой из Сторон. Под конфиденциальной информацией в Договоре
          понимаются не являющиеся общедоступными сведения, разглашение которых
          может привести к возникновению убытков и/или повлиять на деловую
          репутацию любой из Сторон.
        </Paragraph>
        <Paragraph>
          10.2. Конфиденциальная информация может быть раскрыта только в случаях
          и в порядке, установленном законодательством Республики Казахстан.
        </Paragraph>
        <H2>11. ОТВЕТСТВЕННОСТЬ СТОРОН</H2>
        <Paragraph>
          11.1. Исполнитель несет материальную ответственность за сохранность
          доставляемых отправлений с момента их принятия до вручения получателю,
          в размерах стоимости отправления при предоставлении подтверждающих
          документов.
        </Paragraph>
        <Paragraph>
          11.2. Исполнитель не несет ответственность за содержимое отправления
          при целостности упаковки, печатей̆ и перевязей̆, а также, если факт
          пропажи, порчи отправлений (вложения или части вложения) был
          установлен после принятия отправлений получателем, а также, если в
          момент сдачи не был составлен акт с участием представителя
          Исполнителя.
        </Paragraph>
        <H2>12. ПРОЧИЕ УСЛОВИЯ</H2>
        <Paragraph>
          12.1. По вопросам, неурегулированным Договором, Стороны
          руководствуются законодательством Республики Казахстан.
        </Paragraph>
        <Paragraph>
          12.2. Настоящий договор вступает в силу с даты акцепта Заказчика
          настоящей оферты и действует до полного исполнения обязательств
          Сторонами.
        </Paragraph>
        <Paragraph>
          12.3. Все споры и разногласия, возникающие при исполнении Сторонами
          обязательств по настоящему договору, решаются путем переговоров в
          течение 30 календарных дней. В случае невозможности их устранения,
          Стороны имеют право обратиться за судебной защитой своих интересов.
        </Paragraph>
        <Paragraph>
          12.4. Исполнитель оставляет за собой право расширять и сокращать
          товарное предложение на сайте, регулировать доступ к покупке любых
          услуг, а также приостанавливать или прекращать свои услуги и/или
          продажу любых товаров по своему собственному усмотрению.
        </Paragraph>
        <H2>13. АДРЕС И РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</H2>
        <Paragraph>
          <strong>Наименование</strong>: ТОО «Friendship business group»
        </Paragraph>
        <Paragraph>
          <strong>Юридический адрес</strong>: Республика Казахстан, г.
          Нур-Султан, ул.Т.Бигелдинов, д.8, н.п.1;
        </Paragraph>
        <Paragraph>
          <strong>Фактический адрес</strong>: Республика Казахстан, г.
          Нур-Султан, ул.Т.Бигелдинов, д.8, н.п.1;
        </Paragraph>
        <Paragraph>
          <strong>БИН</strong>: 201040027696
        </Paragraph>
        <Paragraph>
          <strong>БИК</strong>: HSBKKZKX
        </Paragraph>
        <Paragraph>АО «Народный Банк Казахстана»</Paragraph>
        <Paragraph>
          <strong>ИИК</strong>: KZ86601A871003157011
        </Paragraph>
      </ContentWindow>
    </InfoPageTemplate>
  );
}
