import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import Popup from './components/Popup';
import { useNavSettings } from './hooks/useNavSettings';

import {
  InfoButton,
  InfoButtonCenter,
  InfoContainer,
  Nav,
  NavBack,
  NavBorder,
  NavHome,
  NavTitle,
} from './styled';

export default function HeaderNav(): JSX.Element {
  const [isShownInfoPopup, setIsShownInfoPopup] = useState(false);
  const popup = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  const navigate = useNavigate();
  const { navigateWithUid } = useCustomNavigate();
  const {
    isShowBackButton,
    isShowHomeButton,
    isShowInfoButton,
    isSecondaryIconsStyle,
    isSecondaryTitleStyle,
    pageHeader,
    getNavBGType,
  } = useNavSettings();

  function goToPrevPage(): void {
    navigate(-1);
  }
  function goToHomePage(): void {
    navigateWithUid('/');
  }

  function togglePopupVisibility(): void {
    setIsShownInfoPopup((s) => !s);
  }

  function closePopup(): void {
    setIsShownInfoPopup(false);
  }

  useEffect(() => {
    if (isShownInfoPopup) closePopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useOutsideClick({
    ref: popup,
    cb: closePopup,
    isOpen: isShownInfoPopup,
  });

  return (
    <Nav bgType={getNavBGType()}>
      <NavBorder>
        {isShowInfoButton && (
          <InfoContainer ref={popup}>
            <InfoButton
              isPressed={isShownInfoPopup}
              type="button"
              aria-label="Show/hide info about postamat functions"
              onClick={togglePopupVisibility}
            >
              <InfoButtonCenter>?</InfoButtonCenter>
            </InfoButton>
            <Popup isVisible={isShownInfoPopup} closePopup={closePopup} />
          </InfoContainer>
        )}
        {isShowBackButton && (
          <NavBack
            role="button"
            aria-label="Go to prev page"
            isSecondary={isSecondaryIconsStyle}
            onClick={goToPrevPage}
          />
        )}
      </NavBorder>
      <NavTitle aria-label="Page title" isSecondary={isSecondaryTitleStyle}>
        {pageHeader}
      </NavTitle>
      <NavBorder>
        {isShowHomeButton && (
          <NavHome
            role="button"
            aria-label="Go to home page"
            isSecondary={isSecondaryIconsStyle}
            onClick={goToHomePage}
          />
        )}
      </NavBorder>
    </Nav>
  );
}
