import styled from 'styled-components';

export const FeaturesWrapper = styled.aside`
  width: 100%;
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
`;

export const Main = styled.main`
  width: 100%;
`;
