import { ChangeEvent, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTypedDispatch } from '../../../store';
import { DEFAULT_THEME_SETTINGS } from '../../../store/constants';
import {
  selectThemeSettings,
  setThemeSettings,
} from '../../../store/slices/themeSlice';
import { FontSize, ThemeMode } from '../../../types/theme';
import { CheckboxAction, CheckboxName } from '../types';

export function useA11ySettings() {
  const themeSettings = useSelector(selectThemeSettings);
  const navigate = useNavigate();

  const [fontSize, setFontSize] = useState(themeSettings.fontSize);
  const [themeMode, setThemeMode] = useState(themeSettings.themeMode);
  const [isArialFont, setIsArialFont] = useState(themeSettings.isArialFont);
  const [isHighlightLinks, setIsHighlightLinks] = useState(
    themeSettings.isHighlightLinks,
  );

  const dispatch = useTypedDispatch();

  function selectMode(
    event: ChangeEvent<HTMLFieldSetElement>,
  ): void | undefined {
    if (event.target.name === themeMode) {
      setThemeMode(ThemeMode.default);
    } else {
      const { name } = event.target;
      setThemeMode(name as ThemeMode);
    }
  }

  function selectSettings(event: ChangeEvent<HTMLFieldSetElement>): void {
    const { name } = event.target;
    const actions: CheckboxAction = {
      highlightLinks: setIsHighlightLinks,
      arialFont: setIsArialFont,
    };

    actions[name as CheckboxName]((s) => !s);
  }

  function selectFontSize(event: MouseEvent<HTMLDivElement>): void {
    setFontSize((event.target as HTMLButtonElement).name as FontSize);
  }

  function acceptThemeSettings(): void {
    navigate(-1);
    dispatch(
      setThemeSettings({
        fontSize,
        themeMode,
        isArialFont,
        isHighlightLinks,
      }),
    );
  }

  function resetThemSettings(): void {
    setFontSize(FontSize.normal);
    setThemeMode(ThemeMode.default);
    setIsArialFont(false);
    setIsHighlightLinks(false);
    dispatch(setThemeSettings(DEFAULT_THEME_SETTINGS));
  }

  return {
    fontSize,
    themeMode,
    isArialFont,
    isHighlightLinks,
    selectMode,
    selectSettings,
    selectFontSize,
    resetThemSettings,
    acceptThemeSettings,
  };
}
