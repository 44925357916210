import { useSelector } from 'react-redux';

import { request } from '../api';
import { FeedbackType } from '../layouts/types/feedback';
import { useTypedDispatch } from '../store';
import { setIsFeedbacksRequestAvailable } from '../store/slices/feedbackSlice';
import {
  selectDeviceInfo,
  selectOrderInfo,
} from '../store/slices/initialSlice';
import { useCustomNavigate } from './useCustomNavigate';

interface FeedbackArgs {
  type: FeedbackType;
  rate: number;
  comment: string;
}

export function useSendFeedback() {
  const { navigateToErrorPage, navigateWithUid } = useCustomNavigate();

  const dispatch = useTypedDispatch();

  const deviceInfo = useSelector(selectDeviceInfo);
  const orderInfo = useSelector(selectOrderInfo);

  async function sendFeedback({ type, rate, comment }: FeedbackArgs) {
    const data = {
      type,
      device_id: deviceInfo.device_id,
      comment,
      rating: rate,
      session_lifetime: 0,
      order_number: orderInfo.order_number,
    };

    await request({
      method: 'POST',
      path: '/feedback/',
      data,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  return async (args: FeedbackArgs) => {
    sendFeedback(args)
      .then(() => {
        dispatch(setIsFeedbacksRequestAvailable(true));
        navigateWithUid('/rate/like');
      })
      // TODO: Add common error page
      .catch(({ message: errorType }) => navigateToErrorPage(errorType));
  };
}
