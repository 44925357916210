import { IndicatorProps } from './types';

import { AvailabilityIndicator } from './styled';

export default function IndicatorDot({ isAvailable }: IndicatorProps) {
  return (
    <AvailabilityIndicator
      isAvailable={isAvailable}
      role="img"
      aria-label="Indicator of availability"
    />
  );
}
