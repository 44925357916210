import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { ReactComponent as ChevronSVG } from '../../assets/images/chevron.svg';
import { infoTextTemplate } from '../../theme/pagesElements';
import {
  ChevronProps,
  ScheduleContainerProps,
  StatusValueProps,
} from './types';

function applyStatusColor({ isActive, theme }: StatusValueProps): string {
  return isActive ? theme.palette.success : theme.palette.error;
}

function applyChevron({
  isOpen,
  dir,
}: ChevronProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  const transform = isOpen
    ? css`
        transform: rotate(90deg) rotateY(-180deg);
      `
    : css`
        transform: rotate(90deg) rotateY(0deg);
      `;
  const side =
    dir === 'ltr'
      ? css`
          right: -${({ theme }): string => theme.spacing(3)};
        `
      : css`
          left: -${({ theme }): string => theme.spacing(3)};
        `;

  return css`
    ${transform};
    ${side};
  `;
}

function applyHeight({
  isOpen,
  height,
}: ScheduleContainerProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return isOpen
    ? css`
        margin-top: ${({ theme }): string => theme.spacing(3)};
        max-height: ${height}px;
      `
    : css`
        margin-top: 0px;
        max-height: 0px;
      `;
}

export const WorkingDataContainer = styled.div`
  min-width: 300px;
`;

export const WorkingInfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};
`;

export const WorkingInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StatusValue = styled.p`
  ${infoTextTemplate};
  color: ${applyStatusColor};
`;

export const ScheduleButton = styled.button`
  position: relative;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  ${infoTextTemplate};
  gap: ${({ theme }): string => theme.spacing()};
`;

export const ClosesValue = styled.p`
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const ChevronIcon = styled(ChevronSVG).withConfig({
  shouldForwardProp: (prop) => !'isOpen'.includes(prop),
})`
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  position: absolute;
  height: 11px;
  ${applyChevron};

  path {
    fill: ${({ theme }): string => theme.palette.text.primary};
  }
`;

export const WorkScheduleContainer = styled.div`
  height: fit-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${applyHeight};
`;
