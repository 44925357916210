import styled from 'styled-components';

import { ReactComponent as DF } from '../../assets/images/dislikeFace.svg';
import { ReactComponent as LF } from '../../assets/images/likeFace.svg';
import Button from '../../components/Button';
import { infoTextTemplate } from '../../theme/pagesElements';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
`;

export const Title = styled.h1`
  ${infoTextTemplate};
  text-align: center;
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-top: ${({ theme }): string => theme.spacing(3)};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const RateExpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 4px ${({ theme }): string => theme.palette.border.shadow};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
`;

export const ExpText = styled.p`
  ${infoTextTemplate};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-top: ${({ theme }): string => theme.spacing(1.5)};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const RateButtonsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.l};
  border: 1px solid ${({ theme }): string => theme.palette.border.secondary};
  width: 88px;
  height: 67px;
`;

export const LikeFace = styled(LF)`
  height: 55px;
  width: 55px;
  path {
    fill: ${({ theme }): string => theme.palette.primary[30]};
  }
`;

export const DislikeFace = styled(DF)`
  height: 55px;
  width: 55px;

  path {
    fill: ${({ theme }): string => theme.palette.face.dislike};
  }
`;

export const CustomButton = styled(Button)`
  margin: 0;
`;

export const ButtonsPaper = styled.div`
  margin-top: ${({ theme }): string => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(1.5)};
  width: 100%;
`;
