import styled from 'styled-components';

export const CodeInputWrapper = styled.div`
  width: 100%;
`;

export const CodeInput = styled.input`
  width: inherit;

  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxl};
  text-transform: uppercase;
  text-align: center;

  outline: none;
  border: 1px solid ${({ theme }): string => theme.palette.border.asPrimeText};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.m};
  padding: ${({ theme: { spacing } }): string => spacing()};

  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};

  &:focus {
    border-color: ${({ theme }): string => theme.palette.border.primary};
    box-shadow: 0 0 4px ${({ theme }): string => theme.palette.border.primary};
  }
`;

export const ResendInfo = styled.p`
  margin-top: ${({ theme }): string => theme.spacing()};
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.special};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
`;
