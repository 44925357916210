import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import Link from '../../components/Link';
import { FontSize } from '../../types/theme';
import { FontSizeButtonProps } from './types';

function applyFontSize({ fontSizeCrop }: FontSizeButtonProps): string {
  switch (fontSizeCrop) {
    case FontSize.small:
      return '12px';
    case FontSize.large:
      return '24px';
    case FontSize.largest:
      return '36px';
    default:
      return '16px';
  }
}

function applyFontSizeButtonStyle({
  isActive,
}: FontSizeButtonProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return isActive
    ? css`
        border-color: ${({ theme }): string => theme.palette.text.asTheme};
        background-color: ${({ theme }): string =>
          theme.palette.background.primary};
        color: ${({ theme }): string => theme.palette.text.secondary};
      `
    : css`
        background-color: transparent;
        color: ${({ theme }): string => theme.palette.text.primary};
        border-color: ${({ theme }): string =>
          theme.palette.border.asPrimeText};
      `;
}

export const A11yContainer = styled.main`
  width: 100%;
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const ExampleAreaWrapper = styled.div`
  border: 1px solid ${({ theme }): string => theme.palette.border.asPrimeText};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  overflow: hidden;
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
`;

export const FontSizes = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }): string => theme.spacing(4)};
`;

export const FontSizeButton = styled.button`
  width: 67px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.l};

  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${applyFontSize};
  ${applyFontSizeButtonStyle};
`;

export const A11yButtonsForm = styled.form`
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const Fieldset = styled.fieldset`
  border: none;
`;

export const BoxGroup = styled.p`
  margin-bottom: ${({ theme }): string => theme.spacing(4)};
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;

  padding-left: 80px;
`;

export const CheckBox = styled.input`
  display: none;

  & + ${Label} {
    &:before {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      content: '';
      width: 64px;
      height: 32px;
      border: 2px solid
        ${({ theme }): string => theme.palette.border.asPrimeText};
      border-radius: 20px;
    }

    &:after {
      position: absolute;
      left: 4px;
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: ${({ theme }): string =>
        theme.palette.border.asPrimeText};
      transition-duration: ${({ theme }): string =>
        theme.transitionDuration.normal};
    }
  }

  &:checked + ${Label}:after {
    background-color: ${({ theme }): string => theme.palette.success};
    transform: translateX(32px);
  }
`;

export const A11yDocLink = styled(Link)`
  width: 100%;
  display: block;
  text-align: center;
  text-decoration: underline;
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
