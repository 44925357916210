import { ChangeEvent, useId } from 'react';

import { TextInputProps } from './types';

import { Input, InputWrapper, Placeholder } from './styled';

export default function TextInput({
  placeholder,
  onChange,
  ...props
}: TextInputProps): JSX.Element {
  const id = useId();

  function onChangeHandler({ target }: ChangeEvent<HTMLInputElement>) {
    onChange(target.value);
  }

  return (
    <InputWrapper>
      <Input
        maxLength={30}
        type="text"
        placeholder=" "
        id={id}
        onChange={onChangeHandler}
        {...props}
      />
      <Placeholder htmlFor={id}>{placeholder}</Placeholder>
    </InputWrapper>
  );
}
