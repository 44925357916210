import {
  Blob,
  BlobCenter,
  BlobsWrapper,
  Filter,
  LoaderContainer,
  SizeWrapper,
} from './styled';

export default function Loader(): JSX.Element {
  return (
    <>
      <LoaderContainer>
        <SizeWrapper>
          <BlobsWrapper>
            <BlobCenter />
            <Blob />
            <Blob />
            <Blob />
            <Blob />
            <Blob />
            <Blob />
          </BlobsWrapper>
        </SizeWrapper>
      </LoaderContainer>
      <Filter />
    </>
  );
}
