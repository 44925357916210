import { useLocation, useSearchParams } from 'react-router-dom';

import { Flow } from '../types/appLogic';

interface FlowData {
  flow: Flow;
  basePath: string;
  query: string;
}

export function useCheckFlow(): FlowData {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  const queryFlow = searchParams.get('flow');
  const pathPiece = pathname.split('/')[1];

  if (pathPiece === Flow.device || queryFlow === Flow.device) {
    return {
      flow: Flow.device,
      basePath: pathPiece === Flow.device ? `/${Flow.device}` : '',
      query: queryFlow ? `?flow=${queryFlow}` : '',
    };
  }

  return {
    flow: Flow.common,
    basePath: '',
    query: '',
  };
}
