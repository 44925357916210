import styled, { css } from 'styled-components';

const placeholderStyle = css`
  top: 12px;
  left: 12px;
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  opacity: 0.4;
`;

const placeholderMiniStyle = css`
  padding: 0 4px;
  top: -0.725em;
  left: 12px;
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  color: ${({ theme }): string => theme.palette.text.asTheme};
  opacity: 1;
  background-color: ${({ theme }): string =>
    theme.palette.background.secondary};
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Placeholder = styled.label`
  position: absolute;
  pointer-events: none;
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${placeholderMiniStyle};
`;

export const Input = styled.input`
  width: 100%;
  padding: ${({ theme }): string => theme.spacing(1.5)};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  background-color: transparent;
  outline: none;

  border: 1px solid ${({ theme }): string => theme.palette.border.shadow};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};

  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};

  &:focus {
    border-color: ${({ theme }): string => theme.palette.border.primary};
    box-shadow: 0 0 4px ${({ theme }): string => theme.palette.border.primary};
  }

  &:placeholder-shown + ${Placeholder} {
    ${placeholderStyle};
  }
  &:placeholder-shown:focus + ${Placeholder} {
    ${placeholderMiniStyle};
  }
`;
