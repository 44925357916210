import Button from '../../../../components/Button';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { useTranslate } from '../../../../hooks/useTranslate';
import { useTypedDispatch } from '../../../../store';
import { clearData } from '../../../../store/slices/laundrySlice';

import { ContentWrapper, CustomBigInfo, OperatorIcon } from './styled';

export default function WhatsNext(): JSX.Element {
  const { navigateWithUid } = useCustomNavigate();
  const { staticTranslate } = useTranslate();
  const dispatch = useTypedDispatch();

  function onClickHandler() {
    dispatch(clearData());
    navigateWithUid('/');
  }

  return (
    <>
      <ContentWrapper>
        <OperatorIcon />
        <CustomBigInfo>
          {staticTranslate('operatorClarifyingCall')}
        </CustomBigInfo>
      </ContentWrapper>
      <Button variant="contained" onClick={onClickHandler}>
        {staticTranslate('toMainPage')}
      </Button>
    </>
  );
}
