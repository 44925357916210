import styled, { css } from 'styled-components';

import { ReactComponent as EmailSVG } from '../../assets/images/email.svg';
import { ReactComponent as HomeSVG } from '../../assets/images/home.svg';
import { ReactComponent as PhoneSVG } from '../../assets/images/phoneFooter.svg';

const iconStyle = css`
  margin-top: 0.2em;
  min-width: 1em;
  min-height: 1em;

  path {
    fill: ${({ theme }): string => theme.palette.text.hightLight};
  }
`;

export const HomeIcon = styled(HomeSVG)`
  display: inline;
  align-self: flex-start;
  ${iconStyle};
`;

export const PhoneIcon = styled(PhoneSVG)`
  ${iconStyle};
`;

export const EmailIcon = styled(EmailSVG)`
  ${iconStyle};
`;

export const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }): string => theme.palette.background.footer};
  padding: ${({ theme }): string => theme.spacing(3)};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  color: ${({ theme }): string => theme.palette.text.hightLight};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing()};
`;

export const InfoWrapper = styled.p`
  display: flex;
  gap: ${({ theme }): string => theme.spacing()};

  &.cards {
    margin-top: ${({ theme }): string => theme.spacing()};
    padding-top: ${({ theme }): string => theme.spacing(2)};
    border-top: 1px solid ${({ theme }): string => theme.palette.grey[50]};
  }
`;

export const ConnectInfo = styled.a`
  color: inherit;
`;

export const FooterBottom = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }): string => theme.palette.grey[100]};
`;

export const Image = styled.img`
  width: 50px;
`;

export const LinksWrapper = styled.div`
  font-size: 14px;
  margin-bottom: ${({ theme }): string => theme.spacing()};
  display: flex;
  flex-flow: row wrap;

  a {
    display: inline-block;
    cursor: pointer;
    color: ${({ theme }): string => theme.palette.grey[30]};

    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 8px;
    }

    &:nth-child(1),
    &:nth-child(3) {
      width: 40%;
    }

    &:nth-child(2),
    &:nth-child(4) {
      width: 60%;
    }
  }
`;
