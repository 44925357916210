import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectAssortment,
  selectClothesVault,
} from '../../../../../../../store/slices/laundrySlice';
import { AssortmentItem } from '../../../../../../../store/types/laundryTypes';

export function useAssortmentFilter(
  searchValue: string,
  currentListItemId: string,
): AssortmentItem[] {
  const [shownAssortment, setShownAssortment] = useState<AssortmentItem[]>([]);
  const [availableAssortment, setAvailableAssortment] = useState<
    AssortmentItem[]
  >([]);

  const clothesVault = useSelector(selectClothesVault);
  const assortment = useSelector(selectAssortment);

  useEffect(() => {
    const filteredAssortment = assortment.filter(
      ({ clotheId }) =>
        !clothesVault.some(
          (selectedClothe) =>
            selectedClothe.clotheId === clotheId &&
            selectedClothe.listItemId !== currentListItemId,
        ),
    );
    setAvailableAssortment(filteredAssortment);
  }, [clothesVault, assortment, currentListItemId]);

  useEffect(() => {
    const assortmentToShow = availableAssortment.filter(({ clothe }) =>
      clothe.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setShownAssortment(assortmentToShow);
  }, [searchValue, availableAssortment]);

  return shownAssortment;
}
