import { useSelector } from 'react-redux';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

import { ReactComponent as A11ySVG } from '../../assets/images/a11y.svg';
import { ReactComponent as LogoDefault } from '../../assets/images/logoDefault.svg';
import { ReactComponent as LogoOMIS } from '../../assets/images/logoOmis.svg';
import { projectIs } from '../../constants';
import { selectThemeSettings } from '../../store/slices/themeSlice';
import { FontSize } from '../../types/theme';
import { A11yButtonProps, LogoButtonProps } from './types';

const HEADER_HEIGHT = '60px';

function useLogoWidth(): string {
  const { fontSize } = useSelector(selectThemeSettings);

  let width = '';

  if (fontSize === FontSize.largest) width = '53px';
  if (fontSize === FontSize.large) width = '75px';

  return width;
}

function useSvgUnderline():
  | FlattenInterpolation<ThemeProps<DefaultTheme>>
  | false {
  const { isHighlightLinks } = useSelector(selectThemeSettings);

  return (
    isHighlightLinks &&
    css`
      margin-bottom: -1px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    `
  );
}

export const Logo =
  projectIs.omar || projectIs.omkaz || projectIs.mncdk
    ? styled(LogoDefault)`
        ${useSvgUnderline};
        border-bottom-color: ${({ theme }): string =>
          theme.palette.text.hightLight};
      `
    : styled(LogoOMIS)`
        width: ${useLogoWidth};
        ${useSvgUnderline};
        border-bottom-color: ${({ theme }): string =>
          theme.palette.text.hightLight};
        path {
          fill: ${({ theme }): string => theme.palette.text.hightLight};
        }
      `;

export const A11yIcon = styled(A11ySVG)`
  ${useSvgUnderline};
`;

function useA11ySize(): FlattenSimpleInterpolation {
  const { fontSize } = useSelector(selectThemeSettings);

  if (fontSize === FontSize.largest) {
    return css`
      ${A11yIcon} {
        width: 25px;
        height: 25px;
      }
      height: 41.5px;
      width: 41.5px;
    `;
  }

  if (fontSize === FontSize.large) {
    return css`
      ${A11yIcon} {
        width: 20px;
        height: 20px;
      }
      height: 38px;
      width: 38px;
    `;
  }

  if (fontSize === FontSize.small) {
    return css`
      ${A11yIcon} {
        width: 11px;
        height: 11px;
      }
      height: 23px;
      width: 23px;
    `;
  }

  return css`
    height: 28px;
    width: 28px;
  `;
}

function applyA11yButtonStyle({
  isActive,
}: A11yButtonProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return isActive
    ? css`
        background-color: ${({ theme }): string =>
          theme.palette.background.hightLight};

        ${A11yIcon} {
          border-bottom-color: ${({ theme }): string =>
            theme.palette.text.headerActive};
          path {
            fill: ${({ theme }): string => theme.palette.text.headerActive};
          }
        }
      `
    : css`
        background-color: transparent;

        ${A11yIcon} {
          border-bottom-color: ${({ theme }): string =>
            theme.palette.text.hightLight};
          path {
            fill: ${({ theme }): string => theme.palette.text.hightLight};
          }
        }
      `;
}

function applySelectedLangStyles({
  isActive,
}: LogoButtonProps): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return isActive
    ? css`
        background: ${({ theme }): string =>
          theme.palette.background.hightLight};
        color: ${({ theme }): string => theme.palette.text.headerActive};
      `
    : css`
        background: none;
        color: ${({ theme }): string => theme.palette.text.hightLight};
      `;
}

export const HeaderHolder = styled.div`
  min-height: ${HEADER_HEIGHT};
  width: 100%;
`;

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  min-height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: ${({ theme }): string => theme.palette.secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }): string => theme.spacing(2)};
  z-index: 11;
`;

export const LogoButton = styled.button`
  border: none;
  background: none;
`;

export const Languages = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Language = styled.button`
  cursor: pointer;
  border: none;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  font-family: ${({ theme }): string => theme.typography.fontFamily.font3};
  font-size: 0.7em;
  line-height: 1.3;
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(0.5)} ${spacing(1.25)}`};
  text-align: center;
  ${applySelectedLangStyles};
`;

export const LogoImg = styled.img`
  width: 68px;
  height: 31px;
`;

export const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(1.25)};
`;

export const A11yButton = styled.button.withConfig({
  shouldForwardProp: (props) => !'isActive'.includes(props),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  border: 2px solid
    ${({ theme }): string => theme.palette.background.hightLight};
  ${applyA11yButtonStyle};
  ${useA11ySize};
`;
