import styled, { css } from 'styled-components';

import { ReactComponent as ArrowSVG } from '../../../../assets/images/sliderArrow.svg';

const arrowWrapper = css`
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 10;
  background: #ffffff9b;
  height: 90px;
  width: 45px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  border: none;
  outline: none;
`;

const arrow = css`
  path {
    stroke: ${({ theme }): string => theme.palette.primary[30]};
  }
  height: 35px;
  width: 35px;
`;

export const SliderWrapper = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    div {
      width: inherit;
      height: inherit;
    }
  }
`;

export const Slide = styled.img`
  object-fit: cover;
  height: 100%;
`;

export const LeftArrowWrapper = styled.button`
  ${arrowWrapper};
  left: 0;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  justify-content: flex-start;
  padding-right: 12px;
`;

export const RightArrowWrapper = styled.button`
  ${arrowWrapper};
  right: 0;
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  justify-content: flex-end;
  padding-left: 12px;
`;

export const RightArrow = styled(ArrowSVG)`
  ${arrow};
`;

export const LeftArrow = styled(ArrowSVG)`
  ${arrow};
  transform: rotateY(180deg);
`;
