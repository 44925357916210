import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCheckFlow } from '../../../hooks/useCheckFlow';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { usePageCheck } from '../../../hooks/usePageCheck';
import { REGEXP_UID } from '../../../layouts/constants';
import { useTypedDispatch } from '../../../store';
import {
  getDeviceData,
  getOrderData,
  selectRequestAvailability,
  setIsRequestAvailable,
  setOrderUid,
  setSavedError,
} from '../../../store/slices/initialSlice';
import { ErrorType } from '../../../store/types/errors';
import { Flow } from '../../../types/appLogic';

export function useInitData(): boolean {
  const { uid } = useParams();
  const { flow } = useCheckFlow();
  const { isMainPage } = usePageCheck();

  const { navigateToErrorPage } = useCustomNavigate();
  const dispatch = useTypedDispatch();

  const isRequestAvailable = useSelector(selectRequestAvailability);

  const isValidOrderUid = !!uid?.match(REGEXP_UID);

  useEffect(() => {
    dispatch(setOrderUid(uid as string));

    if (!isValidOrderUid) {
      navigateToErrorPage(ErrorType.NO_ORDER_ERROR);
      dispatch(setSavedError(ErrorType.NO_ORDER_ERROR));
      return;
    }

    function dispatchData(id: string) {
      if (flow === Flow.common) {
        const resultUrl = window.location.href;

        return dispatch(
          getOrderData({
            uid: id,
            resultUrl,
          }),
        );
      }
      return dispatch(getDeviceData(id));
    }

    if (isMainPage || isRequestAvailable) {
      dispatchData(uid as string)
        .unwrap()
        .catch(({ message: errorType }) => {
          dispatch(setSavedError(errorType));
          navigateToErrorPage(errorType);
        })
        .finally(() => dispatch(setIsRequestAvailable(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, dispatch, isRequestAvailable, isValidOrderUid]);

  return isValidOrderUid;
}
